import { HttpRequestHub } from "../HttpRequestHub";

export const getAllSicknessConfig = (page = 1, size = 10) => {
  const config = {
    method: "GET",
    url: `sickness/get-list`,
  };
  return HttpRequestHub(config);
};

export const sendSicknessConfig = (data) => {
  const config = {
    method: "POST",
    url: "sickness/store",
    data,
  };
  return HttpRequestHub(config);
};

export const getSicknessByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: `sickness/show/${id}`
  };
  return HttpRequestHub(config);
};

export const editSicknessConfig = (data,id) => {
  const config = {
    method: "POST",
    url: `sickness/update/${id}`,
    data,
  };
  return HttpRequestHub(config);
};

export const deleteSicknessConfig = (id) => {
  const config = {
    method: "DELETE",
    url: "sickness/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const getSicknessByCategoryConfig = (id) => {
  const config = {
    method: "GET",
    url: "sickness/sickness-category/" + id,
  };
  return HttpRequestHub(config);
};

export const getSicknessBySymtomConfig = (id) => {
  const config = {
    method: "GET",
    url: `symptoms/show/${id}`,
  };
  return HttpRequestHub(config);
};
