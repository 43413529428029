import { HttpRequestHub } from "../HttpRequestHub";

export const getAllCategories = () => {
  const config = {
    method: "GET",
    url: "service-category/get-list",
  };
  return HttpRequestHub(config);
};

export const sendCategory = (data) => {
  const config = {
    method: "POST",
    url: "service-category/store",
    data,
  };
  return HttpRequestHub(config);
};

export const deleteCategory = (id) => {
  const config = {
    method: "DELETE",
    url: "category/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const editCategory = (data,id) => {
  const config = {
    method: "POST",
    url: `service-category/update/${id}`,
    data,
  };
  return HttpRequestHub(config);
};

export const getCategory = (id) => {
  const config = {
    method: "GET",
    url: `service-category/show/${id}`,
  };
  return HttpRequestHub(config);
};
