import React, {useEffect, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import {Table, Button, Modal, Form, Input, Upload, Switch} from "antd";

import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {deleteFile, fileUpload} from "../../server/config/UploadFiles";
import {host} from "../../server/host";
import {getLanguages} from "../../server/config/GetLenguage";
import Text from "antd/lib/typography/Text";
import {
    deleteNewsConfig,
    editNewsConfig,
    getAllNewsConfig,
    getNewsByIdConfig,
    sendNewsConfig,
} from "../../server/config/NewsConfig";
import {toast} from "react-toastify";
import {toastDel, toastEdit, toastErr} from "../../Notifications/toast";

const News = () => {
    const {TextArea} = Input;
    const [NewsForm] = Form.useForm();
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editObject, setEditObject] = useState([]);
    const [lang, setLang] = useState([]);
    const [newsData, setNewsdata] = useState([]);
    const [ckData, setCkData] = useState({uz: "<p></p>", ru: "<p></p>"});
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [urlImage, setUrlImage] = useState("");
    const [urlImage_Save, setUrlImageSAVE] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [newsInfoContent, setNewsInfoContent] = useState({});
    const [sendImageNews, setSendImagenews] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });

    useEffect(() => {
        languageGet();
        getAllNews();
    }, []);

    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
                let ckObject = {};
                res.data.forEach((item) => {
                    ckObject[item.id] = "";
                });
                setCkData(ckObject);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const hasSelected = selectedRowKeys.length > 0;
    const onSelectChange = (value) => {
        console.log(value)
        setSelectedRowKeys(value);
    };
    const start = () => {
        setSelectedRowKeys([]);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns = [
        {
            title: "Yangilik Sarlavhasi",
            dataIndex: "title",
        },
        {
            title: "Yangilik haqida malumot",
            render: (newsInfo) => (
                <Button onClick={() => showInfoModal(newsInfo)}>Batafsil</Button>
            ),
        },
    ];

    //Crud News
    const getAllNews = (page) => {
        setLoading(true);
        getAllNewsConfig(page)
            .then((res) => {
                console.log(res)
                setLoading(false);
                let dataWithkey = res.data.data.map((item) => {
                    return {...item, key: item.id};
                });
                setPagination((prev) => ({
                    ...prev,
                    total: res.data.id,
                }));
                setNewsdata(dataWithkey);
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    };
    const saveNews = () => {
        console.log(editObject)
        if (editObject.id) {
            console.log('edit')
            NewsForm.validateFields()
                .then((res) => {
                    let res_data = {
                        ...res, status: res.switch == true ? 1 : 0

                    }
                    console.log(res_data, res_data, sendImageNews)
                    editNewsConfig(res_data, sendImageNews, editObject.id,saveImage)
                        .then(() => {
                            toastEdit("Yangilik");
                            setSelectedRowKeys([]);
                            getAllNews();
                            handleCancel();
                        })
                        .catch((err) => {
                            toastErr();
                        });
                });
        } else {
            console.log('add')
            NewsForm.validateFields().then((res) => {
                let res_data = {
                    ...res, status: res.switch == true ? 1 : 0

                }
                console.log(sendImageNews)
                sendNewsConfig(res_data, sendImageNews)
                    .then(() => {
                        toast.success("Yangilik qo'shildi", {autoClose: 2000});
                        getAllNews();
                        setIsModalVisible(false);
                        setPagination((prev) => ({
                            ...prev,
                            current: 1,
                        }));
                    })
                    .catch((err) => {
                        toastErr();
                    });
            });
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        let data = {};
        lang.forEach((item) => {
            data[item] = {
                title: null,
                author: null,
                description: null,
                badge: null,
            };
        });
        NewsForm.setFieldsValue(data);
        setCkData({uz: "<p></p>", ru: "<p></p>"});
    };
    const deleteNews = () => {
        console.log(selectedRowKeys)
        selectedRowKeys.forEach((item) => {
            deleteNewsConfig(item)
                .then(() => {
                    toastDel("Yangilik");
                    getAllNews();
                    getNewsByIdConfig(item).then((res) => {
                    });
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };
    const [saveImage, setSaveimage] = useState([])
    const editNews = () => {
        let id = selectedRowKeys[0];
        console.log(id)
        getNewsByIdConfig(id)
            .then((res) => {
                console.log(res)
                setEditObject(res.data);
                console.log(res.data)
                let data_save_news = {
                    author: {
                        ru: res.data.author.ru,
                        uz: res.data.author.uz,
                    },
                    title: {
                        ru: res.data.title.ru,
                        uz: res.data.title.uz,
                    },
                    description: {
                        ru: res.data.description.ru,
                        uz: res.data.description.uz,
                    },
                    badge: {
                        ru: res.data.badge.ru,
                        uz: res.data.badge.uz,
                    },
                    status: res.data.status,
                    image: res.data.image

                }
                console.log(res)
                setUrlImage(res.data.id);
                setFileList([{url: `${res.data.image}`}]);
                setSaveimage(res.data.image)
                setPreviewImage(`${res.data.image}`);

                NewsForm.setFieldsValue(data_save_news);
                setIsModalVisible(true);
            })
            .catch((err) => {
                toastErr();
            });
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "Rosdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                deleteNews();
            },
        });
    }

    //Image upload
    const handleChange = (info) => {
        if (info.fileList.length !== 0) {
            let fileListInfo = [...info.fileList];
            setFileList(fileListInfo);
            setSendImagenews(info.fileList[0].originFileObj)
        }
    };
    const handlePreview = () => {
        setPreviewVisible(true);
        setPreviewImage(`${host}files/public/${urlImage}`);
    };
    const deleteImg = () => {
        setFileList([]);
    };

    //Modal functions
    const showModal = () => {
        setIsModalVisible(true);
        setCkData({uz: "<p></p>", ru: "<p></p>"});
        NewsForm.resetFields();
        setUrlImage("");
        setFileList([]);
        setSelectedRowKeys([]);
        setEditObject([]);
    };
    const showInfoModal = (newsInfo) => {
        setInfoModal(true);
        setNewsInfoContent(newsInfo);
    };
    const hideInfoModal = () => {
        setInfoModal(false);
    };

    const handleTableChange = (page) => {
        console.log(page)
        getAllNews(page.current);
        setSelectedRowKeys([]);
        setEditObject([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    return (
        <div className="illness">
            <Table
                loading={loading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={newsData}
                pagination={pagination}
                onChange={handleTableChange}
                title={() => (
                    <>
                        <Button
                            type="primary"
                            style={{marginLeft: 16}}
                            onClick={showModal}
                            disabled={hasSelected}
                        >
                            Yangilik Qo'shish
                        </Button>
                        <Button
                            className={selectedRowKeys.length > 0 ? "" : "display-none"}
                            style={{marginLeft: 16, color: "red"}}
                            onClick={confirm}
                        >
                            <DeleteOutlined/>
                        </Button>
                        <Button
                            className={selectedRowKeys.length === 1 ? "" : "display-none"}
                            style={{marginLeft: 16, color: "green"}}
                            onClick={editNews}
                        >
                            <EditOutlined/>
                        </Button>
                        <Button
                            type="primary"
                            onClick={start}
                            disabled={!hasSelected}
                            style={{marginLeft: 16}}
                        >
                            Yangilash
                        </Button>
                    </>
                )}
            />

            <Modal
                title="Yangilik qo'shish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={saveNews}
                onCancel={handleCancel}
                className="illness-modal"
            >
                <Form form={NewsForm} {...layout}>
                    {lang.map((item, index) => (
                        <div key={index}>
                            <Text keyboard style={{textTransform: "capitalize"}}>
                                {item.name} tilida kiritiladigan ma'lumotlar
                            </Text>
                            <Form.Item
                                label="Yangilik sarlavhasi:"
                                name={["title", item.url]}
                                key={[item.id, "title"]}
                                rules={[
                                    {required: true, message: "Please input illness name!"},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Muallif:"
                                name={["author", item.url]}
                                key={[item.id, "author"]}
                                rules={[
                                    {required: true, message: "Please input illness name!"},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Badge:"
                                name={["badge", item.url]}
                                key={[item.id, "badge"]}
                                rules={[
                                    {required: true, message: "Please input illness name!"},
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                name={["description", item.url]}
                                key={[item.url, "description"]}
                                label="Yangilik haqida malumot:"
                                // getValueFromEvent={ckChange}
                                style={{marginBottom: "50px"}}
                            >
                                <TextArea rows={4}/>
                                {/*<CKEditor*/}
                                {/*  data={ckData[item]}*/}
                                {/*  editor={ClassicEditor}*/}
                                {/*  onChange={(e, editor) => ckChange(e, editor, item)}*/}
                                {/*/>*/}
                            </Form.Item>

                        </div>
                    ))}
                    <Form.Item label="News status" valuePropName="checked" key='switch' name='switch'>
                        <Switch/>
                    </Form.Item>
                </Form>
                <Form.Item
                    label="Rasm:"
                    key="Rasm:"
                    className="image-upload"
                    {...layout}
                >
                    <Upload
                        fileList={fileList}
                        listType="picture-card"
                        onPreview={(file) => handlePreview(file)}
                        onChange={handleChange}
                        onRemove={deleteImg}
                        beforeUpload={() => false}
                        maxCount={1}
                        accept=".jpg, .jpeg, .png, .pdf"
                    >
                        {fileList.length !== 0 ? (
                            ""
                        ) : (
                            <div>
                                <div className="ant-upload-text">Rasmni yuklang</div>
                            </div>
                        )}
                    </Upload>
                    <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                        cancelText="Yopish"
                    >
                        <img alt="example" style={{width: "100%"}} src={previewImage}/>
                    </Modal>
                </Form.Item>

            </Modal>
            <Modal
                title="Yangilik haqida malumot"
                visible={infoModal}
                centered
                width="70%"
                onOk={hideInfoModal}
                onCancel={hideInfoModal}
                className="illness-info-modal"
            >
                <div className="illness-info">
                    <div className="illness-image">
                        <img
                            src={newsInfoContent.image}
                            alt="news"
                        />
                        <h2>{newsInfoContent.title}</h2>
                    </div>
                    <div className="editor">
                        {ReactHtmlParser(newsInfoContent.description)}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default News;
