import { HttpRequestHub } from "../HttpRequestHub";

export const GetCommentsFalse = (page) => {
    const config = {
        method: "GET",
        url: `clinic-comment/list?page=${page}/`,
    };
    return HttpRequestHub(config);
};

export const GetCommentsTrue = (page) => {
    const config = {
        method: "GET",
        url: `clinic-comment/active-list?page=${page}/`,
    };
    return HttpRequestHub(config);
};

export const ConfirmComment = (id, data) => {
    const config = {
        method: "POST",
        url: "clinic-comment/update-status/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const DeleteComment = (id, data) => {
    const config = {
        method: "POST",
        url: "clinic-comment/update-status/" + id,
        data,
    };
    return HttpRequestHub(config);
};