import React, { useEffect, useState } from "react";
import "./Filter.css";
import { Tabs, Table, Button, Modal, Input } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  deleteFilter,
  deleteFilterDistance,
  editFilter,
  editFilterDistance,
  getFilter,
  sendFilter,
  sendFilterDistance,
} from "../../server/config/FilterCrud";
import {
  toastAdd,
  toastDel,
  toastEdit,
  toastErr,
} from "../../Notifications/toast";

const Filter = () => {
  const { TabPane } = Tabs;
  const [spaceList, setSpaceList] = useState([]);
  const [fromSpace, setFromSpace] = useState("");
  const [toSpace, setToSpace] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editId, setEditId] = useState("");
  const [modal2Visible, setModal2Visible] = useState(false);
  const [maxSpaceValue, setmaxSpace] = useState("");
  const [costValue, setMaxCostalue] = useState("");
  const [filterId, setFilterId] = useState("");
  const [editMin, setEditMin] = useState("");
  const [editMax, setEditMax] = useState("");
  const [distanceprice, setDistancePrice] = useState("");

  useEffect(() => {
    getFilterAll();
  }, []);

  const changeFrom = (e) => {
    setFromSpace(e.target.value);
  };
  const changeTo = (e) => {
    setToSpace(e.target.value);
  };
  const editGetSpace = () => {
    setModal2Visible(true);
    setFromSpace(editMin);
    setToSpace(editMax);
  };
  const resetSearch = () => {
    setSelectedRowKeys([]);
    setEditId("");
  };

  //Table functions
  const onSelectChange = (selectedRowKeys) => {
    setEditId(selectedRowKeys[0]);
    setSelectedRowKeys(selectedRowKeys);
    spaceList.forEach((item) => {
      if (item.filterDistanceId === selectedRowKeys[0]) {
        setEditMin(item.minDistance);
        setEditMax(item.maxDistance);
      }
    });
  };
  const rowSelection2 = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const columns2 = [
    {
      title: "from",
      dataIndex: "minDistance",
      key: "filterDistanceId",
    },
    {
      title: "to",
      dataIndex: "maxDistance",
      key: "filterDistanceId",
    },
  ];

  //Modal functions
  const closeModal2 = () => {
    setModal2Visible(false);
    setFromSpace("");
    setToSpace("");
  };

  //Max space
  const changeMaxSpace = (e) => {
    setmaxSpace(e.target.value);
  };

  //Cost
  const changeCost = (e) => {
    setMaxCostalue(e.target.value);
  };

  //Distance Price
  const changeDistancePrice = (e) => {
    setDistancePrice(e.target.value);
  };

  //Filter Crud functions
  const getFilterAll = () => {
    getFilter().then((res) => {
      setFilterId(res.data.filterId);
      setMaxCostalue(res.data.maxSum);
      setmaxSpace(res.data.maxDistance);
      setDistancePrice(res.data.distancePrice);
      console.log(res);
      if (res.data.filterId) {
        let keyed = res.data.filterDistances.map((item) => {
          return { ...item, key: item.filterDistanceId };
        });
        setSpaceList(keyed);
      }
    });
  };
  const saveFilterAll = () => {
    let unkeyed = spaceList.map((item) => {
      let { key, filterDistanceId, ...rest } = item;
      return rest;
    });
    if (filterId) {
      const data = {
        filterId: filterId,
        maxSum: costValue,
        maxDistance: maxSpaceValue,
        filterDistances: unkeyed,
        distancePrice: distanceprice,
      };
      editFilter(data)
        .then(() => {
          getFilterAll();
          toastEdit("Filter ma'lumotlari");
        })
        .catch(() => toastErr());
    } else {
      const data = {
        maxSum: costValue,
        maxDistance: maxSpaceValue,
        filterDistances: unkeyed,
        distancePrice: distanceprice,
      };
      sendFilter(data)
        .then(() => {
          getFilterAll();
          toastAdd("Filter ma'lumotlari");
        })
        .catch(() => toastErr());
    }
  };
  const saveDistance = () => {
    if (editId) {
      const data = {
        filterDistanceId: editId,
        minDistance: parseFloat(fromSpace),
        maxDistance: parseFloat(toSpace),
      };
      editFilterDistance(data)
        .then(() => {
          getFilterAll();
          toastEdit("Filter masofalari");
        })
        .catch((err) => {
          toastErr();
        });
    } else {
      const data = {
        minDistance: parseFloat(fromSpace),
        maxDistance: parseFloat(toSpace),
        filterId: filterId,
      };
      sendFilterDistance(data)
        .then(() => {
          getFilterAll();
          toastAdd("Filter masofalari");
        })
        .catch((err) => {
          toastErr();
        });
      setFromSpace("");
      setToSpace("");
    }
    setModal2Visible(false);
    getFilterAll();
  };
  const delFilterAll = () => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteFilter(filterId).then((res) => {
          getFilterAll();
          toastDel("Filter ma'lumotlari");
        });
      },
    });
  };
  const deleteDistance = () => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteFilterDistance(editId).then((res) => {
          getFilterAll();
          setSelectedRowKeys([]);
          setEditId("");
          toastDel("Filter masofalari");
        });
      },
    });
  };

  return (
    <div className="filter_section">
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Filter: Maximal Qiymatlar" key="1">
          <label htmlFor="toCost">Narx Chegarasini kiriting ($)</label>
          <div className="info-div-space"></div>
          <Input
            style={{ width: "50%" }}
            value={costValue}
            onChange={changeCost}
            required
            size="large"
            id="toCost"
            placeholder="Narx chegarasi..."
          />
          <div className="info-div-space"></div>

          <label htmlFor="toSpaceId">Masofa Chegarasini kiriting (km)</label>
          <div className="info-div-space"></div>
          <Input
            style={{ width: "50%" }}
            value={maxSpaceValue}
            onChange={changeMaxSpace}
            required
            size="large"
            id="toSpaceId"
            placeholder="Masofa chegarasi..."
          />

          <div className="info-div-space"></div>
          <label htmlFor="toSpaceId">Masofa narxini kiriting (so'm) </label>
          <div className="info-div-space"></div>
          <Input
            style={{ width: "50%" }}
            value={distanceprice}
            onChange={changeDistancePrice}
            required
            size="large"
            id="toSpaceId"
            placeholder="Masofa narxi"
          />
          <div className="info-div-space"></div>
          <Button
            type="primary"
            style={{ float: "right", marginRight: "30px" }}
            onClick={saveFilterAll}
          >
            Save changes
          </Button>

          <Button
            type="danger"
            style={{ float: "right", marginRight: "30px" }}
            onClick={delFilterAll}
          >
            Delete
          </Button>
        </TabPane>

        <TabPane tab="Filter: Masofa Oraliqlari" key="2">
          <div className="doctors-head">
            <div className="doctor-btns tool-btns">
              <Button
                onClick={deleteDistance}
                className={
                  selectedRowKeys.length === 1
                    ? "delete-doctors category-main-plus"
                    : "delete-doctors btn-none category-main-plus"
                }
                style={{ width: "40px", height: "30px", marginRight: "20px" }}
              >
                <DeleteFilled />
              </Button>
              <Button
                onClick={editGetSpace}
                className={
                  selectedRowKeys.length === 1
                    ? "edit-doctors category-main-edit"
                    : "edit-doctors btn-none category-main-edit"
                }
                style={{ width: "40px", height: "30px" }}
              >
                <EditFilled />
              </Button>
            </div>
            <Button
              onClick={() => {
                setModal2Visible(true);
                setEditId("");
                setFromSpace("");
                setToSpace("");
              }}
              disabled={editId ? true : false}
              type="danger"
            >
              Yangi masofa qo'shish
            </Button>
            <div className="doctors-search">
              <Button
                style={{ marginLeft: "20px" }}
                onClick={resetSearch}
                type="primary"
              >
                Reset
              </Button>
            </div>
          </div>

          <Table
            rowSelection={{ type: "radio", ...rowSelection2 }}
            columns={columns2}
            dataSource={spaceList}
            bordered
            pagination={{ hideOnSinglePage: true }}
          />
          <div className="info-div-space"></div>
        </TabPane>
      </Tabs>

      <Modal
        title={editId ? "Ma'lumotlarni tahrirlash" : "Ma'lumotlarni Kiriting"}
        centered
        visible={modal2Visible}
        onOk={saveDistance}
        onCancel={() => {
          closeModal2();
        }}
      >
        <label htmlFor="from_space">Boshlang'ich masofani kiriting</label>
        <Input
          value={fromSpace}
          onChange={changeFrom}
          required
          size="large"
          id="from_space"
          placeholder="from space..."
        />
        <div className="info-div-space"></div>

        <label htmlFor="to_space">Oxirgi masofani kiriting</label>
        <Input
          value={toSpace}
          onChange={changeTo}
          required
          size="large"
          id="to_space"
          placeholder="to space..."
        />
        <div className="info-div-space"></div>
      </Modal>
    </div>
  );
};

export default Filter;
