import { HttpRequestHub } from "../HttpRequestHub";

export const getAbout = () => {
    const config = {
        method: "GET",
        url: "get-about-info",
    };
    return HttpRequestHub(config);
};

export const sendAbout = (data) => {
    const config = {
        method: "POST",
        url: "about-us/",
        data,
    };
    return HttpRequestHub(config);
};

export const editAbout = (data) => {
    const config = {
        method: "POST",
        url: "update-about-info?lang=uz",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteAbout = () => {
    const config = {
        method: "DELETE",
        url: "about-us/delete/",
    };
    return HttpRequestHub(config);
};