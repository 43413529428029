import { HttpRequestHub } from "../HttpRequestHub";

export const getAllServices = (id) => {
    const config = {
        method: "GET",
        url: `service-category/get-list?category_id=${id}`,
    };
    return HttpRequestHub(config);
};

export const sendServices = (data) => {
    const config = {
        method: "POST",
        url: "service-category/store",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteServices = (id) => {
    const config = {
        method: "DELETE",
        url: "standard-service/delete/" + id,
    };
    return HttpRequestHub(config);
};

export const getService = (id) => {
    const config = {
        method: "GET",
        url: `service-category/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editService = (data,id) => {
    const config = {
        method: "POST",
        url: `service-category/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};

export const getAllServicesConfig=()=>{
    const config={
        method: 'GET',
        url: 'service-category/get-list?type=standard'
    }
    return HttpRequestHub(config)
}
