import { HttpRequestHub } from "../HttpRequestHub";

export const getTemplateSubCategorybyCategory = (id, page, size) => {
    const config = {
        method: "GET",
        url: `template-category/get-list?category_id=${id}`,
    };
    return HttpRequestHub(config);
};

export const sendTemplateSubCategory = (data) => {
    const config = {
        method: "POST",
        url: "template-category/store",
        data,
    };
    return HttpRequestHub(config);
};

export const getTemplateSubCategoryById = (id, data) => {
    const config = {
        method: "GET",
        url: "template-category/show/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const editTemplateSubCategory = (id, data) => {
    const config = {
        method: "POST",
        url: "template-category/update/" + id,
        data,
    };
    return HttpRequestHub(config);
};


export const deleteTemplateSubCategory = (id) => {
    const config = {
        method: "DELETE",
        url: "med-card-sub-category/delete/" + id,
    };
    return HttpRequestHub(config);
};