import { HttpRequestHub } from "../HttpRequestHub";

export const getPartnerById = (id) => {
  const config = {
    method: "GET",
    url: `clinic-billings/show/${id}`,
  };
  return HttpRequestHub(config);
};

export const createPartner = (data) => {
  const config = {
    method: "POST",
    url: "clinic-billings/store",
    data,
  };
  return HttpRequestHub(config);
};
