import React, {useEffect, useState} from "react";
import "./Region.css";
import {PlusOutlined, DeleteFilled, EditOutlined} from "@ant-design/icons";
import {Button, Table, Modal, Input, Form, Upload} from "antd";
import {
    deleteRegion,
    editRegion,
    getRegionbyid,
    getRegions,
    sendRegion,
} from "../../server/config/RegionCrud";
import {
    deleteDistrict,
    editDistrict,
    getDistrictbyid,
    getDistrictsbyRegion,
    sendDistrict,
} from "../../server/config/DistrictCrud";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";
import {host} from "../../server/host";
import Text from "antd/lib/typography/Text";

function Region() {
    const {TextArea} = Input;
    const [fileList, setFileList] = useState([]);
    const [sendImageNews, setSendImagenews] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [urlImage, setUrlImage] = useState("");



    const [languages, setLanguages] = useState([]);
    const [regionId, setRegionId] = useState("");
    const [districtId, setDistrictId] = useState("");
    const [aktivId, setAktivId] = useState("");
    const [regionModal, setRegionModal] = useState(false);
    const [districtModal, setDistrictModal] = useState(false);
    const [formRegion] = Form.useForm();
    const [formDistrict] = Form.useForm();
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const [regionList, setRegion] = useState([]);
    const [districtList, setDistrict] = useState([]);

    useEffect(() => {
        console.log('regions')
        regionsGet();
        GetLanguages()
    }, []);

    // Regions Crud
    const GetLanguages = () => {
        console.log('language')
        getLanguages()
            .then((res) => {
            setLanguages(res.data);
            console.log('language', res.data)

        });
    }

    const regionsGet = (page) => {
        getRegions(page ? page : 1)
            .then((res) => {
                setRegion(res.data);
                console.log(res.data)
            })
            .catch((err) => {
                toastErr();
            });
    };
    const regionGetById = (id) => {
        console.log(id)
        getRegionbyid(id)
            .then((res) => {
                console.log(res.data)
                const data = res.data;
                formRegion.setFieldsValue(data);
                setUrlImage(res.data.id);
                setFileList([{url: `${res.data.image}`}]);
                setPreviewImage(`${res.data.image}`);
            })
            .catch(() => toastErr());
    };
    const regionSave = () => {
        formRegion
            .validateFields()
            .then((values) => {
                if (regionId) {
                    const data = Object.keys(values).map((item) => {
                        return {
                            language: item,
                            name: values[item].name,
                            regionId: regionId,
                        };
                    });
                    editRegion(data)
                        .then(() => {
                            regionsGet();
                            toastEdit("Viloyat");
                        })
                        .catch(() => toastErr());
                } else {
                    console.log(values)
                    sendRegion(values,sendImageNews)
                        .then(() => {
                            regionsGet();
                            toastAdd("viloyat");
                        })
                        .catch(() => toastErr());
                }
                setRegionModal(false);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const regionDelete = (id) => {
        console.log(id)
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteRegion(id)
                    .then((res) => {
                        setRegionId("");
                        regionsGet();
                        toastDel("Viloyat");
                    })
                    .catch(() => toastErr());
            },
        });
    };

    // Districts Crud
    const districtsGet = (id) => {
        getDistrictsbyRegion(id)
            .then((res) => {
                setDistrict(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const districtGetById = (id) => {
        getDistrictbyid(id)
            .then((res) => {
                const data = res.data;
                formDistrict.setFieldsValue(data);
            })
            .catch(() => toastErr());
    };
    const districtSave = () => {
        formDistrict
            .validateFields()
            .then((values) => {
                if (districtId) {
                    let data={
                        ...values,
                        region_id: aktivId,
                    }
                    editDistrict(data,districtId)
                        .then(() => {
                            districtsGet(aktivId);
                            toastEdit("Tuman");
                        })
                        .catch(() => toastErr());
                } else {
                    let data={
                        ...values,
                        region_id: aktivId,
                    }
                    console.log(data)
                    sendDistrict(data)
                        .then(() => {
                            districtsGet(aktivId);
                            toastAdd("tuman");
                        })
                        .catch(() => toastErr());
                }
                setDistrictModal(false);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const districtDelete = (id) => {
        console.log(id)
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteDistrict(id)
                    .then((res) => {
                        districtsGet(aktivId);
                        toastDel("Tuman");
                    })
                    .catch(() => toastErr());
            },
        });
    };

    // Table functions
    const columnsRegion = [
        {
            title: <h1>Viloyatlar</h1>,
            render: (item) => (
                <Button
                    onClick={() => {
                        districtsGet(item.id);
                        setAktivId(item.id);
                    }}
                    type="text"
                >
                    {item.name}
                </Button>
            ),
            key: "name",
        },
        {
            title: (
                <Button
                    className="category-main-button"
                    onClick={() => {
                        setRegionModal(true);
                        setRegionId("");
                        clearRegionModal();
                    }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <PlusOutlined style={{fontSize: "25px"}}/>
                </Button>
            ),
            render: (item) => (
                <React.Fragment>
                    <DeleteFilled
                        className="cate-button-icon cate-icon-delete"
                        onClick={() => regionDelete(item.id)}
                    />
                    <EditOutlined
                        className="cate-button-icon cate-icon-edit"
                        onClick={() => {
                            setRegionId(item.id);
                            setRegionModal(true);
                            regionGetById(item.id);
                        }}
                    />
                </React.Fragment>
            ),
            key: "item",
        },
    ];
    const columnsDistrict = [
        {
            title: <h1>Tumanlar</h1>,
            dataIndex: "name",
            key: "name",
        },
        {
            title: (
                <Button
                    className="category-main-button"
                    onClick={() => {
                        setDistrictModal(true);
                        setDistrictId("");
                        clearDistrictModal();
                    }}
                    disabled={aktivId ? false : true}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <PlusOutlined style={{fontSize: "25px"}}/>
                </Button>
            ),
            render: (item) => (
                <React.Fragment>
                    <DeleteFilled
                        className="cate-button-icon cate-icon-delete"
                        onClick={() => districtDelete(item.id)}
                    />
                    <EditOutlined
                        className="cate-button-icon cate-icon-edit"
                        onClick={() => {
                            setDistrictId(item.id);
                            setDistrictModal(true);
                            districtGetById(item.id);
                        }}
                    />
                </React.Fragment>
            ),
            key: "item",
        },
    ];

    //Clear Modal
    const clearRegionModal = () => {
        formRegion.resetFields();
    };
    const clearDistrictModal = () => {
        formDistrict.resetFields();
    };



    //////______________image-Upload------------
    const handleChange = (info) => {
        if (info.fileList.length !== 0) {
            let fileListInfo = [...info.fileList];
            setFileList(fileListInfo);
            setSendImagenews(info.fileList[0].originFileObj)
        }
    };
    const handlePreview = () => {
        setPreviewVisible(true);
        setPreviewImage(`${host}files/public/${urlImage}`);
    };
    const deleteImg = () => {
        setFileList([]);
    };

    return (
        <>
            <div className="main-categories-content region_section">
                <div className="region_left" style={{width: "50%"}}>
                    <Table
                        rowKey={(o) => o.regionId}
                        columns={columnsRegion}
                        dataSource={regionList}
                        style={{width: "100%"}}
                        pagination={{hideOnSinglePage: true}}
                    />
                </div>
                <div className="region_right" style={{width: "50%"}}>
                    <Table
                        columns={columnsDistrict}
                        rowKey={(o) => o.districtId}
                        dataSource={districtList}
                        style={{width: "100%"}}
                        pagination={{hideOnSinglePage: true}}
                    />
                </div>
            </div>

            {/* Region Modal */}
            <Modal
                width={1000}
                title={
                    regionId
                        ? "Viloyat Ma'lumotlarini tahrirlash"
                        : "Yangi Viloyat Qo'shish"
                }
                centered
                visible={regionModal}
                okText={regionId ? "O'zgarishlarni Saqlash" : "Saqlash"}
                cancelText="Bekor qilish"
                onOk={regionSave}
                onCancel={() => {
                    setRegionModal(false);
                }}
            >
                <Form {...layout} form={formRegion}>
                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <div>
                                    <Text keyboard style={{textTransform: "capitalize"}}>
                                        {item.name} tilida kiritiladigan ma'lumotlar
                                    </Text>
                                    <Form.Item
                                        label="Viloyatlar sarlavhasi:"
                                        name={["name", item.url]}
                                        key={[item.id, "name"]}
                                        rules={[
                                            {required: true, message: "Please input illness name!"},
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        name={["description", item.url]}
                                        key={[item.url, "description"]}
                                        label="Viloyatlar haqida malumot:"
                                        // getValueFromEvent={ckChange}
                                        style={{marginBottom: "50px"}}
                                    >
                                        <TextArea rows={4}/>
                                        {/*<CKEditor*/}
                                        {/*  data={ckData[item]}*/}
                                        {/*  editor={ClassicEditor}*/}
                                        {/*  onChange={(e, editor) => ckChange(e, editor, item)}*/}
                                        {/*/>*/}
                                    </Form.Item>
                                </div>


                            );
                        })

                        : null}
                </Form>
                <Form.Item
                    label="Rasm:"
                    key="Rasm:"
                    className="image-upload"
                    {...layout}
                >
                    <Upload
                        fileList={fileList}
                        listType="picture-card"
                        onPreview={(file) => handlePreview(file)}
                        onChange={handleChange}
                        onRemove={deleteImg}
                        beforeUpload={() => false}
                        maxCount={1}
                        accept=".jpg, .jpeg, .png, .pdf"
                    >
                        {fileList.length !== 0 ? (
                            ""
                        ) : (
                            <div>
                                <div className="ant-upload-text">Rasmni yuklang</div>
                            </div>
                        )}
                    </Upload>
                    <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                        cancelText="Yopish"
                    >
                        <img alt="example" style={{width: "100%"}} src={previewImage}/>
                    </Modal>
                </Form.Item>
            </Modal>

            {/* District Modal */}
            <Modal
                width={1000}
                title={
                    districtId
                        ? "Tuman Ma'lumotlarini tahrirlash"
                        : "Yangi Tuman Qo'shish"
                }
                centered
                visible={districtModal}
                okText={districtId ? "O'zgarishlarni Saqlash" : "Saqlash"}
                cancelText="Bekor qilish"
                onOk={districtSave}
                onCancel={() => {
                    setDistrictModal(false);
                }}
            >
                <Form {...layout} form={formDistrict}>
                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <Form.Item
                                    name={["name", item.url]}
                                    key={[item.id, "name"]}
                                    label={`Tuman Nomini Kiriting (${item.name})`}
                                    rules={[{required: true, message: "To'ldirilmagan"}]}
                                >
                                    <Input size="large" placeholder={`Tuman ${item.name} nomi...`}/>
                                </Form.Item>
                            );
                        })
                        : null}
                </Form>
            </Modal>
        </>
    );
}

export default Region;
