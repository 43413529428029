import React, {useEffect, useState} from "react";
import "../illness/Illness.css";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import {Table, Button, Modal, Form, Input, Select} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    deleteSymptomConfig,
    editSymptomConfig,
    getAllSymptomsConfig,
    getSymptomByIdConfig,
    sendSymptomConfig,
} from "../../server/config/SymptomConfig";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    getAllSicknessConfig,
    getSicknessBySymtomConfig,
} from "../../server/config/SicknessConfig";
import {getAllSymptomCategories} from "../../server/config/SymptomCategory";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const Symptom = () => {
    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };
    const {Option} = Select;
    const [symptomData, setSymptomData] = useState([]);
    const [lang, setLang] = useState([]);
    const [sicknessData, setSicknessData] = useState([]);
    const [symptomCategoryData, setSymptomCategoryData] = useState([]);
    const [formSymptom] = Form.useForm();
    const [editObject, setEditObject] = useState([]);
    const [ckData, setCkData] = useState({uz: "<p></p>", ru: "<p></p>"});
    const [infoModal, setInfoModal] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [symptomInfoContent, setSymptomInfoContent] = useState("");
    const [sicknessList, setSicknessList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });

    const getLanguagesFunc = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    //   table illlness
    const hasSelected = selectedRowKeys.length > 0;
    const start = () => {
        setSelectedRowKeys([]);
    };
    const onSelectChange = (value) => {
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns = [
        {
            title: "Symptom nomi",
            dataIndex: "name",
        },
        {
            title: "Symptomga biriktirilgan kassalliklar",
            render: (item) => (
                <Select
                    defaultValue="Kassalliklarni ko'rish"
                    style={{width: 200}}
                    onClick={() => getSicknesses(item.id)}
                >
                    {sicknessList.length > 0
                        ? sicknessList.map((item, index) => {
                            return (
                                <Option
                                    key={index}
                                    value="disabled"
                                    style={{pointerEvents: "none"}}
                                >
                                    {item.name}
                                </Option>
                            );
                        })
                        : ""}
                </Select>
            ),
            key: "item",
        },
        {
            title: "Symptom haqida malumot",
            render: (item) => (
                <Button onClick={() => showInfoModal(item)}>Batafsil</Button>
            ),
            key: "symptomId",
        },
    ];

    //   modal add symtop
    const showModal = () => {
        setIsModalVisible(true);
        formSymptom.resetFields();
        setEditObject([]);
        setCkData({uz: "<p></p>", ru: "<p></p>"});
    };
    const showInfoModal = (item) => {
        setInfoModal(true);
        setSymptomInfoContent(item);
    };

    //Sumptom Crud
    const getSicknesses = (id) => {
        getSicknessBySymtomConfig(id)
            .then((res) => {
                setSicknessList(res.data.sickness);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getAllSicknessConfigFunc = () => {
        getAllSicknessConfig()
            .then((res) => {
                setSicknessData(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getAllSymptomCategoriesFunc = () => {
        getAllSymptomCategories().then((res) => {
            setSymptomCategoryData(res.data);
        });
    };
    const getAllSymptoms = (page) => {
        setLoading(true);
        getAllSymptomsConfig()
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                setSymptomData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.totalElements,
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    };
    const saveSymptom = () => {
        if (editObject.id) {
            const id = selectedRowKeys[0];
            formSymptom
                .validateFields()
                .then((values) => {
                    console.log(values)
                    // let {sicknessIdList, symptomCategoryId, ...rest} = values;
                    // const data2 = Object.keys(rest).map((item) => {
                    //     return {
                    //         symptomId: id,
                    //         language: item,
                    //         description: values[item].description,
                    //         title: values[item].title,
                    //         symptomCategoryId: symptomCategoryId,
                    //         sicknessIdList: sicknessIdList,
                    //     };
                    // });
                    editSymptomConfig(values,editObject.id)
                        .then((res) => {
                            getAllSymptoms(pagination.current);
                            setSelectedRowKeys([]);
                            toastEdit("Simptom");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                    setIsModalVisible(false);
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            formSymptom
                .validateFields()
                .then((values) => {
                    console.log(values)
                    sendSymptomConfig(values)
                        .then((res) => {
                            getAllSymptoms(pagination.current);
                            toastAdd("simptom");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                    setIsModalVisible(false);
                })
                .catch((err) => {
                    toastErr();
                });
        }
    };
    const editSymptom = () => {
        showModal();
        const id = selectedRowKeys[0];
        getSymptomByIdConfig(id)
            .then((res) => {
                setEditObject(res.data);
                let data2 = res.data
                let sickness = []
                res.data.sickness.map(item => {
                    sickness.push(item.id)
                })
                data2["sicknesses"] = sickness;
                data2["symptom_category_id"] = res.data.symptom_category_id;
                console.log(data2)
                formSymptom.setFieldsValue(data2);
                let ckObject = {};
                lang.forEach(item => {
                    ckObject[item.url] = res.data.description[item.url]
                })
                setCkData(ckObject);
                // let dataDes = {
                //     description: {
                //         ru: res.data.description.ru,
                //         uz: res.data.description.uz
                //     }
                // }
                // setCkData(dataDes)
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSymptom = () => {
        selectedRowKeys.forEach((item) => {
            deleteSymptomConfig(item)
                .then((res) => {
                    getAllSymptoms(
                        symptomData.length > 1 ? pagination.current : pagination.current - 1
                    );
                    toastDel("Simptom");
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "Rostdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSymptom();
            },
        });
    }

    const handleTableChange = (page) => {
        getAllSymptoms(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    useEffect(() => {
        getLanguagesFunc();
        getAllSymptoms();
        getAllSicknessConfigFunc();
        getAllSymptomCategoriesFunc();
    }, []);

    return (
        <div className="illness">
            <div>
                <div style={{marginBottom: 16}}>
                    <Button
                        type="primary"
                        onClick={showModal}
                        disabled={selectedRowKeys.length > 0 ? true : false}
                    >
                        Symptom Qo'shish
                    </Button>
                    <Button
                        className={selectedRowKeys.length > 0 ? "" : "display-none"}
                        style={{marginLeft: 16, color: "red"}}
                        onClick={confirm}
                    >
                        <DeleteOutlined/>
                    </Button>
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{marginLeft: 16, color: "green"}}
                        onClick={editSymptom}
                    >
                        <EditOutlined/>
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{marginLeft: 16}}
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={symptomData}
                    rowKey={(p) => p.id}
                    loading={loading}
                    onChange={handleTableChange}
                    pagination={pagination}
                />
            </div>

            <Modal
                title="Symtom qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={saveSymptom}
                onCancel={() => setIsModalVisible(false)}
                className="illness-modal"
            >
                <Form {...layout} form={formSymptom}>
                    {lang.map((item) => (
                        <>
                            <Form.Item
                                label={`Symptom nomini ${item.name}da kiriting:`}
                                name={["name", item.url]}
                                rules={[
                                    {required: true, message: "Please input symptom name!"},
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label={`Symptom haqida malumotni ${item.name}da kiriting:`}
                                name={["description", item.url]}
                                getValueFromEvent={(_, editor) => editor.getData()}
                                initialValue={ckData[item.url]}
                            >
                                <CKEditor data={ckData[item.url]} editor={ClassicEditor}/>
                            </Form.Item>
                        </>
                    ))}

                    <Form.Item
                        label={`Symptom mansub kasalliklarni tanlang:`}
                        name="sicknesses"
                        key="sicknessIdList"
                        rules={[{required: true, message: "To'ldiring!"}]}
                    >
                        <Select mode="multiple" allowClear style={{width: "100%"}}>
                            {sicknessData.map((item, index) => (
                                <Select.Option value={item.id} key={index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={`Symptom kategoriyasini tanlang:`}
                        name="symptom_category_id"
                        key="symptomCategoryId"
                        rules={[{required: true, message: "To'ldiring!"}]}
                    >
                        <Select allowClear style={{width: "100%"}}>
                            {symptomCategoryData.map((item, index) => (
                                <Select.Option value={item.id} key={index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={`${symptomInfoContent.title} kassaligi haqida ma'lumot`}
                visible={infoModal}
                centered
                width="70%"
                onOk={() => setInfoModal(false)}
                onCancel={() => setInfoModal(false)}
                className="illness-info-modal"
                key="modalInform"
            >
                <div className="illness-info" key="infromModal">
                    {ReactHtmlParser(symptomInfoContent.description)}
                </div>
            </Modal>
        </div>
    );
};

export default Symptom;
