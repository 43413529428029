import React, { useCallback, useEffect, useState } from "react";
import "../illness/Illness.css";
import { Table, Button, Modal, Form, Input, Select } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    getAllWeekdays,
    getWeekdayById,
    editWeekday,
    sendWeekday, deleteWeekday,
} from "../../server/config/WeekdaysCrud";
import { getLanguages } from "../../server/config/GetLenguage";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const Weekdays = () => {
    const [doctorSpecilityData, setDoctorSpecilityData] = useState([]);
    const [specializationData, setSpecializationData] = useState([]);
    const [editObject, setEditObject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [formWeekdays] = Form.useForm();
    const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
    const { Option } = Select;
    const [lang, setLang] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        languageGet();
    }, []);

    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getSpecialization = () => {
        getAllWeekdays()
            .then((res) => {
                setSpecializationData(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    // table functions
    const start = () => {
        setSelectedRowKeys([]);
        setEditObject([]);
    };
    const onSelectChange = (value) => {
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: "Hafta kuni nomi",
            dataIndex: "name",
            key: "name",
        },
    ];

    //   modal add symtop
    const showModal = () => {
        setIsModalVisible(true);
        formWeekdays.resetFields();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        setEditObject([])
    };

    //Specialty Crud
    const getAllSpecialitiesFunc = useCallback((page) => {
        setLoading(true);
        getAllWeekdays(page)
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                console.log(data)
                setDoctorSpecilityData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.totalElements,
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    }, []);
    const getServicesForSee = (id) => {
        getAllWeekdays(id)
            .then((res) => {
                const dataList = res.data[0].standardServiceIdList;
                let arr = [];
                if (specializationData.length > 0) {
                    specializationData.forEach((item) => {
                        if (dataList.includes(item.standardServiceId)) {
                            arr.push(item);
                        }
                    });
                    setServiceList(arr);
                }
            })
            .catch((err) => {
                toastErr();
            });
    };
    const handleOk = () => {
        if (editObject.id) {
            console.log(editObject.id)
            const id = editObject.id;
            formWeekdays
                .validateFields()
                .then((values) => {
                    console.log(values)
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    editWeekday(id, newdata)
                        .then((res) => {
                            getAllSpecialitiesFunc(pagination.current);
                            setSelectedRowKeys([]);
                            setIsModalVisible(false);
                            setEditObject([]);
                            toastEdit("Mutahasislik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            formWeekdays
                .validateFields()
                .then((values) => {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    sendWeekday(newdata)
                        .then((res) => {
                            getAllSpecialitiesFunc();
                            toastAdd("Doctor ixtisoslik yo'nalishi");
                            setIsModalVisible(false);
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }
    };
    const editSpeciality = () => {
        const id = selectedRowKeys[0];
        getWeekdayById(id)
            .then((res) => {
                setEditObject(res.data);
                showModal();
                formWeekdays.setFieldsValue(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSpeciality = () => {
        selectedRowKeys.forEach((item) => {
            deleteWeekday(item)
                .then((res) => {
                    getAllSpecialitiesFunc(pagination.current);
                    toastDel("Mutahasislik");
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Rosdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSpeciality();
            },
        });
    }

    const handleTableChange = (page) => {
        getAllSpecialitiesFunc(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    useEffect(() => {
        getAllSpecialitiesFunc();
        getSpecialization();
    }, [getAllSpecialitiesFunc]);

    return (
        <div className="illness">
            <div>
                <div style={{ marginBottom: 16 }}>
                    {/*<Button type="primary" onClick={showModal} disabled={hasSelected}>*/}
                    {/*    Yangi hafta kunini yaratish*/}
                    {/*</Button>*/}
                    <Button
                        className={selectedRowKeys.length > 0 ? "" : "display-none"}
                        style={{ marginLeft: 16, color: "red" }}
                        onClick={confirm}
                    >
                        <DeleteOutlined />
                    </Button>
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{ marginLeft: 16, color: "green" }}
                        onClick={editSpeciality}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{ marginLeft: 16 }}
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowKey={(o) => o.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={doctorSpecilityData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>

            <Modal
                title="Muataxasislik yo'nalishini qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={handleOk}
                onCancel={handleCancel}
                className="illness-modal"
            >
                <Form {...layout} form={formWeekdays}>
                    {lang.map((item, index) => (
                        <>
                            <h3
                                style={{ textTransform: "capitalize" }}
                                key={`${item.url} malumot`}
                            >
                                {item.name} da kiritiladigan ma'lumotlar
                            </h3>
                            <Form.Item
                                label={`Hafta kuni nomini ${item.url} da kiriting:`}
                                name={["name", item.url]}
                                key={`${index.url}speciality`}
                                rules={[{ required: true, message: "To'ldiring!" }]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    ))}

                </Form>
            </Modal>
        </div>
    );
};

export default Weekdays;
