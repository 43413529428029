import React, {useEffect, useState} from "react";
import "../illness/Illness.css";
import {Button, Form, Input, Select, Checkbox, Pagination} from "antd";
import {toastAdd, toastEdit, toastErr} from "../../Notifications/toast";
import {getClinicsUnArchived} from "../../server/config/ClinicsCrud";
import {createPartner, getPartnerById} from "../../server/config/PartnerCrud";
import {toast} from "react-toastify";

const Partner = () => {
    const [formPartner] = Form.useForm();
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const {Option} = Select;
    const [clinics, setClinic] = useState([]);
    const [clinicId, setClinicId] = useState("");
    const [Id, setId] = useState("");

    const getClinicsAll = (page) => {
        getClinicsUnArchived(page ? page : 1)
            .then((res) => {
                console.log(res.data)
                const Data = res.data;
                let keyed = Data.data.map((item) => {
                    return {...item, key: item.id};
                });
                setClinic(keyed);
                setTotal(Data.total)
                setCurrent(Data.current_page)

            })
            .catch((err) => {
                toastErr();
            });
    };
    useEffect(() => {
        getClinicsAll();
    }, []);

    const handleChange = (value) => {
        setClinicId(value);
        console.log(value)
        getPartnerById(value).then((res) => {
            console.log(res);
            const {data} = res;
            if (Object.keys(data).length > 0) {
                setId(data.id);
                let obj = {
                    org_account: data.org_account,
                    org_inn: data.org_inn,
                    org_mfo: data.org_mfo,
                    org_bank: data.org_bank,
                    org_title: data.org_title,
                    org_address: data.org_address,
                    org_phone: data.org_phone,
                    vendor_commission: data.vendor_commission,
                };
                console.log(obj);
                formPartner.setFieldsValue(obj);
            } else {
                formPartner.resetFields();
                setId("");
            }
        });
    };

    const handleOk = () => {
        formPartner
            .validateFields()
            .then((values) => {
                console.log(values)
                let data = {
                    org_account: values.org_account,
                    org_inn: values.org_inn,
                    org_mfo: values.org_mfo,
                    org_bank: values.org_bank,
                    org_title: values.org_title,
                    org_address: values.org_address,
                    org_phone: values.org_phone,
                    vendor_commission: +values.vendor_commission,
                    clinic_id: clinicId,
                };
                console.log(data)
                createPartner(data)
                    .then((res) => {
                        console.log(res)
                        if (Id) toastEdit("Shifoxona to'lov ma'lumotlari");
                        else toastAdd("Shifoxona to'lov ma'lumotlari");
                    })
                    .catch((errors) => {

                        if (errors.response) {
                            toast.error(`${errors.response.data.errors.message}`)
                        } else {
                            toastErr();
                        }
                        console.log('a', errors.response)
                    });

            })
            .catch((err) => {
                console.log(err)
                toastErr();
            });
    };

    const formData = [
        {
            name: "org_account",
            label: "org_account",
            disabled: true
        },
        {
            name: "org_inn",
            label: "org_inn",
            disabled: true
        },
        {
            name: "org_mfo",
            label: "org_mfo",
            disabled: true
        },
        {
            name: "org_bank",
            label: "org_bank",
            disabled: true
        },
        {
            name: "org_title",
            label: "org_title",
            disabled: true
        },
        {
            name: "org_address",
            label: "org_address",
            disabled: true
        },
        {
            name: "org_phone",
            label: "org_phone",
            disabled: true
        },
        {
            name: "vendor_commission",
            label: "vendor_commission",
            disabled: false
        },
    ];
    const [current, setCurrent] = useState(0);
    const [total, setTotal] = useState([]);

    const onChange = (page) => {
        // setCurrent(page);
        getClinicsAll(page)
    };
    return (
        <div className="illness">
            <h1 style={{textAlign: "center", fontSize: "30px"}}>
                Shifoxonalar to'lov ma'lumotlari
            </h1>
            <div style={{display: 'flex'}}>
                <div>
                    <Select
                        placeholder="Shifoxonani tanlang"
                        allowClear
                        style={{width: "400px", marginBottom: "20px"}}
                        onChange={(value) => {
                            handleChange(value);
                        }}
                    >
                        {clinics.map((item, index) => (
                            <Option value={item.id} key={index}>
                                {item.name}
                            </Option>
                        ))}

                    </Select>
                </div>
                <div>
                    {
                        total > 10 ? <Pagination style={{marginRight: '50px', paddingLeft: '50px'}} current={current}
                                                 onChange={onChange} total={total}/> : ''
                    }

                </div>
            </div>

            {clinicId ? (
                <>
                    <h2>
                        {Id
                            ? "Shifoxonani to'lov ma'lumotlarini tahrirlang"
                            : "Shifoxonaga to'lov ma'lumotlari qo'shing"}
                    </h2>
                    <Form
                        {...layout}
                        form={formPartner}
                        initialValues={{vendor_commission: 9}}

                    >
                        {formData.map((item, index) => {
                            return (

                                <Form.Item
                                    label={item.label}
                                    name={item.name}
                                    key={item.name}
                                    disabled={false}
                                    rules={[
                                        {

                                            required: true,
                                            message: `Itmmos ${item.name} ni kiriting`,

                                        },
                                    ]}
                                >
                                    <Input key={index + item.name} disabled={
                                        Id ? item.disabled : false
                                    }/>
                                </Form.Item>
                            );
                        })}
                    </Form>
                    {Id ? (
                        <Button
                            type="primary"
                            danger
                            style={{display: "block", margin: " 20px", textAlign: "center"}}
                            onClick={handleOk}
                            // disabled
                        >
                            Tahrirlash
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            danger
                            style={{display: "block", margin: " 20px", textAlign: "center"}}
                            onClick={handleOk}
                        >
                            Qo'shish
                        </Button>
                    )}
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default Partner;
