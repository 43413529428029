import { HttpRequestHub } from "../HttpRequestHub";

export const getOrders = (status) => {
    const config = {
        method: "GET",
        url: `order/moderator?status=${status}&page=0&size=10`,
    };
    return HttpRequestHub(config);
};

export const checkOrders = (orderId) => {
    const config = {
        method: "GET",
        url: `order/moderator/${orderId}`,
    };
    return HttpRequestHub(config);
};


