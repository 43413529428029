import React, { useEffect, useState } from "react";
import "./Content.css";
import { getClinicsUnArchived } from "../../server/config/ClinicsCrud";
import { Button, Select, Upload } from "antd";
import { toastAdd, toastErr } from "../../Notifications/toast";
import { toast } from "react-toastify";
import { getDoctorByClinicId } from "../../server/config/DoctorCrud";
import {
  checkIdealList,
  downloadPrices,
} from "../../server/config/ContentCrud";

function Content() {
  const { Option } = Select;
  const [clinics, setClinic] = useState([]);
  const [clinicId, setClinicId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDoctors = (value) => {
    setClinicId(value);
    getDoctorByClinicId(value)
      .then((res) => {
        const arr = res.data.content;
        setDoctors([...arr]);
        if (arr.length > 0) setDoctorId(arr[0].doctorId);
        else setDoctorId("");
        console.log(res);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const getClinicsAll = (page) => {
    getClinicsUnArchived(page ? page : 1, 100000)
      .then((res) => {
        const Data = res.data;
        let keyed = Data.content.map((item) => {
          return { ...item, key: item.clinicsId };
        });
        setClinic(keyed);
      })
      .catch((err) => {
        toastErr();
      });
  };

  const fileRequestClinic = ({ file }) => {
    if (doctorId) {
      let type = file.name.split(".").reverse()[0];
      if (type === "xlsx") {
        const data = new FormData();
        data.append("file", file);
        data.append("clinicId", clinicId);
        data.append("doctorId", doctorId);
        downloadPrices(data)
          .then((res) => {
            toastAdd("Shifoxona narx ma'lumotlari");
          })
          .catch((err) => {
            toastErr();
          });
      } else {
        toast.error("Iltimos faqat excel formatdagi file yuklang", {
          autoClose: 2000,
        });
      }
    } else {
      toast.warning("Iltimos avval shifoxona va do'ktorni tanlang");
    }
  };
  const fileRequest = ({ file }) => {
    setLoading(true);
    let type = file.name.split(".").reverse()[0];
    if (type === "xlsx") {
      let formData = new FormData();
      formData.append("file", file);
      checkIdealList(formData)
        .then((response) => {
          setLoading(false);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          toast.success("Shifoxona narx ma'lumotalari tekshirildi", {
            autoClose: 2000,
          });
        })
        .catch((err) => {
          setLoading(false);
          // toast.error(err.response.message);
          toastErr();
        });
    } else {
      toast.error("Iltimos faqat excel formatdagi file yuklang", {
        autoClose: 2000,
      });
    }
  };
  const checkChecked = () => {
    if (!doctorId)
      toast.warning("Iltimos avval shifoxona va do'ktorni tanlang", {
        autoClose: 2000,
      });
  };
  useEffect(() => {
    getClinicsAll();
  }, []);
  return (
    <div className="content_section">
      <h2>Shifoxona narx ma'lumotlari</h2>
      <Select
        placeholder="Shifoxonani tanlang"
        allowClear
        label="Shifoxonani tanlang"
        value={clinicId}
        style={{ width: "400px", marginBottom: "20px", marginRight: "40px" }}
        onChange={(value) => {
          getDoctors(value);
        }}
      >
        {clinics.map((item, index) => (
          <Option value={item.clinicsId} key={index}>
            {item.name}
          </Option>
        ))}
      </Select>
      <Select
        placeholder="Do'ktorni tanlang"
        allowClear
        label="Do'ktorni tanlang"
        value={doctorId}
        style={{ width: "400px", marginBottom: "20px", marginRight: "40px" }}
        onChange={(value) => {
          setDoctorId(value);
        }}
      >
        {doctors.map((item, index) => (
          <Option value={item.doctorId} key={index}>
            {item.name}
          </Option>
        ))}
      </Select>
      <Upload customRequest={fileRequestClinic}>
        <Button
          onClick={checkChecked}
          style={{ marginBottom: "20px" }}
          type="danger"
        >
          Shifoxona narx ma'lumotlari qo'shish
        </Button>
      </Upload>
      <div className="content_flex__div">
        <Upload customRequest={fileRequest}>
          <Button loading={loading} type="primary">
            Shifoxona narx ma'lumotlarini tekshirish
          </Button>
        </Upload>
      </div>
    </div>
  );
}

export default Content;
