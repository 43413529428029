import React, {useEffect, useState} from "react";
import "./Clinic.css";
import {
    Tabs,
    Table,
    Upload,
    Button,
    message,
    Modal,
    Input,
    Form,
    Select,
    Switch,
} from "antd";
import {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    createUser,
    sendClinics,
    getClinicsUnArchived,
    deleteClinics,
    getClinicById,
    editClinics,
    getClinicsArchived,
    makeArchiveById,
    changeBillingById,
} from "../../server/config/ClinicsCrud";
import {
    YMaps,
    Map,
    ZoomControl,
    FullscreenControl,
    SearchControl,
    GeolocationControl,
    Placemark,
} from "react-yandex-maps";
import {getRegions} from "../../server/config/RegionCrud";
import {
    getDistrictsbyPagination,
    getDistrictsbyRegion,
} from "../../server/config/DistrictCrud";
import {
    deleteFile,
    deleteUploadFile,
    fileUpload,
    getUploadFile,
    orderFileUpload,
} from "../../server/config/UploadFiles";
import {host} from "../../server/host";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";
import {toast} from "react-toastify";

const Clinic = () => {
    const [coordinates, setCoordinates] = useState([
        41.34884411932717, 69.2080062381313,
    ]);
    const [languages, setLanguages] = useState([]);
    const {Option} = Select;
    const [formClinics] = Form.useForm();
    const [formLogin] = Form.useForm();
    const [formCreateClinic] = Form.useForm();
    const {TabPane} = Tabs;
    const [fileList, setFileList] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [ckData, setCkData] = useState({uz: "<p></p>", ru: "<p></p>"});
    const [clinicList, setClinic] = useState([]);
    const [readList, setReadList] = useState("");
    const [imgLinks, setImgLinks] = useState([]);
    const [modal1Visible, setModal1Visible] = useState(false);
    const [modal2Visible, setModal2Visible] = useState(false);
    const [modalLogin, setmodalLogin] = useState(false);
    const [readModal, setReadModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editId, setEditId] = useState(false);
    const [userId, setUserID] = useState("");
    const [regionId, setRegionId] = useState([]);
    const [regionList, setRegion] = useState([]);
    const [districtList, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [pagination2, setPagination2] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [archived, setArchived] = useState(false);
    const [docFiles, setDocFiles] = useState([]);
    const [newDocFiles, setNewDocFiles] = useState([]);
    const [removedDocs, setRemovedDocs] = useState([]);
    const [filesId, setFilesId] = useState([]);

    useEffect(() => {
        getLanguages().then((res) => {
            setLanguages(res.data);
        });
        getClinicsAll();
        regionsGet();
    }, []);


    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    // upload file
    const handleChangeFile = (info) => {
        if (info.fileList.length !== 0) {
            setDocFiles(info.fileList);
            if (info.fileList.length > docFiles.length){
                setNewDocFiles([...newDocFiles, info.fileList.at(-1).originFileObj]);
            }
        }
    };

    const removeFiles = (file) => {
        if (!isNaN(file.uid)) {
            setRemovedDocs([...removedDocs, file.uid])
        }
    }

    // upload image
    const handleChange = (info) => {
        if (info.fileList.length !== 0) {
            setFileList(info.fileList);
            if (info.fileList.length > fileList.length){
                setNewImages([...newImages, info.fileList.at(-1).originFileObj]);
            }
        }
    };
    const handlePreview = () => {
        setPreviewVisible(true);
        setPreviewImage('');
    };
    const removeImage = (file) => {
        if (!isNaN(file.uid)) {
            setRemovedImages([...removedImages, file.uid])
        }
    }

    const deleteImg = (link) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteFile(link)
                    .then(() => {
                        toastDel("Rasm");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };
    const showImgModal = () => {
        setPreviewVisible(true);
    };

    const resetSearch = () => {
        setEditId("");
        setClinic(clinicList);
        setSelectedRowKeys([]);
    };

    //Table functions
    const onSelectChange = (selectedRowKeys) => {
        setEditId(selectedRowKeys[0]);
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: "odd",
                text: "Select Odd Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: "even",
                text: "Select Even Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };
    const columns = [
        {
            title: "Ma'lumotlari",
            render: (item) => (
                <Button
                    onClick={() => {
                        setReadList(item);
                        setReadModal(true);
                        setImgLinks(item.imageUrls);
                    }}
                >
                    Ko'rish
                </Button>
            ),
            key: "description",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Phone number",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "T_Discount/%",
            dataIndex: "total_discount",
            key: "total_discount",
        },
        {
            title: "C_Discount/%",
            dataIndex: "client_discount",
            key: "client_discount",
        },
        {
            title: "Billing",
            render: (item) => (
                <Switch
                    checked={item.allowed_billing ? true : false}
                    onClick={() =>
                        changeBillingClinics(item.id, item.allowed_billing)
                    }
                />
            ),
            key: "billing",
        },
        {
            title: "Archive",
            render: (item) => (
                <Switch
                    checked={archived ? true : false}
                    onClick={() => {
                        makeArchive(item.id, item.billingActive);
                    }}
                />
            ),
            key: "archive",
        },
    ];

    //Modal functions
    const clearModal = () => {
        formLogin.resetFields();
        formClinics.resetFields();
        setFileList([]);
        setRegionId("");
        setDistrictId("");
        setEditId("");
        regionsGet("");
        setCoordinates([41.34884411932717, 69.2080062381313]);
        setCkData({uz: "<p></p>", ru: "<p></p>"});
        if (filesId.length > 0) setFilesId([]);
    };

    // CRUD All Api
    const getClinicsAll = (page) => {
        setLoading(true);
        getClinicsUnArchived(page ? page : 1)
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                let keyed = Data.data.map((item) => {
                    return {...item, key: item.id};
                });
                setClinic(keyed);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.total,
                    current: Data.current_page
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    };
    const editGetClinic = () => {
        setModal1Visible(true);
        getClinicById(editId)
            .then((res) => {
                let ckObject = {};
                languages.forEach(item => {
                    ckObject[item.url] = res.data.description[item.url]
                })
                setCkData(ckObject);
                let data = {};
                let {
                    phone,
                    total_discount,
                    client_discount,
                    id,
                    name,
                    images,
                    region_id,
                    district_id,
                    location_x,
                    location_y,
                    documents,
                } = res.data

                languages.map(item => {
                    data[item.url] = name[item.url]
                })
                data["name"] = name;
                data["phone"] = phone;
                data["total_discount"] = total_discount;
                data["client_discount"] = client_discount;
                setCoordinates([location_y, location_x]);
                setDistrictId(district_id);
                setFileList(images.map(image => ({uid: image.id, url: image.image, status: 'done'})));
                setEditId(id);
                let disId = district_id;
                const files = documents.map((elem, i) => ({
                    uid: elem.id,
                    name: `file №${i + 1}`,
                    status: "done",
                    url: elem.document,
                }));
                setDocFiles(files);
                getDistrictsbyRegion(region_id)
                    .then((res) => {
                        res.data.map((item) => {
                            if (item.id === disId) {
                                setDistrictId(item.id);
                                setRegionId(item.region_id);
                                getDistrictsbyRegion(item.region_id)
                                    .then((res) => {
                                        setDistrict(res.data);
                                    })
                                    .catch((err) => {
                                        toastErr();
                                    });
                            }
                        });
                    })
                    .catch((err) => {
                        toastErr();
                    });
                formClinics.setFieldsValue(data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const saveLogin_Parol = () => {
        formLogin
            .validateFields()
            .then((values) => {
                const data = {
                    username: values.login,
                    password: values.parol,
                };
                createUser(data)
                    .then((res) => {
                        setUserID(res.data.id);
                        if (res.data.id) {
                            setmodalLogin(false);
                            setModal1Visible(true);
                        } else {
                            toastErr();
                        }
                    })
                    .catch((err) => {
                        message.error(
                            "Bunday login parol mavjud, iltimos boshqa ma'lumotlar kiriting"
                        );
                        formLogin.resetFields();
                    });
            })
            .catch((err) => {
                toastErr();
            });
    };

    const clearEditImagesAndDocs = () => {
        setCoordinates([]);
        setRemovedImages([])
        setNewImages([])
        setRemovedDocs([])
        setNewDocFiles([])
    }

    const saveClinics = () => {
        formClinics
            .validateFields()
            .then((values) => {
                console.log(values)
                let {phone, total_discount, client_discount, ...rest} = values;
                let docId = docFiles.map((item) => item.uid);
                if (editId) {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    newdata.append("location_y", coordinates[0])
                    newdata.append("location_x", coordinates[1])
                    newdata.append("district_id", districtId)
                    newdata.append("region_id", regionId)
                    // add Photos to form

                    removedImages.forEach((image, index) => {
                        newdata.append(`remove_images[]`, image)
                    })
                    newImages.forEach((image, index) => {
                        newdata.append(`images[${index}]`, image)
                    })
                    newDocFiles.forEach((file, index) => {
                        newdata.append(`documents[${index}]`, file)
                    })
                    removedDocs.forEach((file, index) => {
                        newdata.append(`remove_documents[]`, file)
                    })
                    editClinics(editId, newdata)
                        .then(() => {
                            getClinicsAll(pagination.current);
                            toastEdit("Shifoxona");
                            clearEditImagesAndDocs()
                            setModal1Visible(false);
                        })
                }
                else {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                            // newdata.append(item, JSON.stringify(values[item]))
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    newdata.append("location_y", coordinates[0])
                    newdata.append("location_x", coordinates[1])
                    newdata.append("district_id", districtId)
                    newdata.append("region_id", regionId)
                    // newdata.append("documents", [])
                    newImages.forEach((image, index) => {
                        newdata.append(`images[]`, image)
                    })
                    newDocFiles.forEach((file, index) => {
                        newdata.append(`documents[]`, file)
                    })

                    sendClinics(newdata)
                        .then(() => {
                            getClinicsAll(pagination.current);
                            toastAdd("Shifoxona");
                            clearEditImagesAndDocs()
                            setModal1Visible(false);
                        })
                    setCoordinates([]);
                }
            })
            .catch((err) => {
                toastErr();
            });
    };

    const deleteClinicsById = () => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteClinics(editId)
                    .then((res) => {
                        getClinicsAll(pagination.current);
                        toastDel("Shifoxona");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    const changeBillingClinics = (billingId, isAktive) => {
        Modal.confirm({
            content: isAktive
                ? "Billing sistemasini o'chirishni xohlaysimi ?"
                : "Billing sistemasini yoqishni xohlaysimi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                changeBillingById(billingId)
                    .then((res) => {
                        getClinicsAll();
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    //Location functions
    const regionsGet = () => {
        getRegions()
            .then((res) => {
                setRegion(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    const changeRegion = (value) => {
        setRegionId(value);
        getDistrictsbyRegion(value)
            .then((res) => {
                setDistrict(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    const changeDistrict = (value) => {
        setDistrictId(value);
    };

    //Archive functions
    const changeArchiveList = (e) => {
        if (e === "2") {
            setArchived(true);
            getClinicsArchived(1)
                .then((res) => {
                    setClinic(res.data.data);
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            setArchived(false);
            getClinicsUnArchived(1)
                .then((res) => {
                    setClinic(res.data.data);
                })
                .catch((err) => {
                    toastErr();
                });
        }
    };

    const makeArchive = (id) => {
        Modal.confirm({
            content: (
                <>
                    {archived
                        ? "Shifoxonani arxivlanganlar ro'yxatidan o'chirishni xohlaysizmi ?"
                        : "Shifoxonani arxivlanganlar ro'yxatiga o'tkazishni xohlaysizmi ?"}
                </>
            ),
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                makeArchiveById(id)
                    .then((res) => {
                        archived
                            ? toast.success("Shifoxona arxivlanganlar ro'yxatidan o'chirildi")
                            : toast.success("Shifoxona arxivlanganlar ro'yxatiga qo'shildi");
                        getClinicsAll(pagination.current);
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    const handleTableChange = (page) => {
        getClinicsAll(page.current);
        setSelectedRowKeys([]);
        setEditId("");
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    const handleTableChange2 = (page) => {
        setSelectedRowKeys([]);
        setEditId("");
        setPagination2((prev) => ({
            ...prev,
            current: page.current,
        }));
        setLoading2(true);
        getClinicsArchived(page.current)
            .then((res) => {
                setClinic(res.data.content);
                setLoading2(false);
            })
            .catch((err) => {
                toastErr();
            });
    };

    const fileRemove = (file) => {
        let udiObjects = filesId.filter((item) => item.uid !== file.uid);
        let newFiles = docFiles.filter((item) => item.uid !== file.uid);
        let uidObj = filesId.filter((item) => item.uid === file.uid);
        setDocFiles(newFiles);
        setFilesId(udiObjects);
        if (uidObj.length)
            deleteUploadFile(uidObj[0].fileId)
                .then((res) => {
                })
                .catch((err) => {
                });
    };

    const fileDownload = (file) => {
        let [uidObj] = filesId.filter((item) => item.uid === file.uid);
        if (uidObj) getUploadFile(uidObj.fileId, file.name);
    };

    const fileRequest = ({file, onError, onProgress, onSuccess}) => {
        let formData = new FormData();
        formData.append("file", file);
        orderFileUpload(formData, onProgress)
            .then(({data}) => {
                setFilesId((prev) => [
                    ...prev,
                    {
                        fileId: data,
                        uid: file.uid,
                    },
                ]);
                setDocFiles((prev) => [
                    ...prev,
                    {uid: data, name: `file №${docFiles.length + 1}`, status: "done"},
                ]);
                onSuccess();
            })
            .catch(() => {
                onError();
            });
    };

    return (
        <div className="clinics_section">
            <Tabs defaultActiveKey="1" centered onChange={changeArchiveList}>
                <TabPane tab="Clinics" key="1">
                    <Table
                        rowSelection={{type: "radio", ...rowSelection}}
                        columns={columns}
                        dataSource={clinicList}
                        bordered
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                        rowKey={(e) => e.id}
                        title={() => (
                            <div>
                                <Button
                                    onClick={() => {
                                        setModal1Visible(true);
                                        clearModal();
                                    }}
                                    disabled={editId ? true : false}
                                >
                                    Yangi Klinika qo'shish
                                </Button>
                                <Button
                                    className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                    style={{marginLeft: 16, color: "red"}}
                                    onClick={deleteClinicsById}
                                >
                                    <DeleteOutlined/>
                                </Button>
                                <Button
                                    className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                    style={{marginLeft: 16, color: "green"}}
                                    onClick={editGetClinic}
                                >
                                    <EditOutlined/>
                                </Button>
                                <Button
                                    style={{marginLeft: "20px"}}
                                    onClick={resetSearch}
                                    disabled={editId ? false : true}
                                    type="primary"
                                >
                                    {" "}
                                    Reset{" "}
                                </Button>
                            </div>
                        )}
                    />
                </TabPane>
                <TabPane tab="Archived" key="2">
                    <Table
                        rowSelection={{type: "radio", ...rowSelection}}
                        columns={columns}
                        dataSource={clinicList}
                        rowKey={(p) => p.id}
                        bordered
                        pagination={pagination2}
                        loading={loading2}
                        onChange={handleTableChange2}
                        title={() => (
                            <div>
                                <Button
                                    className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                    style={{marginLeft: 16, color: "red"}}
                                    onClick={deleteClinicsById}
                                >
                                    <DeleteOutlined/>
                                </Button>
                                <Button
                                    className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                    style={{marginLeft: 16, color: "green"}}
                                    onClick={editGetClinic}
                                >
                                    <EditOutlined/>
                                </Button>
                                <Button
                                    style={{marginLeft: "20px"}}
                                    onClick={resetSearch}
                                    disabled={editId ? false : true}
                                    type="primary"
                                >
                                    {" "}
                                    Reset{" "}
                                </Button>
                            </div>
                        )}
                    />
                </TabPane>
            </Tabs>

            {/* Yangi Klinika qo'shish */}
            <Modal
                width={1000}
                title={
                    editId
                        ? "Klinika ma'lumotlarini tahrirlash"
                        : "Klinika Ma'lumotlarini Kiritish"
                }
                centered
                visible={modal1Visible}
                onOk={saveClinics}
                onCancel={() => {
                    setModal1Visible(false);
                    clearEditImagesAndDocs()
                }}
            >
                <Form {...layout} form={formClinics}>
                    {editId ? ""
                         :
                        <div>
                            <Form.Item
                                label="Klinika Usernameni kiriting"
                                name="username"
                                key="login"
                                rules={[{required: true, message: "Please input your username!"}]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Klinika Passwordini kiriting"
                                name="password"
                                key="parol"
                                rules={[{required: true, message: "Please input your password!"}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </div>
                    }

                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <div>
                                    <h2>Ma'lumotlarni {item.name} da kiriting</h2>

                                    <Form.Item
                                        label="Klinika Nomini Kiriting:"
                                        name={['name', item.url]}
                                        rules={[{required: true, message: "To'ldirilmagan"}]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={`Clinika ${item.name} nomi...`}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Klinika haqida malumotni kiriting:"
                                        name={['description', item.url]}
                                        getValueFromEvent={(_, editor) => editor.getData()}
                                        initialValue={ckData[item.url]}
                                    >
                                        <CKEditor data={ckData[item.url]} editor={ClassicEditor}/>
                                    </Form.Item>
                                </div>
                            );
                        })
                        : ""}

                    <Form.Item
                        name="total_discount"
                        key="total_discount"
                        label="Umumiy chegirma miqdorini kiriting (%)"
                        rules={[{required: true, message: "To'ldirilmagan"}]}
                    >
                        <Input size="large" placeholder="Umumiy chegirma..."/>
                    </Form.Item>

                    <Form.Item
                        name="client_discount"
                        key="client_discount"
                        label="Clientga ajratiladigan foiz stavkani kiriting (%)"
                        rules={[{required: true, message: "To'ldirilmagan"}]}
                    >
                        <Input size="large" placeholder="Clientni ulushi..."/>
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        key="phoneNumber"
                        label="Klinika telefon raqamini kiriting:"
                    >
                        <Input size="large" placeholder="Clinika telefon raqami..."/>
                    </Form.Item>

                    <div className="clinic_modal__location">
                        <div className="clinic_modal__region">
                            <Form.Item
                                label="Klinika hududini tanlang:"
                                key="Klinika hududini tanlang:"
                            >
                                <Select
                                    placeholder="Klinika joylashgan viloyatni tanlang"
                                    onChange={changeRegion}
                                    value={regionId}
                                >
                                    {regionList
                                        ? regionList.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={`regionList${index}`}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="clinic_modal__district">
                            <Form.Item label="Tumanni tanlang" key="Klinika joylashgan tuman">
                                <Select
                                    placeholder="Klinika joylashgan tumanni tanlang"
                                    onChange={changeDistrict}
                                    value={districtId}
                                >
                                    {districtList
                                        ? districtList.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={`districtList${index}`}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item
                        label="Rasm:"
                        key="Rasm:"
                        className="image-upload"
                        {...layout}
                    >
                        <div style={{display: "flex"}}>
                            {/*<div className="info_images__div">*/}
                            {/*    {urlList*/}
                            {/*        ? urlList.map((item, index) => {*/}
                            {/*            return (*/}
                            {/*                <div key={`urlList${index}`} className="info_img__wrap">*/}
                            {/*                    <div className="info_img">*/}
                            {/*                        <img*/}
                            {/*                            src={item.url}*/}
                            {/*                            alt="error img"*/}
                            {/*                        />*/}
                            {/*                    </div>*/}
                            {/*                    <div className="info_img__up">*/}
                            {/*                        <EyeOutlined onClick={showImgModal}/>*/}
                            {/*                        <DeleteOutlined onClick={() => deleteImg(item)}/>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            );*/}
                            {/*        })*/}
                            {/*        : ""}*/}
                            {/*</div>*/}
                            <Upload
                                key={"fileListUpload"}
                                fileList={fileList}
                                listType="picture-card"
                                onPreview={(file) => handlePreview(file)}
                                onChange={handleChange}
                                onRemove={removeImage}
                                beforeUpload={() => false}
                                maxCount={5}
                                accept=".jpg, .jpeg, .png, .pdf, txt, xls, docs"
                            >
                                {fileList.length < 5 ? (
                                    <div>
                                        <div className="ant-upload-text">Rasmni yuklang</div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Upload>
                            <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={() => setPreviewVisible(false)}
                                cancelText="Yopish"
                            >
                                <img
                                    alt="example"
                                    style={{width: "100%"}}
                                    src={previewImage}
                                />
                            </Modal>
                        </div>
                    </Form.Item>

                    <label style={{marginRight: "30px"}}>
                        Klinika hujjatlarini yuklang
                    </label>

                    <Upload
                        multiple
                        key={"docFilesUpload"}
                        fileList={docFiles}
                        listType="picture"
                        onRemove={removeFiles}
                        onDownload={fileDownload}
                        onChange={handleChangeFile}
                        beforeUpload={() => false}
                        showUploadList={{
                            showDownloadIcon: true,
                            downloadIcon: <DownloadOutlined/>,
                        }}
                    >
                        <Button type="primary">Upload</Button>
                    </Upload>
                    {/*<Upload*/}
                    {/*    multiple*/}
                    {/*    key={"docFilesUpload"}*/}
                    {/*    fileList={docFiles}*/}
                    {/*    listType="picture"*/}
                    {/*    onRemove={fileRemove}*/}
                    {/*    onDownload={fileDownload}*/}
                    {/*    customRequest={fileRequest}*/}
                    {/*    showUploadList={{*/}
                    {/*        showDownloadIcon: true,*/}
                    {/*        downloadIcon: <DownloadOutlined/>,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Button type="primary">Upload</Button>*/}
                    {/*</Upload>*/}

                    <div className="info-div-space"></div>
                    <div className="info-div-space"></div>

                    <h4>Klinika joylashgan joyni tanlang:</h4>
                    <YMaps>
                        <div>
                            <Map
                                onClick={(e) => setCoordinates(e.get("coords"))}
                                style={{width: "100%", height: "70vh"}}
                                defaultState={{center: coordinates, zoom: 14}}
                                modules={["Placemark", "geocode", "geoObject.addon.balloon"]}
                            >
                                {coordinates.length > 0 ? (
                                    <Placemark geometry={coordinates}/>
                                ) : null}
                                <ZoomControl/>
                                <FullscreenControl/>
                                <SearchControl/>
                                <GeolocationControl/>
                            </Map>
                        </div>
                    </YMaps>
                </Form>
            </Modal>



            {/* Klinika haqidagi to'liq ma'lumotlar */}
            <Modal
                width={1000}
                title={`${readList.name} shifohonasi Ma'lumotlari`}
                centered
                visible={readModal}
                onOk={() => setReadModal(false)}
                onCancel={() => setReadModal(false)}
            >
                <div style={{minHeight: "40vh"}}>
                    <div style={{marginBottom: "20px"}}>
                        <h3>{ReactHtmlParser(readList.description)}</h3>
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap", gap: "20px"}}>
                        {imgLinks
                            ? imgLinks.map((item) => {
                                return (
                                    <img
                                        src={`${host}files/public/${item}`}
                                        alt="error img"
                                        style={{width: "48%"}}
                                    />
                                );
                            })
                            : ""}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Clinic;
