import { HttpRequestHub } from "../HttpRequestHub";

export const getAllSpecialities = () => {
  const config = {
    method: "GET",
    url: `doctor-information-list-crud/specialties/get-list`,
  };
  return HttpRequestHub(config);
};

export const sendSpeciality = (data) => {
  const config = {
    method: "POST",
    url: "doctor-information-list-crud/specialties/store",
    data,
  };
  return HttpRequestHub(config);
};

export const deleteSpecialityConfig = (id) => {
  const config = {
    method: "DELETE",
    url: "speciality/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const getSpecialityByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: `doctor-information-list-crud/specialties/show/${id}`,
  };
  return HttpRequestHub(config);
};

export const editSpecialtyConfig = (id,data) => {
  const config = {
    method: "POST",
    url: `doctor-information-list-crud/specialties/update/${id}`,
    data,
  };
  return HttpRequestHub(config);
};


export const getAllServer = () => {
  const config = {
    method: "GET",
    url: `service-category/get-list?type=standard`,
  };
  return HttpRequestHub(config);
};
