import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "./toast.css";

export const toastAdd = (text) => {
  toast.success(
    <div>
      <CheckOutlined style={{ color: "#07bc0c" }} className="iconStyle" />
      Yangi {text} qo'shildi
    </div>,
    { autoClose: 2000 }
  );
};

export const toastEdit = (text) => {
  toast.warning(
    <div>
      <CheckOutlined style={{ color: "#f1c40f" }} className="iconStyle" />
      {text} tahrirlandi
    </div>,
    { autoClose: 2000 }
  );
};

export const toastDel = (text) => {
  toast.info(
    <div>
      <CheckOutlined style={{ color: "#3498db" }} className="iconStyle" />
      {text} o'chirildi
    </div>,
    { autoClose: 2000 }
  );
};

export const toastErr = () => {
  toast.error(
    <div>
      <ExclamationOutlined style={{ color: "#e74c3c" }} className="iconStyle" />
      Xatolik yuz berdi, qayta urunib ko'ring
    </div>,
    { autoClose: 2000 }
  );
};
