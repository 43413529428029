import { HttpRequestHub } from "../HttpRequestHub";

export const getTemplates = (page) => {
  const config = {
    method: "GET",
    url: `full-templates/get-list?page=${page}`,
  };
  return HttpRequestHub(config);
};

export const getTemplatesBySubcategoryId = (id, page) => {
  const config = {
    method: "GET",
    url: `template/med-card-sub-category/${id}?page=${page - 1}&size=10`,
  };
  return HttpRequestHub(config);
};

export const sendTemplate = (data) => {
  const config = {
    method: "POST",
    url: "full-templates/store",
    data,
  };
  return HttpRequestHub(config);
};

export const getFullTemplateById = (id) => {
  const config = {
    method: "GET",
    url: "full-templates/show/" + id,
  };
  return HttpRequestHub(config);
};

export const getTemplateById = (id) => {
  const config = {
    method: "GET",
    url: "templates/show/" + id,
  };
  return HttpRequestHub(config);
};

export const editTemplate = (id, data) => {
  const config = {
    method: "POST",
    url: "full-templates/update/" + id,
    data,
  };
  return HttpRequestHub(config);
};

export const deleteTemplate = (id) => {
  const config = {
    method: "DELETE",
    url: "template/delete/" + id,
  };
  return HttpRequestHub(config);
};
