import {HttpRequestHub} from "../HttpRequestHub";

export const getAllMedCategories = () => {
    const config = {
        method: "GET",
        url: "med-question-groups/get-list",
    };
    return HttpRequestHub(config);
};

export const getMedCategory = (id) => {
    const config = {
        method: "GET",
        url: `med-question-groups/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editMedCategory = (data,id) => {
    const config = {
        method: "POST",
        url: `med-question-groups/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};


export const sendMedCategory = (data) => {
    const config = {
        method: "POST",
        url: "med-question-groups/store",
        data,
    };
    return HttpRequestHub(config);
};

export const getAllMed_Q = (id) => {
        console.log(id)
        const config = {
            method: "GET",
            url: `med-question-groups/show/${id}`,
        };
        return HttpRequestHub(config);
};

export const getAllMed_Q_ITEM = (id) => {
    console.log(id)
    const config = {
        method: "GET",
        url: `med-questions/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const sendUserQuestionMed= (data) => {
    const config = {
        method: "POST",
        url: "med-questions/store",
        data,
    };
    return HttpRequestHub(config);
};

export const editUserQuestionMed = (data,id) => {
    const config = {
        method: "POST",
        url: `med-questions/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};


export const getAllMed_Answer = (id) => {
    console.log(id)
    const config = {
        method: "GET",
        url: `med-questions/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const getMed_Answer_Detail = (id) => {
    console.log(id)
    const config = {
        method: "GET",
        url: `med-question-answers/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const sendMedAnswer = (data) => {
    const config = {
        method: "POST",
        url: "med-question-answers/store",
        data,
    };
    return HttpRequestHub(config);
};

export const EditMedAnswer = (data,id) => {
    const config = {
        method: "POST",
        url: `med-question-answers/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};