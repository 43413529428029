import { HttpRequestHub } from "../HttpRequestHub";

export const getFooter = () => {
    const config = {
        method: "GET",
        url: "get-footer-info",
    };
    return HttpRequestHub(config);
};

export const sendFooter = (data) => {
    const config = {
        method: "POST",
        url: "footer/",
        data,
    };
    return HttpRequestHub(config);
};

export const editFooter = (data) => {
    const config = {
        method: "POST",
        url: "update-footer-info",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteFooter = () => {
    const config = {
        method: "DELETE",
        url: "footer/delete/",
    };
    return HttpRequestHub(config);
};
