import React, { useCallback, useEffect, useState } from "react";
import "../illness/Illness.css";
import { Table, Button, Modal, Form, Input, Select } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    getDoctorLangById,
    editDoctorLang,
    sendDoctorLang,
    getAllDoctorLanguages,
} from "../../server/config/DoctorLanguagesCrud";
import { getLanguages } from "../../server/config/GetLenguage";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const DoctorLanguages = () => {
    const [doctorLangData, setDoctorLangData] = useState([]);
    const [editObject, setEditObject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [formSpeciality] = Form.useForm();
    const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
    const [lang, setLang] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        languageGet();
    }, []);

    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    // table functions
    const start = () => {
        setSelectedRowKeys([]);
        setEditObject([]);
    };
    const onSelectChange = (value) => {
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: "Doctor biladigan tillar",
            dataIndex: "name",
            key: "name",
        },
    ];

    //   modal add symtop
    const showModal = () => {
        setIsModalVisible(true);
        formSpeciality.resetFields();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    //Specialty Crud
    const getAllDoctorLang = useCallback(() => {
        setLoading(true);
        getAllDoctorLanguages()
            .then((res) => {
                console.log(res.data)
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                setDoctorLangData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.length,
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    }, []);

    const handleOk = () => {
        if (selectedRowKeys.length > 0) {
            const id = editObject.id;
            formSpeciality
                .validateFields()
                .then((values) => {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    editDoctorLang(id, newdata)
                        .then((res) => {
                            getAllDoctorLang();
                            setSelectedRowKeys([]);
                            toastEdit("Doctor biladigan tillar");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            formSpeciality
                .validateFields()
                .then((values) => {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    sendDoctorLang(newdata)
                        .then((res) => {
                            getAllDoctorLang();
                            toastAdd("Doctor biladigan til");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }

        setSelectedRowKeys([]);
        setEditObject([]);
        setIsModalVisible(false);
    };
    const editSpeciality = () => {
        const id = selectedRowKeys[0];
        getDoctorLangById(id)
            .then((res) => {
                setEditObject(res.data);
                showModal();
                formSpeciality.setFieldsValue(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSpeciality = () => {
        selectedRowKeys.forEach((item) => {
            editDoctorLang(item)
                .then((res) => {
                    getAllDoctorLang(pagination.current);
                    toastDel("Mutahasislik");
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Rosdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSpeciality();
            },
        });
    }

    const handleTableChange = (page) => {
        getAllDoctorLang(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    useEffect(() => {
        getAllDoctorLang();
        // gerServices();
    }, [getAllDoctorLang]);

    return (
        <div className="illness">
            <div>
                <div style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={showModal} disabled={hasSelected}>
                        Yangi Mutaxasislik yaratish
                    </Button>
                    <Button
                        className={selectedRowKeys.length > 0 ? "" : "display-none"}
                        style={{ marginLeft: 16, color: "red" }}
                        onClick={confirm}
                    >
                        <DeleteOutlined />
                    </Button>
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{ marginLeft: 16, color: "green" }}
                        onClick={editSpeciality}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{ marginLeft: 16 }}
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowKey={(o) => o.id}
                    rowSelection={{type: "radio", ...rowSelection}}
                    columns={columns}
                    dataSource={doctorLangData}
                    // pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>

            <Modal
                title="Doctor biladigan til qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={handleOk}
                onCancel={handleCancel}
                className="illness-modal"
            >
                <Form {...layout} form={formSpeciality}>
                    {lang.map((item, index) => (
                        <>
                            <h3
                                style={{ textTransform: "capitalize" }}
                                key={`${item.url}malumot`}
                            >
                                {item.name} tilida kiritiladigan ma'lumotlar
                            </h3>
                            <Form.Item
                                label={`Doctor biladigan til nomini ${item.url} da kiriting:`}
                                name={["name", item.url]}
                                key={`${index}speciality`}
                                rules={[{ required: true, message: "To'ldiring!" }]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    ))}
                </Form>
            </Modal>
        </div>
    );
};

export default DoctorLanguages;
