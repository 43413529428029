import { HttpRequestHub } from "../HttpRequestHub";

export const getAllSymptomsConfig = () => {
  const config = {
    method: "GET",
    url: `symptoms/get-list`,
  };
  return HttpRequestHub(config);
};

export const getSymptomByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: `symptoms/show/${id}`,
  };
  return HttpRequestHub(config);
};

export const sendSymptomConfig = (data) => {
  const config = {
    method: "POST",
    url: "symptoms/store",
    data,
  };
  return HttpRequestHub(config);
};

export const editSymptomConfig = (data,id) => {
  const config = {
    method: "POST",
    url: `symptoms/update/${id}`,
    data,
  };
  return HttpRequestHub(config);
};

export const deleteSymptomConfig = (id) => {
  const config = {
    method: "DELETE",
    url: "symptom/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const getSymptomsBySymptomCategory = (id) => {
  const config = {
    method: "GET",
    url: `symptom/symptom-category/` + id,
  };
  return HttpRequestHub(config);
};
