import { HttpRequestHub } from "../HttpRequestHub";

export const getAllDictionary = (page = 1) => {
  const config = {
    method: "GET",
    url: `search/?page=${page - 1}&size=10`,
  };
  return HttpRequestHub(config);
};

export const sendDictionaryConfig = (data) => {
  const config = {
    method: "POST",
    url: "search/",
    data,
  };
  return HttpRequestHub(config);
};

export const deleteDictionaryConfig = (id) => {
  const config = {
    method: "DELETE",
    url: "search/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const editDictionaryConfig = (data) => {
  const config = {
    method: "POST",
    url: "search/edit",
    data,
  };
  return HttpRequestHub(config);
};

export const getDictionaryByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: "search/" + id,
  };
  return HttpRequestHub(config);
};

export const uploadDictionaryConfig = (data) => {
  const config = {
    method: "POST",
    url: "content/dictionary",
    data,
  };
  return HttpRequestHub(config);
};
