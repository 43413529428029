import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, Upload } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getLanguages } from "../../server/config/GetLenguage";
import Text from "antd/lib/typography/Text";
import { toast } from "react-toastify";
import { toastDel, toastEdit, toastErr } from "../../Notifications/toast";
import {
  deleteDictionaryConfig,
  editDictionaryConfig,
  getAllDictionary,
  getDictionaryByIdConfig,
  sendDictionaryConfig,
  uploadDictionaryConfig,
} from "../../server/config/DictionaryCrud";

const Dictionary = () => {
  const [NewsForm] = Form.useForm();
  const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editObject, setEditObject] = useState([]);
  const [lang, setLang] = useState([]);
  const [newsData, setNewsdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
    hideOnSinglePage: true,
  });

  useEffect(() => {
    languageGet();
    getAllNews();
  }, []);

  const languageGet = () => {
    getLanguages()
      .then((res) => {
        setLang(res.data);
        let ckObject = {};
        res.data.forEach((item) => {
          ckObject[item] = "";
        });
      })
      .catch((err) => {
        toastErr();
      });
  };

  //Table functions
  const hasSelected = selectedRowKeys.length > 0;
  const onSelectChange = (value) => {
    setSelectedRowKeys(value); 
  };
  const start = () => {
    setSelectedRowKeys([]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "Lug'at nomi",
      dataIndex: "basis",
    },
    {
      title: "Lug'at kengaytmasi",
      dataIndex: "synonyms",
    },
  ];

  //Crud News
  const getAllNews = (page) => {
    setLoading(true);
    getAllDictionary(page)
      .then((res) => {
        setLoading(false); 
        let dataWithkey = res.data.content.map((item) => {
          return { ...item, key: item.searchId };
        });
        setPagination((prev) => ({
          ...prev,
          total: res.data.totalElements,
        }));
        setNewsdata(dataWithkey);
      })
      .catch((err) => {
        toastErr();
        setLoading(false);
      });
  };
  const saveNews = () => {
    if (editObject.length > 0) {
      NewsForm.validateFields().then((res) => {
        let data = Object.keys(res).map((item) => {
          return {
            ...res[item],
            language: item,
            searchId: selectedRowKeys[0],
          };
        });
        editDictionaryConfig(data)
          .then(() => {
            toastEdit("Dictionary");
            setSelectedRowKeys([]);
            getAllNews();
            handleCancel();
          })
          .catch((err) => {
            toastErr();
          });
      });
    } else {
      NewsForm.validateFields().then((res) => {
        let data = Object.keys(res).map((item) => {
          return {
            ...res[item],
            language: item,
          };
        }); 
        sendDictionaryConfig(data)
          .then(() => {
            toast.success("Yangi lug'at qo'shildi", { autoClose: 2000 });
            getAllNews();
            setIsModalVisible(false);
            setPagination((prev) => ({
              ...prev,
              current: 1,
            }));
          })
          .catch((err) => {
            toastErr();
          });
      });
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    let data = {};
    lang.forEach((item) => {
      data[item] = {
        title: null,
        author: null,
        description: null,
        badge: null,
      };
    });
    NewsForm.setFieldsValue(data);
  };
  const deleteNews = () => {
    selectedRowKeys.forEach((item) => {
      deleteDictionaryConfig(item)
        .then(() => {
          toastDel("Lug'at");
          getAllNews();
          getDictionaryByIdConfig(item).then((res) => {});
        })
        .catch((err) => {
          toastErr();
        });
    });
    setSelectedRowKeys([]);
  };
  const editNews = () => {
    let id = selectedRowKeys[0];
    getDictionaryByIdConfig(id)
      .then((res) => {
        setEditObject(res.data);
        let data = {};
        res.data.forEach((item) => {
          let { language, ...rest } = item;
          data[language] = { ...rest };
        });
        NewsForm.setFieldsValue(data);
        setIsModalVisible(true);
      })
      .catch((err) => {
        toastErr();
      });
  };

  // confirmation
  function confirm() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Rosdan ham o'chirishni xohlaysizmi?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk() {
        deleteNews();
      },
    });
  }

  //Modal functions
  const showModal = () => {
    setIsModalVisible(true);
    NewsForm.resetFields();
    setSelectedRowKeys([]);
    setEditObject([]);
  };

  const handleTableChange = (page) => {
    getAllNews(page.current);
    setSelectedRowKeys([]);
    setEditObject([]);
    setPagination((prev) => ({
      ...prev,
      current: page.current,
    }));
  };

  const fileRequest = ({ file }) => {
    let type = file.name.split(".").reverse()[0];
    if (type === "xlsx") {
      let formData = new FormData();
      formData.append("file", file);
      uploadDictionaryConfig(formData)
        .then((res) => { 
          toast.success("Lug'at ma'lumotlari saqlandi", {
            autoClose: 2000,
          });
        })
        .catch((err) => {
          toast.err(err.message, {
            autoClose: 2000,
          });
        });
    } else {
      toast.error("Iltimos faqat excel formatdagi file yuklang", {
        autoClose: 2000,
      });
    }
  };
  return (
    <div className="illness dictionary_section">
      <div className="category_upload__buttons">
        <Upload customRequest={fileRequest}>
          <Button type="danger">Lug'at ma'lumotlarini yuklang</Button>
        </Upload>
      </div>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={newsData}
        pagination={pagination}
        onChange={handleTableChange}
        title={() => (
          <>
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={showModal}
              disabled={hasSelected}
            >
              Yangi Lug'at Qo'shish
            </Button>
            <Button
              className={selectedRowKeys.length > 0 ? "" : "display-none"}
              style={{ marginLeft: 16, color: "red" }}
              onClick={confirm}
            >
              <DeleteOutlined />
            </Button>
            <Button
              className={selectedRowKeys.length === 1 ? "" : "display-none"}
              style={{ marginLeft: 16, color: "green" }}
              onClick={editNews}
            >
              <EditOutlined />
            </Button>
            <Button
              type="primary"
              onClick={start}
              disabled={!hasSelected}
              style={{ marginLeft: 16 }}
            >
              Yangilash
            </Button>
          </>
        )}
      />

      <Modal
        title="Yangi lug'at qo'shish"
        visible={isModalVisible}
        centered
        width="70%"
        onOk={saveNews}
        onCancel={handleCancel}
        className="illness-modal"
      >
        <Form form={NewsForm} {...layout}>
          {lang.map((item, index) => (
            <div key={index}>
              <Text keyboard style={{ textTransform: "capitalize" }}>
                {item}da kiritiladigan ma'lumotlar
              </Text>
              <Form.Item
                label="Lug'at nomini kiriting"
                name={[item, "basis"]}
                key={[item, "basis"]}
                rules={[
                  { required: true, message: "Please input illness name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Lug'at kengaytmasini kiriting"
                name={[item, "synonyms"]}
                key={[item, "synonyms"]}
                rules={[
                  { required: true, message: "Please input illness name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          ))}
        </Form>
      </Modal>
    </div>
  );
};

export default Dictionary;
