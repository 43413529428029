import {HttpRequestHub, HttpRequestHubFile} from "../HttpRequestHub";

export const createUser = (data) => {
  const config = {
    method: "POST",
    url: "auth/reception/signup",
    data,
  };
  return HttpRequestHub(config);
};

export const editLogin = (data) => {
  const config = {
    method: "POST",
    url: "login/edit",
    data,
  };
  return HttpRequestHub(config);
};

export const getClinicsUnArchived = (page, size = "10") => {
  const config = {
    method: "GET",
    url: `clinic/list?page=${page}&size=${size}`,
  };
  return HttpRequestHub(config);
};

export const getClinicsArchived = (page) => {
  const config = {
    method: "GET",
    url: `clinic/list?status=Inactive&page=${page}&size=10`,
  };
  return HttpRequestHub(config);
};

export const getClinicById = (id) => {
  const config = {
    method: "GET",
    url: "clinic/show/" + id,
  };
  return HttpRequestHub(config);
};

export const sendClinics = (data) => {
  const config = {
    method: "POST",
    url: "clinic/store",
    data,
  };
  return HttpRequestHub(config);
};

export const editClinics = (id, data) => {
  console.log(data)
  const config = {
    method: "POST",
    url: "clinic/update/" + id,
    data,
  };
  return HttpRequestHubFile(config);
};

export const editClinicsFinancial = (data) => {
  const config = {
    method: "POST",
    url: "clinics/edit/financial",
    data,
  };
  return HttpRequestHub(config);
};

export const deleteClinics = (id) => {
  const config = {
    method: "DELETE",
    url: "clinics/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const editFinancialId = (data) => {
  const config = {
    method: "POST",
    url: "clinics/edit/financial",
    data,
  };
  return HttpRequestHub(config);
};

export const makeArchiveById = (id) => {
  const config = {
    method: "POST",
    url: "clinic/update-status/" + id,
  };
  return HttpRequestHub(config);
};

export const changeBillingById = (id) => {
  const config = {
    method: "POST",
    url: "clinic/update-billing/" + id,
  };
  return HttpRequestHub(config);
};
