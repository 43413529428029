import React, { useCallback, useEffect, useState } from "react";
import "../illness/Illness.css";
import { Table, Button, Modal, Form, Input, Select } from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    deleteSpecialityConfig,
    editSpecialtyConfig,
    getAllSpecialities,
    getSpecialityByIdConfig,
    sendSpeciality,
} from "../../server/config/SpecialityConfig";
import { getAllServicesConfig } from "../../server/config/ServiceCrud";
import { getLanguages } from "../../server/config/GetLenguage";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";
import {
    editSpecializationsConfig,
    getAllSpecialization,
    getSpecializationByIdConfig, sendSpecializations
} from "../../server/config/DoctorSpecializationsCrud";

const DoctorSpecializations = () => {
    const [specialityData, setSpecialityData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [editObject, setEditObject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [formSpeciality] = Form.useForm();
    const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
    const { Option } = Select;
    const [lang, setLang] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        languageGet();
    }, []);

    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const gerServices = () => {
        getAllServicesConfig()
            .then((res) => {
                setServiceData(res.data.content);
            })
            .catch((err) => {
                toastErr();
            });
    };

    // table functions
    const start = () => {
        setSelectedRowKeys([]);
        setEditObject([]);
    };
    const onSelectChange = (value) => {
        console.log(value)
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: "Doktor ixtisosliklari",
            dataIndex: "name",
            key: "name",
        },
        // {
        //   title: "Mutaxasislik standart servislari",
        //   render: (item) => (
        //     <Select
        //       defaultValue="Servislarni ko'rish"
        //       style={{ width: 200 }}
        //       onClick={() => getServicesForSee(item.id)}
        //     >
        //       {serviceList.length > 0
        //         ? serviceList.map((item, index) => {
        //             return (
        //               <Option
        //                 key={index}
        //                 value="disabled"
        //                 style={{ pointerEvents: "none" }}
        //               >
        //                 {item.name}
        //               </Option>
        //             );
        //           })
        //         : ""}
        //     </Select>
        //   ),
        //
        //   key: "specialityId",
        // },
    ];

    //   modal add symtop
    const showModal = () => {
        setIsModalVisible(true);
        formSpeciality.resetFields();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    //Specialty Crud
    const getAllSpecialitiesFunc = useCallback((page) => {
        setLoading(true);
        getAllSpecialization(page)
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                setSpecialityData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.totalElements,
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    }, []);
    const getServicesForSee = (id) => {
        getSpecializationByIdConfig(id)
            .then((res) => {
                const dataList = res.data[0].standardServiceIdList;
                let arr = [];
                if (serviceData.length > 0) {
                    serviceData.forEach((item) => {
                        if (dataList.includes(item.standardServiceId)) {
                            arr.push(item);
                        }
                    });
                    setServiceList(arr);
                }
            })
            .catch((err) => {
                toastErr();
            });
    };
    const handleOk = () => {
        console.log(editObject)
        if (editObject.id) {
            const id = selectedRowKeys[0];
            formSpeciality
                .validateFields()
                .then((values) => {
                    let { service, ...rest } = values;

                    let data={
                        name:{
                            ru:values.name.ru,
                            uz:values.name.uz
                        }
                    }
                    console.log(data)
                    editSpecializationsConfig(data,editObject.id)
                        .then((res) => {
                            getAllSpecialitiesFunc(pagination.current);
                            setSelectedRowKeys([]);
                            toastEdit("Mutahasislik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }
        else {
            formSpeciality
                .validateFields()
                .then((values) => {
                    console.log(values)
                    let data={
                        name:{
                            ru:values.name.ru,
                            uz:values.name.uz
                        }
                    }
                    sendSpecializations(data)
                        .then((res) => {
                            getAllSpecialitiesFunc(pagination.current);
                            toastAdd("mutahasislik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }

        setSelectedRowKeys([]);
        setEditObject([]);
        setIsModalVisible(false);
    };
    const editSpeciality = () => {
        const id = selectedRowKeys[0];
        getSpecializationByIdConfig(id)
            .then((res) => {
                setEditObject(res.data);
                showModal();
                const data2 = {
                    name:{
                        ru:res.data.name.ru,
                        uz:res.data.name.uz
                    }
                }
                console.log(data2)
                // res.data.forEach((item) => {
                //   data2[item.language] = { name: item.name };
                // });
                // data2["service"] = res.data[0].standardServiceIdList;
                formSpeciality.setFieldsValue(data2);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSpeciality = () => {
        selectedRowKeys.forEach((item) => {
            deleteSpecialityConfig(item)
                .then((res) => {
                    getAllSpecialitiesFunc(pagination.current);
                    toastDel("Mutahasislik");
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "Rosdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSpeciality();
            },
        });
    }

    const handleTableChange = (page) => {
        getAllSpecialitiesFunc(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    useEffect(() => {
        getAllSpecialitiesFunc();
        gerServices();
    }, [getAllSpecialitiesFunc]);

    return (
        <div className="illness">
            <div>
                <div style={{ marginBottom: 16 }}>
                    <Button type="primary" onClick={showModal} disabled={hasSelected}>
                        Doktor ixtisosliklari
                    </Button>
                    {/*<Button*/}
                    {/*  className={selectedRowKeys.length > 0 ? "" : "display-none"}*/}
                    {/*  style={{ marginLeft: 16, color: "red" }}*/}
                    {/*  onClick={confirm}*/}
                    {/*>*/}
                    {/*  <DeleteOutlined />*/}
                    {/*</Button>*/}
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{ marginLeft: 16, color: "green" }}
                        onClick={editSpeciality}
                    >
                        <EditOutlined />
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{ marginLeft: 16 }}
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowKey={(o) => o.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={specialityData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>

            <Modal
                title="Muataxasislik qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={handleOk}
                onCancel={handleCancel}
                className="illness-modal"
            >
                <Form {...layout} form={formSpeciality}>
                    {lang.map((item, index) => (
                        <>
                            <h3
                                style={{ textTransform: "capitalize" }}
                                key={`${item.name}malumot`}
                            >
                                {item.name }da kiritiladigan ma'lumotlar
                            </h3>
                            <Form.Item
                                label={`Doktor ixtisosliklari ${item.name}da kiriting:`}
                                name={["name",item.url]}
                                key={`${index}speciality`}
                                rules={[{ required: true, message: "To'ldiring!" }]}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    ))}

                    {/*<Form.Item*/}
                    {/*  label={`Speciality servislarini tanlang:`}*/}
                    {/*  name="service"*/}
                    {/*  key="service"*/}
                    {/*  rules={[{ required: true, message: "To'ldiring!" }]}*/}
                    {/*>*/}
                    {/*  /!*<Select mode="multiple" allowClear style={{ width: "100%" }}>*!/*/}
                    {/*  /!*  {serviceData.map((item, index) => (*!/*/}
                    {/*  /!*    <Option value={item.standardServiceId} key={index}>*!/*/}
                    {/*  /!*      {item.name}*!/*/}
                    {/*  /!*    </Option>*!/*/}
                    {/*  /!*  ))}*!/*/}
                    {/*  /!*</Select>*!/*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        </div>
    );
};

export default DoctorSpecializations;
