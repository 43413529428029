import React, {useCallback, useEffect, useState} from "react";
import "./MedQuestionCategory.css";
import {Form, Switch, Tabs, Upload} from "antd";
import {
    PlusOutlined,
    DeleteFilled,
    EditOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import {Button, Table, Modal, Input, Select} from "antd";
import {
    deleteCategory,
    editCategory,
    getAllCategories,
    getCategory,
    sendCategory,
} from "../../server/config/Category";
import {
    deleteSubCategory,
    editSubCategory,
    getAllSubcategories,
    getSubCategory,
    sendSubCategory,
} from "../../server/config/SubcategoryCrud";
import {
    deleteServices,
    getAllServices,
    getService,
    sendServices,
    editService,
} from "../../server/config/ServiceCrud";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    CreateType,
    DeleteType,
    EditType,
    GetTypesById,
    GetTypesByServiceId,
} from "../../server/config/TypeCrud";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";
import {toast} from "react-toastify";
import {downloadServices} from "../../server/config/ContentCrud";
import {
    EditMedAnswer,
    editMedCategory, editUserQuestionMed, getAllMed_Answer, getAllMed_Q, getAllMed_Q_ITEM,
    getAllMedCategories, getMed_Answer_Detail,
    getMedCategory, sendMedAnswer,
    sendMedCategory, sendUserQuestionMed
} from "../../server/config/MedQuestionCategory";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const MedQuestionCategory = () => {
    const {TabPane} = Tabs;
    const {Option} = Select;
    const [formType] = Form.useForm();
    const [formCategory] = Form.useForm();
    const [formServices] = Form.useForm();
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [editCateId, setEditCate] = useState(false);
    const [modalCateVisible, setCateModal] = useState(false);
    const [activekey, setAktivkey] = useState("");
    const [formSubCategory] = Form.useForm();
    const [editSubId, setEditSub] = useState("");
    const [modalSubVisible, setSubModal] = useState(false);
    const [subcateList, setSubcate] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [editSerId, setEditSer] = useState(false);
    const [modalSerVisible, setSerModal] = useState(false);
    const [selectedSub, setSelectedsub] = useState("");
    const [cateId, setCateId] = useState("");
    const [serId, setSerId] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editTypeId, setTypeId] = useState("");
    const [typeModal, setTypeModal] = useState(false);
    const [typeSaveModal, setTypeSaveModal] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [serviceId, setServiceId] = useState("");
    const [serviceName, setServiceName] = useState("");

    const languagesGet = () => {
        getLanguages().then((res) => {
            console.log(res);
            setLanguages(res.data);
        });
    };

    //Clear Modal
    const clearModal = () => {
        const data2 = {};
        languages.forEach((item) => {
            data2[item] = {name: null};
        });
        formCategory.setFieldsValue(data2);
    };
    const clearSubModal = () => {
        const data2 = {};
        languages.forEach((item) => {
            data2[item] = {name: null};
        });
        formSubCategory.setFieldsValue(data2);
    };
    const clearSerModal = () => {
        const data2 = {};
        languages.forEach((item) => {
            data2[item] = {name: null};
        });
        formServices.setFieldsValue(data2);
    };

    //CRUD Categories
    const categoriesGet = useCallback(() => {
        getAllMedCategories()
            .then((res) => {
                setCategories(res.data);
            })
            .catch(() => {
                toastErr();
            });
    }, []);
    const openCategoryModal = () => {
        setCateModal(true);
        setEditCate(false);
        clearModal();
        // formCategory.setFieldsValue({ ru: { name: null }, uz: { name: null } });
    };
    const saveCategory = () => {
        formCategory
            .validateFields()
            .then((values) => {
                if (editCateId) {
                    // const data = Object.keys(values).map((item) => {
                    //     return {
                    //         language: item,
                    //         name: values[item].name,
                    //         categoryId: activekey,
                    //     };
                    // });
                    let data = {
                        name: {
                            ru: values.name.ru,
                            uz: values.name.uz
                        },
                        status: values.switch == true ? 1 : 0,
                    }
                    editMedCategory(data, activekey).then(() => {
                        categoriesGet();
                        toastEdit("Categoriya");
                        formCategory.setFieldsValue(null);
                    });
                } else {
                    let data = {
                        name: {
                            ru: values.name.ru,
                            uz: values.name.uz
                        },
                        status: values.switch == true ? 1 : 0,
                    }
                    sendMedCategory(data).then(() => {
                        categoriesGet();
                        toastAdd("Categoriya");

                    });
                }
                setCateModal(false);
                formCategory.setFieldsValue(null);
            })
            .catch(() => {
                toastErr();
            });
    };
    const delCategory = () => {
        Modal.confirm({
            content: "Rostan o'chirishni hohlaysimi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteCategory(activekey).then(() => {
                    setAktivkey("");
                    categoriesGet();
                    toastDel("Categoriya");
                });
            },
        });
    };
    const edCategory = () => {
        setCateModal(true);
        setEditCate(true);
        getMedCategory(activekey).then((res) => {
            console.log(res)
            let data = {
                name: {
                    ru: res.data.name.ru,
                    uz: res.data.name.uz
                },
                switch: res.data.status
            }
            // const data = res.data;
            // const new_data = {};
            // data.forEach((item) => {
            //     new_data[item.language] = {name: item.name};
            // });
            formCategory.setFieldsValue(data);
        });
    };
    const changeCategory = (name) => {
        console.log(name)
        setAktivkey(name);
        getAllMed_Q(name).then((res) => {
            console.log(res)
            setSubcate(res.data.med_questions);
        });
        setServiceList([]);
    };

    //CRUD SubCategories
    const getSubcategorybyid = (Id) => {
        console.log(Id)
        getAllMed_Q_ITEM(Id).then((res) => {
           let data={
               question:{
                   uz:res.data.question.uz,
                   ru:res.data.question.ru,
               },
               switch: res.data.status
           }
            formSubCategory.setFieldsValue(data);
        });
    };
    const saveSubcategories = () => {
        formSubCategory
            .validateFields()
            .then((values) => {

                if (editSubId) {
                    let data={
                        ...values,
                        med_question_group_id:activekey,
                        answer_type:`text`,
                        status: values.switch == true ? 1 : 0,
                    }

                    editUserQuestionMed(data,editSubId).then(() => {
                        toastEdit("Subcategoriya");
                        getAllMed_Q(activekey).then((res) => {
                            setSubcate(res.data.med_questions);
                        });
                    });
                } else {

                    let data={
                        ...values,
                        med_question_group_id:activekey,
                        status: values.switch == true ? 1 : 0,
                    }
                    sendUserQuestionMed(data).then(() => {
                        toastAdd("Subcategoriya");
                        getAllMed_Q(activekey).then((res) => {
                            setSubcate(res.data.med_questions);
                        });
                    });
                }
                setSubModal(false);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSubCategory = (Id) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysanmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteSubCategory(Id).then((res) => {
                    toastDel("Subcategoriya");
                    getAllSubcategories(activekey).then((res) => {
                        setSubcate(res.data);
                    });
                });
            },
        });
    };
    const columns2 = [
        {
            title: <h1> User Med Questions </h1>,
            render: (item) => (
                <Button
                    onClick={() => getStandartServices(item.id)}
                    type="text"
                >
                    {item.question.slice(0, 35)}
                    {item.question.length > 35 ? "..." : ""}
                </Button>
            ),
            key: "name",
        },
        {
            title: <h1
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft:'10px',
                }}
            > Type </h1>,
            render: (item) => (
                <Button
                    // onClick={() => getStandartServices(item.id)}
                    type="text"
                    style={{
                        float:'left',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                    }}
                >
                    {item.answer_type}
                </Button>
            ),
            key: "answer_type",
        },
        {
            title: (
                <Button
                    className="category-main-button"
                    onClick={() => {
                        setSubModal(true);
                        setEditSub("");
                        clearSubModal();
                    }}
                    disabled={activekey ? false : true}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                    }}
                >
                    <PlusOutlined style={{fontSize: "25px"}}/>
                </Button>
            ),
            render: (item) => (
                <React.Fragment>
                    <EditOutlined
                        className="cate-button-icon cate-icon-edit"
                        onClick={() => {
                            setEditSub(item.id);
                            setSubModal(true);
                            getSubcategorybyid(item.id);
                        }}
                    />
                </React.Fragment>
            ),
            key: "item",
        },
    ];
    //CRUD Standart Services
    const [typeanswer,setTypeAnswer]=useState([])
    const getServicebyid = (Id) => {
        console.log(Id)
        setSerId(Id);
        getMed_Answer_Detail(Id).then((res) => {
            console.log(res.data)
            let data = {
                answer: {
                    ru: res.data.answer.ru,
                    uz: res.data.answer.uz
                },
                switch: res.data.status
                // parent_id: res.data.parent_id
            }
            formServices.setFieldsValue(data);

            // console.log(subcateList,data.parent_id)
            // subcateList.forEach((item) => {
            //     if (item.id === data.parent_id) {
            //         return setSelectedsub(item.name);
            //     }
            // });
        });
    };
    const getStandartServices = (Id) => {
        setCateId(Id);
        console.log(Id)
        getAllMed_Answer(Id).then((res) => {
            setServiceList(res.data.med_question_answers);
            setTypeAnswer(res.data)
        });
    };
    const saveServices = () => {
        formServices
            .validateFields()
            .then((values) => {
                if (editSerId) {

                    let data={
                        ...values,
                        status: values.switch == true ? 1 : 0,
                        med_question_id:cateId
                    }
                    console.log(cateId)
                    EditMedAnswer(data,serId).then(() => {
                        toastEdit("Standart service");
                        getAllMed_Answer(cateId).then((res) => {
                            setServiceList(res.data.med_question_answers);
                        });
                    });
                }
                else {
                    console.log(values)
                    let data={
                        ...values,
                        status: values.switch == true ? 1 : 0,
                        med_question_id:cateId
                    }
                    console.log(cateId)
                    sendMedAnswer(data).then(() => {
                        toastAdd("Standart service");
                        getAllMed_Answer(cateId).then((res) => {
                            setServiceList(res.data.med_question_answers);
                        });
                    });
                }
            })
            .catch(() => {
                toastErr();
            });
        setSerModal(false);
        setSelectedsub("");
    };
    const delService = (Id) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysanmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteServices(Id).then(() => {
                    toastDel("Standart service");
                    getAllServices(cateId).then((res) => {
                        setServiceList(res.data);
                    });
                });
            },
        });
    };
    const columns = [
        {

            title: <h1>User Med Question Answers</h1>,
            render: (item) => (
                <span>
          {item.answer.slice(0, 35)}
                    {item.answer.length > 35 ? "..." : ""}
        </span>
            ),
            key: "name",
        },
        {
            title: (
                <Button
                    className="category-main-button"
                    onClick={() => {
                        setSerModal(true);
                        setEditSer(false);
                        clearSerModal();
                    }}
                    disabled={activekey ? false : true}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                    }}
                >
                    <PlusOutlined style={{fontSize: "25px"}}/>
                </Button>
            ),
            render: (item) => (
                <React.Fragment>
                    {/*<DeleteFilled*/}
                    {/*    className="cate-button-icon cate-icon-delete"*/}
                    {/*    onClick={() => {*/}
                    {/*        delService(item.standardServiceId);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <EditOutlined
                        className="cate-button-icon cate-icon-edit"
                        onClick={() => {
                            setEditSer(true);
                            setSerModal(true);
                            getServicebyid(item.id);
                        }}
                    />
                </React.Fragment>
            ),
            key: "item",
        },
    ];

    //Crud service types
    const getTypes = (id) => {
        console.log(id)
        setServiceId(id);
        GetTypesByServiceId(id)
            .then((res) => {
                // console.log(res.data)
                let dataWithkey = res.data.map((item) => {
                    return {...item, key: item.id};
                });
                console.log(dataWithkey)
                setTypeList(dataWithkey);
            })
            .catch(() => {
                toastErr();
            });
    };
    const saveTypes = () => {
        formType
            .validateFields()
            .then((values) => {
                if (editTypeId) {
                    let data={
                        ...values,
                        type:`type`,
                        status:1,
                        parent_id:serviceId
                    }
                    EditType(data,editTypeId).then(() => {
                        getTypes(serviceId);
                        toastEdit("Service tipi");
                    });
                } else {
                    // console.log(values)
                    // const data = Object.keys(values).map((item) => {
                    //     return {
                    //         language: item,
                    //         name: values[item].name,
                    //         standardServiceId: serviceId,
                    //     };
                    // });
                    let data={
                        ...values,
                        type:`type`,
                        status:1,
                        parent_id:serviceId
                    }
                    CreateType(data).then(() => {
                        toastAdd("Service tipi");
                        getTypes(serviceId);
                    });
                }
                setTypeSaveModal(false);
            })
            .catch(() => {
                toastErr();
            });
    };
    const editTypeFunc = () => {
        let id = selectedRowKeys[0]
        setTypeId(id)
        setTypeSaveModal(true);
        GetTypesById(id)
            .then((res) => {
                let keyed={
                    name:{
                        ru:res.data.name.ru,
                        uz:res.data.name.uz
                    },

                }
                formType.setFieldsValue(keyed);
            })
            .catch(() => {
                toastErr();
            });
    };
    const deleteTypeFunc = () => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysanmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                DeleteType(editTypeId).then((res) => {
                    getTypes(serviceId);
                    toastDel("Service tipi");
                });
            },
        });
    };
    const resetTypeFunc = () => {
        setSelectedRowKeys([]);
        setTypeId("");
    };
    const columnsType = [
        {
            title: <h1>Type nomi</h1>,
            dataIndex: "name",
            key: "name",
        },
    ];

    const hasSelected = selectedRowKeys.length > 0;
    const onSelectChange = (value) => {
        console.log('value')
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const showTypeModal = () => {
        setTypeSaveModal(true);
        formType.resetFields();
    };

    useEffect(() => {
        categoriesGet();
        languagesGet();
    }, [categoriesGet]);

    return (
        <div className="category-section">
            <div className="main-categories">
                <Tabs activeKey={activekey} onChange={changeCategory} type="card">
                    {categories.map((item) => {
                        return <TabPane tab={item.name} key={item.id}></TabPane>;
                    })}
                </Tabs>
                <Button
                    className="category-main-plus"
                    onClick={openCategoryModal}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                    }}
                >
                    <PlusOutlined style={{fontSize: "25px"}}/>
                </Button>
                <Button
                    disabled={activekey ? false : true}
                    className="category-main-edit"
                    onClick={edCategory}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "40px",
                    }}
                >
                    <EditOutlined style={{fontSize: "25px"}}/>
                </Button>
            </div>
            <div className="main-categories-content">
                <div className="categories-subcategory">
                    <Table
                        columns={columns2}
                        dataSource={subcateList}
                        style={{width: "100%"}}
                        rowKey={(obj) => obj.id}
                        pagination={{hideOnSinglePage: true}}
                    />
                </div>
                {
                    typeanswer.answer_type == 'select' ?  <div className="categories-service">
                        <Table
                            columns={columns}
                            dataSource={serviceList}
                            rowKey={(obj) => obj.id}
                            pagination={{hideOnSinglePage: true}}
                        />
                    </div> : ''
                }

            </div>

            {/* Categoriya Modal */}
            <Modal
                width={1000}
                title={
                    editCateId
                        ? "Categoriya Ma'lumotlarini tahrirlash"
                        : "Categoriya Ma'lumotlarini Kiriting"
                }
                centered
                visible={modalCateVisible}
                okText={editCateId ? "O'zgarishlarni Saqlash" : "Saqlash"}
                cancelText="Bekor qilish"
                onOk={saveCategory}
                onCancel={() => {
                    setCateModal(false);
                }}
            >
                <Form {...layout} form={formCategory}>
                    {languages
                        ? languages.map((item) => {
                            return (
                                <Form.Item
                                    name={["name", item.url,]}
                                    key={[item, "name"]}
                                    label={`Categoriyaning Nomini Kiriting (${item.name})`}
                                    rules={[{required: true, message: "To'ldirilmagan"}]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={`Categoriya ${item.name} nomi...`}
                                    />
                                </Form.Item>
                            );
                        })
                        : null}
                    <Form.Item label="Switch" valuePropName="checked" key='switch' name='switch'>
                        <Switch/>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Subcategoriya Modal */}
            <Modal
                width={1000}
                title={
                    editSubId
                        ? "Subcategoriya Ma'lumotlarini tahrirlash"
                        : "Subcategoriya Ma'lumotlarini Kiriting"
                }
                centered
                visible={modalSubVisible}
                onOk={() => {
                    setSubModal(false);
                    saveSubcategories();
                }}
                onCancel={() => {
                    setSubModal(false);
                    setCateId("");
                }}
            >
                <Form {...layout} form={formSubCategory}>
                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <Form.Item
                                    name={["question",item.url ]}
                                    key={index}
                                    label={`Subkategoriyaning Nomini Kiriting (${item.name})`}
                                    rules={[{required: true, message: "To'ldirilmagan"}]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={`Subcategoriya ${item.name} nomi...`}
                                    />
                                </Form.Item>
                            );
                        })

                        : null}
                    <Form.Item
                        name="answer_type"
                        label="Answer Type"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Question Type"
                            allowClear
                        >
                            <Option value="text">text</Option>
                            <Option value="select">select</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Switch" valuePropName="checked" key='switch' name='switch'>
                      <Switch/>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Standart Service Modal */}
            <Modal
                width={1000}
                title={
                    editSerId
                        ? "Standart Servis Ma'lumotlarini tahrirlash"
                        : "Standart Servis Ma'lumotlarini Kiriting"
                }
                centered
                visible={modalSerVisible}
                onOk={() => {
                    setSerModal(false);
                    saveServices();
                }}
                onCancel={() => {
                    setSerModal(false);
                    setSerId("");
                }}
            >

                <Form {...layout} form={formServices}>
                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <Form.Item
                                    name={["answer",item.url]}
                                    key={index}
                                    label={`Standart Servis Nomini Kiriting (${item.name})`}
                                    rules={[{required: true, message: "To'ldirilmagan"}]}
                                >
                                    <Input
                                        size="large"
                                        placeholder={`Standart Servis ${item.name} nomi...`}
                                    />
                                </Form.Item>
                            );
                        })
                        : ""}
                    <Form.Item label="Switch" valuePropName="checked" key='switch' name='switch'>
                      <Switch/>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Service Type Modal */}
            <Modal
                width={1000}
                title={`${serviceName} standart service tiplari`}
                centered
                visible={typeModal}
                onOk={() => {
                    setTypeModal(false);
                }}
                onCancel={() => {
                    setTypeModal(false);
                    setSelectedRowKeys([]);
                    setTypeId("");
                }}
            >
                <Table
                    rowSelection={rowSelection}
                    columns={columnsType}
                    dataSource={typeList}
                    pagination={{hideOnSinglePage: true}}
                    bordered
                    title={() => (
                        <div>
                            <Button
                                onClick={() => {
                                    showTypeModal();
                                }}
                                disabled={editTypeId ? true : false}
                                type="primary"
                            >
                                Standart servicega tip qo'shish
                            </Button>
                            <Button
                                className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                style={{marginLeft: 16, color: "red"}}
                                onClick={deleteTypeFunc}
                            >
                                <DeleteOutlined/>
                            </Button>
                            <Button
                                className={selectedRowKeys.length === 1 ? "" : "display-none"}
                                style={{marginLeft: 16, color: "green"}}
                                onClick={editTypeFunc}
                            >
                                <EditOutlined/>
                            </Button>
                            <Button
                                style={{marginLeft: "20px"}}
                                onClick={resetTypeFunc}
                                disabled={editTypeId ? false : true}
                                type="primary"
                            >
                                Yangilash
                            </Button>
                        </div>
                    )}
                />
            </Modal>

            {/* Service type create modal */}
            <Modal
                width={800}
                title={
                    editTypeId
                        ? "Standart service tipini ma'lumotlarini tahrirlash"
                        : "Yangi standart service tipini yaratish"
                }
                centered
                visible={typeSaveModal}
                onOk={() => {
                    setTypeSaveModal(false);
                    saveTypes();
                }}
                onCancel={() => {
                    setTypeSaveModal(false);
                    setTypeId("");
                }}
            >
                <Form {...layout} form={formType}>
                    {languages
                        ? languages.map((item) => {
                            return (
                                <Form.Item
                                    name={[ "name",item.url]}
                                    key={[item, "name"]}
                                    label={`Tip Nomini Kiriting (${item.name})`}
                                    rules={[{required: true, message: "To'ldirilmagan"}]}
                                >
                                    <Input size="large" placeholder={`Tip ${item.name} nomi...`}/>
                                </Form.Item>
                            );
                        })
                        : null}
                </Form>
            </Modal>
        </div>
    );
};

export default MedQuestionCategory;
