import axios from "axios";
import { API_LOGIN } from "../../constants";

// const headers = {
//   'x-csrf-token': document.querySelector("[name='csrf-token']").getAttribute('content')
// }
export const sendUser = (data) => {
  return axios.post(API_LOGIN + "login?lang=en", data);
};


