import React from "react";
import { sendUser } from "../server/config/User";
import { setCookie } from "../functions/useCookies";
import { TOKEN } from "../constants";
import "./loginpage.css";
import {
  Form,
  Input,
  Button,
  message,
  Avatar,
  Col,
  Row,
  Typography,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const { Title } = Typography;
const Login = () => {
  const onFinish = (values) => {
    sendUser(values)
      .then((res) => {
          console.log(res);
        setCookie(TOKEN, res.data.token);
        window.location.href = "/dashboard";
      })
      .catch(() => {
        message.error("Login yoki parol xato");
      });
  };

  return (
    <div className="essential_container_login_page">
      <Row
        key="login_row"
        justify="center"
        align="middle"
        className="essential_row"
      >
        <div className="login_avatar_div">
          <Avatar size={75} className="login_avatar" icon={<UserOutlined />} />
        </div>
        <Col xs={20} sm={12} md={16} lg={16}>
          <Title level={2} className="text-center login_title">
            Saytga kirish
          </Title>

          <Form name="basic" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Iltimos loginni kiriting",
                },
              ]}
              key="username"
            >
              <Input
                autoFocus
                prefix={
                  <UserOutlined
                    className="site-form-item-icon"
                    id="usericon_login"
                  />
                }
                placeholder="Login"
                className="login_input"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Iltimos parolni kiriting",
                },
              ]}
              key="password"
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    className="site-form-item-icon"
                    id="lockicon_login"
                  />
                }
                placeholder="Parol"
                className="login_input"
              />
            </Form.Item>
            <Form.Item key="sumbitbtn">
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  borderRadius: "11px",
                  padding: "18px 22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "800",
                  boxShadow: "2px 2px 10px rgb(120,100,100)",
                  backgroundColor: "#4998af",
                  width: "80%",
                  margin: "0 auto",
                }}
              >
                Kirish
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
