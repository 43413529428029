import { getCookie } from "../functions/useCookies";
import { axiosInstance, host } from "./host";
import axios from "axios";
import {API_URL, TOKEN} from "../constants";
import {toast} from "react-toastify";

export const HttpRequestHub = (config = null) => {
  const token = getCookie(TOKEN);
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: token ? `Bearer ${token}` : "",
  };
  let axiosInstance = axios.create({
    baseURL: `${API_URL}/`,
    headers,
    timeout: 100000,
  });

  // axiosInstance.interceptors.response.use(
  //     (response) => response,
  //     (error) => {
  //       console.log(error.response.data)
  //       if (error.response && error.response.data) {
  //         error.response.data.errors &&
  //         toast.error(
  //             Object.values(error.response.data.errors)
  //                 .map((item) => item[0])
  //                 .join(", ")
  //         );
  //       }
  //       throw error;
  //     }
  // );
  return axiosInstance(config);
};


export const HttpRequestHubFile = (config = null) => {
  let token = getCookie(TOKEN);
  let fileHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-type": "multipart/form-data",
    Authorization: token ? `Bearer ${token}` : "",
  };
  let axiosInstanceFile = axios.create({
    baseURL: host,
    headers: fileHeaders,
    timeout: 100000,
  });
    axiosInstanceFile.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.data) {
            error.response.data.errors &&
            toast.error(
                Object.values(error.response.data.errors)
                    .map((item) => item[0])
                    .join(", ")
            );
          }
          throw error;
        }
    );
  return axiosInstanceFile(config);
};
