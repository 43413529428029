import { HttpRequestHub } from "../HttpRequestHub";

export const getAllSubcategories = (id) => {
    console.log(id)
    const config = {
        method: "GET",
        url: `service-category/get-list?category_id=${id}`,
    };
    return HttpRequestHub(config);
};

export const sendSubCategory = (data) => {
    const config = {
        method: "POST",
        url: "service-category/store",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteSubCategory = (id) => {
    const config = {
        method: "DELETE",
        url: "sub-category/delete/" + id,
    };
    return HttpRequestHub(config);
};

export const getSubCategory = (id) => {
    const config = {
        method: "GET",
        url: `service-category/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editSubCategory = (data,id) => {
    const config = {
        method: "POST",
        url: `service-category/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};