import { HttpRequestHub } from "../HttpRequestHub";

export const GetTypesByServiceId = (id) => {
    const config = {
        method: "GET",
        url: `service-category/get-list?category_id=${id}`
    };
    return HttpRequestHub(config);
};

export const GetTypesById = (id) => {
    const config = {
        method: "GET",
        url: `service-category/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const CreateType = (data) => {
    const config = {
        method: "POST",
        url: "service-category/store",
        data
    };
    return HttpRequestHub(config);
};

export const EditType = (data,id) => {
    const config = {
        method: "POST",
        url: `service-category/update/${id}`,
        data
    };
    return HttpRequestHub(config);
};

export const DeleteType = (id) => {
    const config = {
        method: "DELETE",
        url: "service-packet-type/delete/" + id, 
    };
    return HttpRequestHub(config);
};
