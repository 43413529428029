import { HttpRequestHub } from "../HttpRequestHub";

export const getAllSicknessCategories = () => {
  const config = {
    method: "GET",
    url: `sickness-categories/get-list`,
  };
  return HttpRequestHub(config);
};

export const sendSicknessCategory = (data) => {
  const config = {
    method: "POST",
    url: "sickness-categories/store",
    data,
  };
  return HttpRequestHub(config);
};

export const deleteSicknessCategory = (id) => {
  const config = {
    method: "DELETE",
    url: "sickness-category/delete/" + id,
  };
  return HttpRequestHub(config);
};

export const editSicknessCategoryConfig = (id, data) => {
  const config = {
    method: "POST",
    url: "sickness-categories/update/" + id,
    data,
  };
  return HttpRequestHub(config);
};

export const getSicknessCategoryByIdConfig = (id) => {
  const config = {
    method: "GET",
    url: "sickness-categories/show/" + id,
  };
  return HttpRequestHub(config);
};
