import {HttpRequestHub} from "../HttpRequestHub";
import {post} from "axios";
import {host} from "../host";
import {getCookie} from "../../functions/useCookies";
import {TOKEN} from "../../constants";

export const getAllNewsConfig = (page = 1, size = '10') => {
    const config = {
        method: "GET",
        url: `get-article-list?page=${page - 1}&size=${size}`,
    };
    return HttpRequestHub(config);
};
const token = getCookie(TOKEN);
export const sendNewsConfig = (data, file) => {
    let formData = new FormData();
    formData.append('image', file)

    Object.keys(data).map((item) => {
        if (typeof data[item] === "object") {
            Object.keys(data[item]).forEach(value => {
                formData.append(`${item}[${value}]`, data[item][value])
            })
        } else {
            formData.append(item, data[item])
        }
    });

    const config = {
        method: "POST",
        url: "create-article",
        data: formData

    };
    return HttpRequestHub(config);


};

export const deleteNewsConfig = (id) => {
    const config = {
        method: "POST",
        url: `destroy-article/${id}`,
    };
    return HttpRequestHub(config);
};

export const editNewsConfig = (data, file, id,saveimg) => {
    console.log(data, file, id)
    if(file){
        let formData = new FormData();
        formData.append('image', file)

        Object.keys(data).map((item) => {
            if (typeof data[item] === "object") {
                Object.keys(data[item]).forEach(value => {
                    formData.append(`${item}[${value}]`, data[item][value])
                })
            } else {
                formData.append(item, data[item])
            }
        });

        const config = {
            method: "POST",
            url: `update-article/${id}`,
            data: formData
        };
        return HttpRequestHub(config);
    }else {
        let edit_date={
            ...data,
            image:saveimg
        }
        const config = {
            method: "POST",
            url: `update-article/${id}`,
            data: edit_date
        };
        return HttpRequestHub(config);
    }

};

export const getNewsByIdConfig = (id) => {
    const config = {
        method: "GET",
        url: `get-article/${id}`,
    };
    return HttpRequestHub(config);
};
