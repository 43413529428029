import React, { useEffect, useState } from "react";
import "./illness/Illness.css";
import {Table, Button, Modal, Form, Input, Select, Switch} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  deleteSicknessCategory,
  editSicknessCategoryConfig,
  getAllSicknessCategories,
  getSicknessCategoryByIdConfig,
  sendSicknessCategory,
} from "../server/config/SicknessCategoryConfig";
import { getSicknessByCategoryConfig } from "../server/config/SicknessConfig";
import {
  toastAdd,
  toastDel,
  toastEdit,
  toastErr,
} from "../Notifications/toast";
import {getLanguages} from "../server/config/GetLenguage";
const SicknessCategory = () => {
  const { Option } = Select;
  const [formSymptomCategory] = Form.useForm();
  const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const [symptomCategoryList, setSymptomCategoryList] = useState([]);
  const [editObject, setEditObject] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sicknessList, setSicknessList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
    hideOnSinglePage: true,
  });

  const [languages, setLanguages] = useState([]);
  const [switchStatus, setSwitchStatus] = useState(1)
  //   table symtopmcategory
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Kassallik kategoriyasi nomi",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Kategoriyaga biriktirilgan kassalliklar",
      render: (category) => (
        <Select
          defaultValue="Kassalliklarni ko'rish"
          style={{ width: 200 }}
          // onClick={() => getSicknesses(category.id)}
        >
          {category.sicknesses.length > 0
            ? category.sicknesses.map((item, index) => {
                return (
                  <Option
                    key={`${index}option`}
                    value="disabled"
                    style={{ pointerEvents: "none" }}
                  >
                    {item.name}
                  </Option>
                );
              })
            : ""}
        </Select>
      ),
      key: "specialityId",
    },
  ];
  const start = () => {
    setTimeout(() => {
      setSelectedRowKeys([]);
    }, 1000);
  };

  const onSwitchChange = (checked) => {
    if (checked){
      setSwitchStatus(1)
    }
    else {
      setSwitchStatus(0)
    }
  }
  const onSelectChange = (value) => {
    setSelectedRowKeys(value);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //   modal add symtop category
  const showModal = () => {
    setIsModalVisible(true);
    formSymptomCategory.resetFields()
    setSwitchStatus(1)
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditObject([]);
  };

  //Sickness category Crud
  const getSicknesses = (id) => {
    getSicknessCategoryByIdConfig(id)
      .then((res) => {
        setSicknessList(res.data.sicknesses);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const getAllSicknessCategoriesFunction = (page) => {
    setLoading(true);
    getAllSicknessCategories(page)
      .then((res) => {
        console.log(res.data)
        setLoading(false);
        const Data = res.data;
        const data = Data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setSymptomCategoryList(data);
        setPagination((prev) => ({
          ...prev,
          total: Data.totalElements,
        }));
      })
      .catch((err) => {
        toastErr();
        setLoading(false);
      });
  };
  const editSymptomCategory = () => {
    const id = selectedRowKeys[0];
    getSicknessCategoryByIdConfig(id)
      .then((res) => {
        setEditObject(res.data);
        showModal();
        formSymptomCategory.setFieldsValue(res.data);
        setSwitchStatus(res.data.status)
      })
      .catch((err) => {
        toastErr();
      });
  };
  const saveSicknessCategory = () => {
    if (editObject.id) {
      const id = editObject.id;
      formSymptomCategory.validateFields().then((values) => {
        let newData = new FormData()
        Object.keys(values).map((item) => {
          if (typeof values[item] === "object") {
            Object.keys(values[item]).forEach(value => {
              newData.append(`${item}[${value}]`, values[item][value])
            })
          } else {
            newData.append(item, values[item])
          }
        });
        newData.delete('status')
        newData.append('status', switchStatus)
        editSicknessCategoryConfig(id, newData)
          .then((res) => {
            getAllSicknessCategoriesFunction(pagination.current);
            setSelectedRowKeys([]);
            toastEdit("Kassallik kategoriyasi");
            handleCancel();
          })
          .catch((err) => {
            toastErr();
          });
      });
    } else {
      formSymptomCategory.validateFields().then((values) => {
        let newData = new FormData()
        Object.keys(values).map((item) => {
          if (typeof values[item] === "object") {
            Object.keys(values[item]).forEach(value => {
              newData.append(`${item}[${value}]`, values[item][value])
            })
          } else {
            newData.append(item, values[item])
          }
        });
        newData.delete('status')
        newData.append('status', switchStatus)
        sendSicknessCategory(newData)
          .then((res) => {
            getAllSicknessCategoriesFunction(pagination.current);
            toastAdd("kassallik kategoriyasi");
            setSwitchStatus(1)
            handleCancel();
          })
          .catch((err) => {
            toastErr();

          });
      });
    }
  };
  const delSicknessCategory = () => {
    selectedRowKeys.forEach((item) => {
      deleteSicknessCategory(item)
        .then((res) => {
          toastDel("Kassallik kategoriyasi");
          getAllSicknessCategoriesFunction(
            symptomCategoryList.length > 1
              ? pagination.current
              : pagination.current - 1
          );
        })
        .catch((err) => {
          toastErr();
        });
    });
    setSelectedRowKeys([]);
  };
  function confirm() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Rostdan ham o'chirishni xohlaysizmi?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk() {
        delSicknessCategory();
      },
    });
  }

  const handleTableChange = (page) => {
    getAllSicknessCategoriesFunction(page.current);
    setSelectedRowKeys([]);
    setPagination((prev) => ({
      ...prev,
      current: page.current,
    }));
  };

  useEffect(() => {
    getAllSicknessCategoriesFunction();
    getLanguages().then((res) => {
      setLanguages(res.data);
    });
  }, []);

  return (
    <div className="illness">
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button disabled={hasSelected} type="primary" onClick={showModal}>
            Kassallik Kategoriyasini Qo'shish
          </Button>
          <Button
            className={selectedRowKeys.length > 0 ? "" : "display-none"}
            style={{ marginLeft: 16, color: "red" }}
            onClick={confirm}
          >
            <DeleteOutlined />
          </Button>
          <Button
            className={selectedRowKeys.length === 1 ? "" : "display-none"}
            style={{ marginLeft: 16, color: "green" }}
            onClick={editSymptomCategory}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            onClick={start}
            disabled={!hasSelected}
            style={{ marginLeft: 16 }}
          >
            Yangilash
          </Button>
        </div>
        <Table
          rowKey={(p) => p.id}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={symptomCategoryList}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>

      <Modal
        title={
          selectedRowKeys.length > 0
            ? "Kasallik kategoriyasi ma'lumotlarini tahrirlash"
            : "Kasallik kategoriyasi qo'shish"
        }
        visible={isModalVisible}
        centered
        width="70%"
        okText="Saqlash"
        cancelText="Bekor qilish"
        onOk={saveSicknessCategory}
        onCancel={handleCancel}
        className="illness-modal z-modal"
      >
        <Form {...layout} form={formSymptomCategory}>
          {languages.map((item, index) => (
            <Form.Item
              label={`Sickness kategoriyani ${item.name} da kiriting`}
              name={["name", item.url]}
              rules={[{ required: true, message: "Toldirilmagan" }]}
              key={`${index}sicknesscategory`}
            >
              <Input />
            </Form.Item>
          ))}
          <Form.Item
              label={`Sickness category status`}
              name={'status'}
          >
            <Switch
                onChange={onSwitchChange}
                checked={switchStatus}
                />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SicknessCategory;
