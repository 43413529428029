import React, { useEffect, useState } from "react";
import { getLanguages } from "../../server/config/GetLenguage";
import {Button, Modal, Table, Form, Input, Switch} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  editStorage,
  getStorage,
  getStorageById,
  sendStorage,
} from "../../server/config/StorageCrud";
import { toastAdd, toastEdit, toastErr } from "../../Notifications/toast";

function Storage() {
  const [languages, setLanguages] = useState([]);
  const [formStorage] = Form.useForm();
  const { TextArea } = Input;
  const [isModal, setModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [storageList, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
    languageGet();
    getStorageProfile();
  }, []);

  const languageGet = () => {
    getLanguages()
      .then((res) => {
        console.log(res.data)
        setLanguages(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };

  //Table functions
  const onSelectChange = (selectedRowKeys) => {
    setEditId(selectedRowKeys[0]);
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "MaxWriteBytes",
      dataIndex: "max_write_bytes",
    },
    {
      title: "MaxTotalWriteBytes",
      dataIndex: "max_total_write_bytes",
    },
    {
      title: "MaxDownloadBytes",
      dataIndex: "max_download_bytes",
    },
  ];

  //Modal functions
  const showModal = () => {
    setModal(true);
    clearModal();
  };
  const clearModal = () => {
    formStorage.resetFields()
  };
  const resetSearch = () => {
    setEditId("");
    setList(storageList);
    setSelectedRowKeys([]);
  };

  //Crud storage
  const getStorageProfile = () => {
    getStorage()
      .then((res) => {
        let keyed = res.data.map((item) => {
          return { ...item, key: item.id };
        });
        setList(keyed);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const getStorageProfileById = () => {
    setModal(true);
    getStorageById(editId)
      .then((res) => {
        formStorage.setFieldsValue(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const saveStorage = () => {
    formStorage
      .validateFields()
      .then((values) => {
        if (editId) {
          let newdata = new FormData()
          Object.keys(values).map((item) => {
            if (typeof values[item] === "object") {
              Object.keys(values[item]).forEach(value => {
                newdata.append(`${item}[${value}]`, values[item][value])
              })
            } else {
              newdata.append(item, values[item])
            }
          });
          if (values.status === true){
            newdata.append('status', 1)
          }
          else {
            newdata.append('status', 0)
          }
          if (values.default === true){
            newdata.append('default', 1)
          }
          else {
            newdata.append('default', 0)
          }
          editStorage(newdata, editId)
            .then((res) => {
              getStorageProfile();
              toastEdit("Storage shabloni");
              setModal(false);
            })
            .catch((err) => {
              toastErr();
            });
        } else {
          let newdata = new FormData()
          Object.keys(values).map((item) => {
            if (typeof values[item] === "object") {
              Object.keys(values[item]).forEach(value => {
                newdata.append(`${item}[${value}]`, values[item][value])
              })
            } else {
              newdata.append(item, values[item])
            }
          });
          if (values.status === true){
            newdata.append('status', 1)
          }
          else {
            newdata.append('status', 0)
          }
          if (values.default === true){
            newdata.append('default', 1)
          }
          else {
            newdata.append('default', 0)
          }
          sendStorage(newdata)
            .then((res) => {
              getStorageProfile();
              toastAdd("storage shabloni");
              setModal(false);
            })
            .catch((err) => {
              toastErr();
            });
        }
      })
      .catch((err) => {
        toastErr();
      });


  };

  return (
    <div>
      <Table
        rowSelection={{ type: "radio", ...rowSelection }}
        columns={columns}
        dataSource={storageList}
        rowKey={(p) => p.id}
        bordered
        pagination={{ hideOnSinglePage: true }}
        title={() => (
          <div>
            <Button
              key="crateButton"
              onClick={showModal}
              disabled={editId ? true : false}
              type="primary"
            >
              Storage Profile shablonini yaratish
            </Button>
            <Button
              key="editButton"
              className={selectedRowKeys.length === 1 ? "" : "display-none"}
              style={{ marginLeft: 16, color: "green" }}
              onClick={getStorageProfileById}
            >
              <EditOutlined />
            </Button>
            <Button
              key="resetButton"
              style={{ marginLeft: "20px" }}
              onClick={resetSearch}
              disabled={editId ? false : true}
              type="primary"
            >
              {" "}
              Reset{" "}
            </Button>
          </div>
        )}
      />

      <Modal
        title={
          editId
            ? "Storage Profile ma'lumotlarini tahrirlang"
            : "Storage Profile ma'lumotlarini kiriting"
        }
        visible={isModal}
        centered
        width={1000}
        onOk={saveStorage}
        onCancel={() => {
          setModal(false);
          clearModal();
        }}
        className="shablon-modal"
      >
        <Form {...layout} form={formStorage}>
          {languages.map((item, index) => (
            <>
              <div
                key={index}
                style={{
                  border: "1px solid grey",
                  borderRadius: "20px",
                  width: "230px",
                  textAlign: "center",
                }}
              >
                <h4 style={{ display: "inline-block" }}>{item.name}</h4> da
                kiritiladigan ma'lumotlar
              </div>

              <Form.Item
                label={`Storage Profile ${item.url} nomini kiriting`}
                name={["name", item.url]}
                key={`${index}title`}
                rules={[
                  { required: true, message: "Please input form title!" },
                ]}
              >
                <Input placeholder={`Storage ${item.url} nomi`} />
              </Form.Item>

              <Form.Item
                name={["description", item.url]}
                key={`${index}description`}
                label={`Storage Profile haqidagi ma'lumotlarini kiriting (${item.url})`}
                rules={[{ required: true, message: "To'ldirilmagan" }]}
              >
                <TextArea
                  rows={4}
                  size="large"
                  placeholder={`Storage Profile ${item.url} ma'lumotlari`}
                />
              </Form.Item>
            </>
          ))}

          <div
            key="dsfdfsdfsd"
            style={{
              border: "1px solid grey",
              borderRadius: "20px",
              width: "230px",
              textAlign: "center",
            }}
          >
            {" "}
            Umumiy kiritiladigan ma'lumotlar
          </div>

          <Form.Item
            name="max_write_bytes"
            key="maxWriteBytes"
            label="Maximal joylanadigan ma'lumotlar sig'imini kiriting"
            rules={[{ required: true, message: "To'ldirilmagan" }]}
            type="number"
          >
            <Input type="number" size="large" placeholder="Maximal sig'im" />
          </Form.Item>

          <Form.Item
            name="max_total_write_bytes"
            key="maxTotalWriteBytes"
            label="Umumiy joylanishi mumkin bo'lgan ma'lumotlar sig'imini kiriting"
            rules={[{ required: true, message: "To'ldirilmagan" }]}
            type="number"
          >
            <Input type="number" size="large" placeholder="Umumiy sig'im" />
          </Form.Item>

          <Form.Item
            name="max_download_bytes"
            key="maxDownloadBytes"
            label="Maxsimal yuklab olishlar sonini kiriting"
            rules={[{ required: true, message: "To'ldirilmagan" }]}
            type="number"
          >
            <Input
              type="number"
              size="large"
              placeholder="Maxsimal yuklab olishlar soni"
            />
          </Form.Item>
          <Form.Item label="Status" valuePropName="checked" key='status' name='status'>
            <Switch/>
          </Form.Item>
          <Form.Item label="Default" valuePropName="checked" key='default' name='default'>
            <Switch/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Storage;
