import React, {useEffect, useState} from "react";
import "../Contact/Contact.css";
import {Tabs, List, Avatar, Button, Collapse, Modal, Form} from "antd";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {DeleteFilled} from "@ant-design/icons";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    deleteContact,
    deleteContactRequest,
    editContact,
    getContact,
    getContactRequest,
    sendContact,
    getContactMap,
    EditContactMap
} from "../../server/config/ContactCrud";
import {toastAdd, toastDel, toastEdit, toastErr} from "../../Notifications/toast";
import {
    FullscreenControl,
    GeolocationControl,
    Map,
    Placemark,
    SearchControl,
    YMaps,
    ZoomControl
} from "react-yandex-maps";

const ContactActual = () => {
    const {TabPane} = Tabs;
    const {Panel} = Collapse;
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const [languages, setLanguages] = useState([]);
    const [requestList, setRequest] = useState([]);
    const [formContact] = Form.useForm();
    const [ckData, setCkData] = useState([]);
    const [contactId, setContactId] = useState("");
    const [contactmap, setContactMap] = useState([])

    useEffect(() => {
        getContactRequestAll();
        getContactAll();
        getLanguages().then((res) => {
            setLanguages(res.data);
        });
        getContactMap()
            .then((resp) => {
                setContactMap(`${resp.data.location_x, resp.data.location_y}`)
                console.log(contactmap)
            })
            .catch((error) => {
                toastErr()
            })
    }, []);

    //Contact Request CRUD


    const editContactMap = (value) => {
        let location_x_y = {
            'location_x': value[0],
            'location_y': value[1],
        }
        EditContactMap(location_x_y)
            .then((resp) => {
                getContactMap()
                    .then((resp) => {
                        setContactMap(`${resp.data.location_x, resp.data.location_y}`)
                        console.log(contactmap)
                    })
                    .catch((error) => {
                        toastErr()
                    })
                toastEdit()
            })
            .catch((error) => {
                toastErr()
            })
    }

    const getContactRequestAll = () => {
        getContactRequest()
            .then((res) => {
                console.log(res)
                setRequest(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delContactRequest = (id) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteContactRequest(id)
                    .then((res) => {
                        getContactRequestAll();
                        toastDel("Kontakt so'rovlari");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    //Contact Info
    const ckChange = (event, editor, item, id) => {
        console.log(event, editor, item, id)
        const data = editor.getData();
        let ckObject = {...ckData};
        ckObject[item] = data;
        setCkData(ckObject);
        return data;
    };

    //Crud about
    const getContactAll = () => {
        getContact()
            .then((res) => {
                let ckObject = {
                    contact_text: {
                        ru: res.data.contact_text.ru,
                        uz: res.data.contact_text.uz,
                    },
                    id:res.data.id
                };

                setCkData(ckObject);
                console.log(ckObject)

            })
            .catch((err) => {
                toastErr();
            });
    };
    const saveContactAll = () => {
        formContact
            .validateFields()
            .then((values) => {
                if (values.id) {
                    console.log(values)
                } else {
                    if(values.contact[`O\`zbek`] && values.contact[`Русский`]){
                        let data = {
                            contact_text: {
                                uz: values.contact[`O\`zbek`],
                                ru: values.contact[`Русский`]
                            },

                        }
                        sendContact(data)
                            .then(() => {
                                getContactAll();
                                toastEdit("Kontakt ma'lumotlari")

                            })
                            .catch((error) => {
                                toastErr('Xatolik   server bilan muammo !!!')
                            })

                    }
                    else if(values.contact[`O\`zbek`]){
                        let data = {
                            contact_text: {
                                uz: values.contact[`O\`zbek`],
                                ru: ckData.contact_text.ru
                            }
                        }
                        sendContact(data)
                            .then(() => {
                                getContactAll();
                                toastEdit("Kontakt ma'lumotlari")

                            })
                            .catch((error) => {
                                toastErr('Xatolik   server bilan muammo !!!')
                            })

                    }
                    else if(values.contact[`Русский`]){
                        let data = {
                            contact_text: {
                                ru: values.contact[`Русский`],
                                uz: ckData.contact_text.uz
                            }
                        }
                        sendContact(data)
                            .then(() => {
                                getContactAll();
                                toastEdit("Kontakt ma'lumotlari")

                            })
                            .catch((error) => {
                                toastErr('Xatolik   server bilan muammo !!!')
                            })
                    }



                }
            })
            .catch((err) => {
                toastErr();
            });
        setContactId("");
    };


    return (
        <div className="contact-section">
            <Tabs defaultActiveKey="1" centered>
                <TabPane tab="Contact Request" key="1">
                    <List
                        itemLayout="horizontal"
                        dataSource={requestList}
                        renderItem={(item) => (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
                                    }
                                    title={
                                        <div>
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    marginBottom: "0",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                {item.name}
                                            </p>
                                            {/*<p*/}
                                            {/*  style={{*/}
                                            {/*    display: "inline-block",*/}
                                            {/*    marginBottom: "0",*/}
                                            {/*    marginRight: "20px",*/}
                                            {/*    color: "blue",*/}
                                            {/*  }}*/}
                                            {/*>*/}
                                            {/*  {item.title}*/}
                                            {/*</p>*/}
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    marginBottom: "0",
                                                    marginRight: "20px",
                                                    color: "blue",
                                                }}
                                            >
                                                {item.email}
                                            </p>
                                            <p style={{float: "right", marginRight: "20px"}}>
                                                <DeleteFilled
                                                    onClick={() => delContactRequest(item.id)}
                                                    style={{
                                                        fontSize: "20px",
                                                        color: "#309BC8",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    }

                                    description={

                                        <Collapse ghost>
                                            <Panel
                                                header={
                                                    <div style={{display: "inline-block"}}>
                                                        <p style={{display: "inline-block"}}>
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                }
                                            >
                                                <p>{item.message}</p>
                                            </Panel>
                                        </Collapse>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>

                <TabPane tab="Contact Info" key="2">
                    <Form {...layout} form={formContact}>
                        {languages
                            ? languages.map((item, index) => {
                                return (
                                    <Form.Item
                                        label={`Kontakt ma'lumotlarini  ${item.name}  tilda  kiriting:`}
                                        name={["contact", item.name]}
                                        key={[item, "contact"]}
                                        getValueFromEvent={ckChange}
                                    >
                                        <CKEditor
                                            data={
                                                ckData.id ? (
                                                    index % 2 == 0 ? ckData.contact_text.ru : ckData.contact_text.uz
                                                ): ''
                                            }
                                            editor={ClassicEditor}
                                            onChange={(e, editor, id) => ckChange(e, editor, item.id)
                                            }
                                        />
                                    </Form.Item>
                                );
                            })
                            : ""}
                    </Form>
                    <Button
                        type="primary"
                        style={{float: "right", marginRight: "30px"}}
                        onClick={saveContactAll}
                    >
                        Save changes
                    </Button>
                    {/*<Button*/}
                    {/*    type="danger"*/}
                    {/*    style={{float: "right", marginRight: "30px"}}*/}
                    {/*    onClick={delContactAll}*/}
                    {/*>*/}
                    {/*    Delete*/}
                    {/*</Button>*/}
                </TabPane>
                <TabPane tab="Contact Map" key="3">
                    <YMaps>
                        <div>
                            <Map
                                onClick={(e) => editContactMap(e.get("coords"))}
                                style={{width: "100%", height: "70vh"}}
                                defaultState={{center: contactmap, zoom: 16}}
                                modules={["Placemark", "geocode", "geoObject.addon.balloon"]}
                            >
                                {contactmap.length > 0 ? (
                                    <Placemark geometry={contactmap}/>
                                ) : null}
                                <ZoomControl/>
                                <FullscreenControl/>
                                <SearchControl/>
                                <GeolocationControl/>
                            </Map>
                        </div>
                    </YMaps>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default ContactActual;
