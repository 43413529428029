import { HttpRequestHub } from "../HttpRequestHub";

export const getQuestion = (page = 1, size = 10) => {
    const config = {
        method: "GET",
        url: `get-faq-feedback?page=${page}&size=${size}`,
    };
    return HttpRequestHub(config);
};

export const sendQuestion = (data) => {
    const config = {
        method: "POST",
        url: "question/answer",
        data,
    };
    return HttpRequestHub(config);
}; 

export const deleteQuestion = (id) => {
    const config = {
        method: "POST",
        url: `destroy-faq/${id}`,
    };
    return HttpRequestHub(config);
};

export const getQuestionGlobal = (page = 1, size = 10) => {
    const config = {
        method: "GET",
        url: `get-faq-list?page=${page}&size=${size}`,
    };
    return HttpRequestHub(config);
};

export const getQuestionGlobalById = (id) => {
    const config = {
      method: "GET",
      url: `get-faq/${id}`,
    };
    return HttpRequestHub(config);
  };

export const sendQuestionGlobal = (data) => {
    console.log(data)
    const config = {
        method: "POST",
        url: "create-faq",
        data,
    };
    return HttpRequestHub(config);
};

export const editQuestionGlobal = (data,id) => {
    const config = {
        method: "POST",
        url: `update-faq/${id}`,
        data,
    };
    return HttpRequestHub(config);
};

export const deleteQuestionGlobal = (id) => {
    const config = {
        method: "POST",
        url: `destroy-faq/${id}`,
    };
    return HttpRequestHub(config);
};

