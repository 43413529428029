import { HttpRequestHub } from "../HttpRequestHub";

export const getFilter = () => {
    const config = {
        method: "GET",
        url: "filter/",
    };
    return HttpRequestHub(config);
};

export const sendFilter = (data) => {
    const config = {
        method: "POST",
        url: "filter/",
        data,
    };
    return HttpRequestHub(config);
};

export const editFilter = (data) => {
    const config = {
        method: "POST",
        url: "filter/edit",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteFilter = (id) => {
    const config = {
        method: "DELETE",
        url: "filter/delete/" + id,
    };
    return HttpRequestHub(config);
};

export const sendFilterDistance = (data) => {
    const config = {
        method: "POST",
        url: "filter-distance/",
        data,
    };
    return HttpRequestHub(config);
};

export const editFilterDistance = (data) => {
    const config = {
        method: "POST",
        url: "filter-distance/edit",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteFilterDistance = (id) => {
    const config = {
        method: "DELETE",
        url: "filter-distance/delete/" + id,
    };
    return HttpRequestHub(config);
};