import React, { useCallback, useEffect, useState } from 'react';
import './Dashboard.css'
import { Button, List, Avatar, Collapse, Select, Pagination, Modal, Statistic } from 'antd';
import { checkOrders, getOrders } from '../../server/config/OrderCrud';
import { ConfirmComment, DeleteComment, GetCommentsFalse, GetCommentsTrue } from '../../server/config/Comment';
import moment from 'moment';
import { WEB_SOCKET_URL } from '../../constants';
import SockJS from "sockjs-client";
import { toastErr } from '../../Notifications/toast';
var Stomp = require("stompjs");

const Dashboard = () => {
    const { Countdown } = Statistic;
    const { Option } = Select;
    const [totalSizeFalse, setSizeFalse] = useState(0);
    const [totalSizeTrue, setSizeTrue] = useState(0);


    const webSocket = useCallback(() => {
        const soc = new SockJS(WEB_SOCKET_URL);
        const stompClient = Stomp.over(soc);
        stompClient.connect({}, function (frame) {
            // console.log("Connected: " + frame);
            stompClient.subscribe("/topic/messages", function (msg) {
                console.log("Salom", JSON.parse(msg.body));
                if (JSON.parse(msg.body).event === "MODERATOR") {
                    console.log("websoketga yangilanish keldi", JSON.parse(msg.body).content);
                    getOrdersAll();
                }

            });
        });
    }, [])


    const { Panel } = Collapse;

    // orders 
    const getOrdersAll = () => {
        getOrders("RESERVED")
            .then(res => {
                console.log(res.data.content);
                setOrderList(res.data.content)
            })
            .catch(err => {
                toastErr();
            })
    }

    function getOrdersByStatus(value) {
        console.log(`selected ${value}`);
        getOrders(value)
            .then(res => {
                console.log(res.data.content);
                setOrderList(res.data.content)
            })
            .catch(err => {
                toastErr();
            })
    }

    const [orderList, setOrderList] = useState([
        {
            clinicName: null,
            createdTime: '2021-10-03T00:11:10.622Z',
            doctorName: "Akmal doctor",
            orderId: "1b43a501-0e79-4416-9544-8beede23ed34",
            serviceItemName: "service main",
            status: null
        },
        {
            clinicName: null,
            createdTime: '2021-10-03T00:11:30.622Z',
            doctorName: "Akmal doctor",
            orderId: "1b43a501-0e79-4416-9544-8beede23ed34",
            serviceItemName: "service main",
            status: null
        },
        {
            clinicName: null,
            createdTime: '2021-10-03T00:11:50.622Z',
            doctorName: "Akmal doctor",
            orderId: "1b43a501-0e79-4416-9544-8beede23ed34",
            serviceItemName: "service main",
            status: null
        },
    ])

    //Comment Crud
    const [dataFalse, setDataFalse] = useState([]);
    const [dataTrue, setDataTrue] = useState([]);
    const getCommentsFalse = (page) => {
        GetCommentsFalse(page ? page : 1)
            .then(res => {
                const Data = res.data;
                setSizeTrue(Data.total);
                let keyed = [];
                keyed = Data.data.map(item => {
                    return { ...item, key: item.id }
                });
                setDataFalse(keyed);
                console.log(keyed);
            })
            .catch(err => {
                toastErr();
            })
    }

    const getCommentsTrue = (page) => {
        GetCommentsTrue(page ? page : 1)
            .then(res => {
                const Data = res.data;
                setSizeTrue(Data.total);
                let keyed = [];
                keyed = Data.data.map(item => {
                    return { ...item, key: item.id }
                });
                setDataTrue(keyed)
            })
            .catch(err => {
                toastErr();
            })
    }

    const onChangeFalse = (page) => {
        getCommentsFalse(page)
    }

    const onChangeTrue = (page) => {
        getCommentsTrue(page)
    }

    const deleteComment = (id, some) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysanmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                let data = new FormData()
                data.append('status', 0)
                DeleteComment(id, data)
                    .then(() => {
                        if (some) {
                            getCommentsFalse()
                        } else {
                            getCommentsTrue()
                        }
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    const confimComment = (id) => {
        Modal.confirm({
            content: "Kommentni websiteda ko'rinishini xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                let data = new FormData()
                data.append('status', 1)
                ConfirmComment(id, data)
                    .then((res) => {
                        getCommentsFalse();
                        getCommentsTrue();
                        getOrdersAll();
                        console.log(res);
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    useEffect(() => {
        // getOrdersAll();
        getCommentsTrue();
        getCommentsFalse();
        // webSocket();
    }, [])

    return (
        <div className='dashboard-moderator'>
            <div className="dashboard-left">
                <div className="comments-confirm">
                    <List
                        header={<h3 style={{ padding: '0 20px' }}>Saytdagi kommentariyalarni tasdiqlang</h3>}
                        itemLayout="horizontal"
                        dataSource={dataFalse}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Button onClick={() => deleteComment(item.id, "false")} type='danger' key="list-loadmore-edit">Delete</Button>,
                                    <Button onClick={() => confimComment(item.id)} type='primary' key="list-loadmore-more">Confirm</Button>
                                ]}
                            >
                                <List.Item.Meta
                                    key={item.id}
                                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                    // title={
                                    //     <div>
                                    //         <p style={{ display: "inline-block", marginBottom: "0", marginRight: '20px' }}>{item.username}</p>
                                    //     </div>
                                    // }
                                    description={
                                        <Collapse ghost>
                                            <Panel header={item.user.username} >
                                                <p>{item.comment}</p>
                                                <p style={{ display: "inline-block", marginBottom: "0", marginRight: '20px', color: 'blue' }}>{item.email}</p>
                                            </Panel>
                                        </Collapse>
                                    }
                                />
                            </List.Item>
                        )}
                    />,
                    <Pagination pageSize={10} defaultCurrent={1} total={totalSizeFalse} onChange={onChangeFalse} style={{ float: "right" }} />
                </div>
                <div className="payment-confirm">
                    <List
                        header={<h3 style={{ padding: '0 20px' }}>Saytdagi ko'rinayotgan kommentariyalar</h3>}
                        itemLayout="horizontal"
                        dataSource={dataTrue}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    // <Button onClick={() => deleteComment(item.id)} type='danger' key="list-loadmore-edit">Delete</Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    key={item.id}
                                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                                    description={
                                        <Collapse ghost>
                                            <Panel header={item.user.username} >
                                                <p>{item.comment}</p>
                                                <p style={{ display: "inline-block", marginBottom: "0", marginRight: '20px', color: 'blue' }}>{item.user.email}</p>
                                            </Panel>
                                        </Collapse>
                                    }
                                />
                            </List.Item>
                        )}
                    />,
                    <Pagination pageSize={10} defaultCurrent={1} total={totalSizeTrue} onChange={onChangeTrue} style={{ float: "right" }} />
                </div>
            </div>
            {/*<div className="order-confirm">*/}
            {/*    <Select defaultValue="ORDERED" style={{ width: 200, marginBottom: "20px" }} onChange={getOrdersByStatus}>*/}
            {/*        <Option value="ORDERED" key="ORDERED">Ordered</Option>*/}
            {/*        <Option value="REJECTED" key="REJECTED">Rejected</Option>*/}
            {/*        <Option value="NOT_ANSWERED" key="NOT_ANSWERED">Not Answered</Option>*/}
            {/*        <Option value="TIME_FINISHED" key="TIME_FINISHED">Time Finished</Option>*/}
            {/*    </Select>*/}

            {/*    {*/}
            {/*        orderList.length > 0 ? orderList.map((item, index) => (*/}
            {/*            <div className="order-card" key={index}>*/}
            {/*                <h3> {item.clinicName}</h3>*/}
            {/*                <p>{item.phoneNumber}</p>*/}
            {/*                <div className='order-buttons'>*/}
            {/*                    <div className="countdown_button">*/}
            {/*                        <Countdown*/}
            {/*                            format="mm:ss"*/}
            {/*                            value={*/}
            {/*                                moment(item.createdAt).valueOf() + 4 * 60 * 1000*/}
            {/*                            }*/}
            {/*                        />*/}
            {/*                    </div>*/}
            {/*                    <Button className="check_Order__Button" type='primary' onClick={() => { checkOrders(item.id); getOrdersAll() }}>Check</Button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )) : <div className="noZakaz">*/}
            {/*            Buyurtmalar Mavjud Emas*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    );
};


export default Dashboard;