import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SicknessCategory from "./Components/SicknessCategory";
import LoginP from "./pages/LoginP";
import LayOut from "./Components/LayOut";
import { SITE_LANG, TOKEN } from "./constants";
import { getCookie } from "./functions/useCookies";
import NotFound from "./pages/NotFound";

import Category from "./Components/Categories/Category";
import Illness from "./Components/illness/illness";
import Symptom from "./Components/symptom/Symptom";
import Speciality from "./Components/speciality/Speciality";
import Dashboard from "./Components/Dashboard/Dashboard";
import Question from "./Components/Questions/Question";
import Clinic from "./Components/Clinics/Clinic";
import Filter from "./Components/Filter/Filter";
import News from "./Components/News/News";
import SymptomCategory from "./Components/symptomCategory/SymptomCategory";
import ContactActual from "./Components/ContactActual/ContactActual";
import Region from "./Components/Regions/Region";
import Contact from "./Components/Contact/Contact";
import TemplateCategory from "./Components/TemplateCategory/TemplateCategory";
import Storage from "./Components/StororageProfile/Storage";
import Template from "./Components/shablon/Template";
import Partner from "./Components/Partner/Partner";
import Content from "./Components/Content/Content";
import Dictionary from "./Components/Dictionary/Dictionary";
import DoctorLanguages from "./Components/Doctor Lang, spec_direc, ser_cat/DoctorLanguages";
import DoctorSpecDirection from "./Components/Doctor Lang, spec_direc, ser_cat/DoctorSpecDirection";
import DoctorSpecializations from "./Components/doctorSpecializations/DoctorSpecializations";
import DoctorAcademicTitle from "./Components/doctoracademictitle/DoctorAcademicTitle";
import Weekdays from "./Components/Weekdays/Weekdays";
import MedQuestionCategory from "./Components/Med-question-Categories/MedQuestionCategory";
import Partner2 from "./Components/Partner/Partner2";

function App() {
  const token = getCookie(TOKEN);
  //   const token = 'ahusdgi23hsad123qjw'
  useEffect(() => {
    const lan = localStorage.getItem(SITE_LANG);
    if (lan) {
      localStorage.setItem(SITE_LANG, lan);
    } else {
      localStorage.setItem(SITE_LANG, "uz");
    }
  }, []);
  return (
    <Router>
      <ToastContainer />
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            key="main_route"
            render={() => {
              return token ? (
                <Redirect to="/dashboard" key="/dashboard" />
              ) : (
                <Redirect to="/login" key="/login" />
              );
            }}
          />
          <Route path="/login" key="/login_route" exact component={LoginP} />
          {token ? (
            <LayOut>
              <Route
                path="/dashboard"
                key="/dashboard"
                exact
                component={Dashboard}
              />
              <Route path="/category" key="/category" component={Category} />
              <Route path="/illness" key="/illness" component={Illness} />
              <Route path="/med-question-category" key="/med-question-category" component={MedQuestionCategory} />
              <Route
                path="/sicknesscategory"
                key="/sicknesscategory"
                component={SicknessCategory}
              />
                <Route
                    path="/doctor-specializations"
                    key="/doctor-specializations"
                    component={DoctorSpecializations}
                />
              <Route path="/symptom" key="/symptom" component={Symptom} />
              <Route path="/doctor-academic-t" key="/doctor-academic-t" component={DoctorAcademicTitle} />
              <Route
                path="/symptomcategory"
                key="/symptomcategory"
                component={SymptomCategory}
              />
              <Route path="/contact" key="/contact" component={ContactActual} />
              <Route path="/about" key="/about" component={Contact} />
              <Route
                path="/speciality"
                key="/speciality"
                component={Speciality}
              />
              <Route path="/question" key="/question" component={Question} />
              <Route path="/shablon" key="/shablon" component={Template} />
              <Route path="/clinic" key="/clinic" component={Clinic} />
              <Route path="/filter" key="/filter" component={Filter} />
              <Route path="/news" key="/news" component={News} />
              <Route path="/region" key="/region" component={Region} />
              <Route
                path="/template"
                key="/template"
                component={TemplateCategory}
              />
              <Route path="/storage" key="/storage" component={Storage} />
              <Route path="/partner" key="/partner" component={Partner} />
              <Route path="/partner2" key="/partner" component={Partner2} />
              <Route path="/price" key="/price" component={Content} />
              <Route
                path="/dictionary"
                key="/dictionary"
                component={Dictionary}
              />
                <Route path="/doctor-languages" key="/doctor_languages" component={DoctorLanguages} />
                <Route path="/doctor-specialization-direction" key="/doctor-specialization-direction" component={DoctorSpecDirection} />
                <Route path="/weekdays" key="/doctor-specialization-direction" component={Weekdays} />
            </LayOut>
          ) : (
            <Redirect to="/login" key="last_key" />
          )}
          <Route exact key="/last_route" component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
