import React, {useEffect, useState} from "react";
import "./Question.css";
import {Tabs, List, Avatar, Button, Collapse, Modal, Input, Form} from "antd";
import {DeleteFilled, EditFilled} from "@ant-design/icons";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    deleteQuestion,
    deleteQuestionGlobal,
    editQuestionGlobal,
    getQuestion,
    getQuestionGlobal,
    getQuestionGlobalById,
    sendQuestion,
    sendQuestionGlobal,
} from "../../server/config/Question";
import {getLanguages} from "../../server/config/GetLenguage";
import {Pagination} from "antd";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const Question = () => {
    const [formQuestionGlobal] = Form.useForm();
    const {Panel} = Collapse;
    const {TabPane} = Tabs;
    const {TextArea} = Input;
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const [answerValue, setAnswer] = useState("");
    const [answerId, setAnswerId] = useState("");
    const [answerGlobalId, setAnswerGlobalId] = useState("");
    const [totalSize, setSize] = useState(0);
    const [languages, setLanguages] = useState([]);
    const [data, setData] = useState([]);
    const [dataGlobal, setDataGlobal] = useState([]);
    const [editdata_next, editDataNext] = useState([]);
    const [questionGlobalModal, setGlobalModal] = useState(false);
    const [deleteRequestModal, setDeleteRequestModal] = useState(false);
    const [editID, setEditID] = useState(null);

    useEffect(() => {
        getAllGlobal();
        getQuestions();
        getLanguages().then((res) => {
            setLanguages(res.data);
        });
    }, []);

    //Crud Qustions
    const changeAnswer = (e) => {
        setAnswer(e.target.value);
    };
    const getQuestions = (page, pageSize) => {
        getQuestion(page, pageSize)
            .then((res) => {
                console.log(res)
                const Data = res.data;
                setData(Data);
                setSize(Data.totalElements);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const sendAnswer = () => {
        let data = {
            questionId: answerId,
            answer: answerValue,
        };
        sendQuestion(data)
            .then(() => {
                getQuestions();
                toast.success("Javob yuborildi");
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delQuestion = (id) => {
        console.log(id)
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteQuestion(id)
                    .then(() => {
                        getQuestions();
                        toastDel("Savol");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    //Crud question Global
    const getAllGlobal = () => {
        getQuestionGlobal()
            .then((res) => {
                setDataGlobal(res.data);
                console.log(res)
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getGlobalById = (id) => {
        setEditID(id)
        getQuestionGlobalById(id)
            .then((res) => {
                let data = {
                    question: {
                        ru: res.data.question.ru,
                        uz: res.data.question.uz,
                    },
                    answer: {
                        ru: res.data.answer.ru,
                        uz: res.data.answer.uz,
                    },
                };

                editDataNext(data)
                formQuestionGlobal.setFieldsValue(data);

            })
            .catch((err) => {
                toastErr();
            });
    };
    const saveQuestionGlobal = () => {
        formQuestionGlobal.validateFields()
            .then((values) => {
                if (answerGlobalId) {
                    console.log()
                    editQuestionGlobal({...values,"status":1},editID)
                        .then(() => {
                            getAllGlobal();
                            toastEdit("Savol");
                            setGlobalModal(false);
                        })
                        .catch((err) => {
                            toastErr();
                        });
                } else {
                    let save_question_data={
                        answer:{
                            ru:values.answer.ru,
                            uz:values.answer.uz,
                        },
                        question:{
                            ru:values.question.ru,
                            uz:values.question.uz,
                        },
                        'status':1
                    }
                    sendQuestionGlobal(save_question_data)
                        .then(() => {
                            setGlobalModal(false);
                            getAllGlobal();
                            toastAdd("savol");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                }
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delQuestionGlobal = (id) => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                console.log(id)
                deleteQuestionGlobal(id)
                    .then(() => {
                        getAllGlobal();
                        toastDel("Savol");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    const clearModal = () => {
        formQuestionGlobal.resetFields();
    };

    const onChange = (page, pageSize) => {
        getQuestions(page, pageSize);
    };

    return (
        <div className="question-section">
            <Tabs defaultActiveKey="1" centered>
                <TabPane tab="Questions" key="1">
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item key={item.questionId}>
                                <List.Item.Meta
                                    key={index}
                                    avatar={
                                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
                                    }
                                    title={
                                        <div>
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    marginBottom: "0",
                                                    marginRight: "20px",
                                                }}
                                            >
                                                {item.user.name} {item.user.surname}

                                            </p>
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    marginBottom: "0",
                                                    marginRight: "20px",
                                                    color: "blue",
                                                }}
                                            >
                                                {item.user.phone}
                                            </p>
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    marginBottom: "0",
                                                    marginRight: "20px",
                                                    color: "blue",
                                                }}
                                            >
                                                {item.user.email}
                                            </p>
                                            <p style={{float: "right", marginRight: "20px"}}>
                                                <DeleteFilled
                                                    onClick={() => delQuestion(item.id)}
                                                    style={{
                                                        fontSize: "20px",
                                                        color: "#309BC8",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </p>
                                        </div>
                                    }
                                    description={
                                        <Collapse ghost>
                                            <Panel
                                                header={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <p style={{display: "inline-block"}}>
                                                            {item.question}
                                                        </p>
                                                        {item.answer ? (
                                                            ""
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    setDeleteRequestModal(true);
                                                                    setAnswerId(item.id);
                                                                }}
                                                                type="primary"
                                                                style={{float: "right", marginRight: "20px"}}
                                                            >
                                                                Answer
                                                            </Button>
                                                        )}
                                                    </div>
                                                }
                                            >
                                                <p
                                                    style={{display: "inline-block", color: "#575050"}}
                                                >
                                                    {item.answer ? item.answer : "No Answer"}
                                                </p>
                                            </Panel>
                                        </Collapse>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    ,
                    <Pagination
                        defaultCurrent={1}
                        total={totalSize}
                        onChange={onChange}
                        style={{float: "right"}}
                        hideOnSinglePage={true}
                    />
                </TabPane>
                <TabPane tab="Favourities" key="2">
                    <div
                        style={{display: "flex", justifyContent: "center", width: "100%"}}
                    >
                        <Button
                            onClick={() => {
                                setGlobalModal(true);
                                clearModal();
                            }}
                            type="danger"
                            style={{margin: "0 auto"}}
                        >
                            Add Question
                        </Button>
                    </div>
                    <List
                        itemLayout="horizontal"
                        dataSource={dataGlobal}
                        renderItem={(item, index) => (
                            <List.Item key={item.questionGlobalId}>
                                <List.Item.Meta
                                    key={index}
                                    avatar={
                                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"/>
                                    }
                                    title={
                                        <Collapse ghost>
                                            <Panel
                                                header={
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <p style={{display: "inline-block"}}>
                                                            {item.question}
                                                        </p>
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    delQuestionGlobal(item.id);
                                                                }}
                                                                style={{
                                                                    marginRight: "20px",
                                                                    border: "none",
                                                                    fontSize: "20px",
                                                                    float: "right",
                                                                }}
                                                            >
                                                                <DeleteFilled/>
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    getGlobalById(item.id);
                                                                    setGlobalModal(true);
                                                                    setAnswerGlobalId(item.id);
                                                                }}
                                                                style={{
                                                                    marginRight: "-5px",
                                                                    border: "none",
                                                                    fontSize: "20px",
                                                                }}
                                                            >
                                                                <EditFilled/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <p
                                                    style={{display: "inline-block", color: "#575050"}}
                                                >
                                                    {item.answer}
                                                </p>
                                            </Panel>
                                        </Collapse>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    ,
                </TabPane>
            </Tabs>

            <Modal
                width={700}
                title="Answer1"
                visible={deleteRequestModal}
                onOk={() => {
                    setDeleteRequestModal(false);
                    sendAnswer();
                }}
                onCancel={() => {
                    setDeleteRequestModal(false);
                    setAnswerId("");
                }}
                okText="Send"
                cancelText="Cancel"
            >
                <TextArea
                    rows={4}
                    onChange={changeAnswer}
                    value={answerValue}
                    placeholder={"Answer to question"}
                />
            </Modal>

            <Modal
                width={700}
                title="Answer2"
                visible={questionGlobalModal}
                onOk={() => {
                    setGlobalModal(false);
                    saveQuestionGlobal();
                }}
                onCancel={() => {
                    setGlobalModal(false);
                    setAnswerGlobalId("");
                }}
                okText="Send"
                cancelText="Cancel"
            >
                <Form {...layout}
                      form={formQuestionGlobal}>
                    {languages
                        ? languages.map((item, index) => {
                            return (
                                <>
                                    <h2 key={index.id}>Ma'lumotlarni {item.name}da kiriting</h2>

                                    <Form.Item

                                        label="Savol matnini kiriting:"
                                        name={["question", item.url]}
                                        rules={[{required: true, message: "To'ldirilmagan"}]}
                                    >
                                        <TextArea rows={4} placeholder={"Question..."}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Javob matnini kiriting:"
                                        name={["answer", item.url]}
                                        rules={[{required: true, message: "To'ldirilmagan"}]}
                                    >
                                        <TextArea rows={4} placeholder={"Answer..."}/>
                                    </Form.Item>
                                </>
                            );
                        })
                        : ""}
                </Form>
            </Modal>
        </div>
    );
};

export default Question;
