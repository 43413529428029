import {
  HttpRequestHub,
  HttpRequestHubFile, 
} from "../HttpRequestHub";

export const checkIdealList = (data) => {
  const config = {
    method: "POST",
    url: "content/check/ideal-list",
    responseType: "blob",
    data,
  };
  return HttpRequestHub(config);
};

export const downloadServices = (data) => {
  const config = {
    method: "POST",
    url: "content/standard-service",
    data,
  };
  return HttpRequestHub(config);
};

export const downloadPrices = (data) => {
  const config = {
    method: "POST",
    url: "content/clinic-price-list",
    data,
  };
  return HttpRequestHubFile(config);
};
