import { HttpRequestHub } from "../HttpRequestHub";

export const getStorage = () => {
    const config = {
        method: "GET",
        url: "storage-profile/get-list",
    };
    return HttpRequestHub(config);
};

export const sendStorage = (data) => {
    console.log(data);
    const config = {
        method: "POST",
        url: "storage-profile/store",
        data,
    };
    return HttpRequestHub(config);
};

export const editStorage = (data, id) => {
    const config = {
        method: "POST",
        url: "storage-profile/update/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const deleteStorage = (id) => {
    const config = {
        method: "DELETE",
        url: "storage-profile/delete/" + id,
    };
    return HttpRequestHub(config);
};

export const getStorageById = (id) => {
    const config = {
        method: "GET",
        url: "storage-profile/show/" + id,
    };
    return HttpRequestHub(config);
};