import React, {useCallback, useEffect, useState} from "react";
import "../illness/Illness.css";
import {Table, Button, Modal, Form, Input, Select, Switch} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
    deleteSpecialityConfig,
    editSpecialtyConfig,
    getAllSpecialities,
    getSpecialityByIdConfig,
    sendSpeciality,
} from "../../server/config/SpecialityConfig";
import {getAllServices, getAllServicesConfig} from "../../server/config/ServiceCrud";
import {getLanguages} from "../../server/config/GetLenguage";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const {Option} = Select;
// const children = [];

// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }

const handleChange = (value) => {
    console.log(`selected ${value}`);
};
const Speciality = () => {
    const [specialityData, setSpecialityData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [editObject, setEditObject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [formSpeciality] = Form.useForm();
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const {Option} = Select;
    const [lang, setLang] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [children, setChildren] = useState([])

    useEffect(() => {
        languageGet();
    }, []);
    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const gerServices = () => {
        getAllServicesConfig()
            .then((res) => {
                setServiceData(res.data);
                setChildren(res.data)
            })
            .catch((err) => {
                toastErr();
            });
    };

    // table functions
    const start = () => {
        setSelectedRowKeys([]);
        setEditObject([]);
    };
    const onSelectChange = (value) => {
        console.log(value)
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const columns = [
        {
            title: "Mutaxasislik nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Standart servislari",
            render: (speciality) => (
                <Select
                    defaultValue="Standart servislarni ko'rish"
                    style={{width: 200}}
                    // onClick={() => getSicknesses(category.id)}
                >
                    {speciality.standard_services.length > 0
                        ? speciality.standard_services.map((item, index) => {
                            return (
                                <Option
                                    key={`${index}option`}
                                    value="disabled"
                                    style={{pointerEvents: "none"}}
                                >
                                    {item.name}
                                </Option>
                            );
                        })
                        : ""}
                </Select>
            ),
            key: "item",
        },
    ];

    //   modal add symtop
    const showModal = () => {
        setIsModalVisible(true);
        formSpeciality.resetFields();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    //Specialty Crud
    const getAllSpecialitiesFunc = useCallback((page) => {
        setLoading(true);
        getAllSpecialities(page)
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                setSpecialityData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.totalElements,
                }));
            })
            .catch((err) => {
                toastErr();
                setLoading(false);
            });
    }, []);
    const getServicesForSee = (id) => {
        getSpecialityByIdConfig(id)
            .then((res) => {
                const dataList = res.data[0].standardServiceIdList;
                let arr = [];
                if (serviceData.length > 0) {
                    serviceData.forEach((item) => {
                        if (dataList.includes(item.standardServiceId)) {
                            arr.push(item);
                        }
                    });
                    setServiceList(arr);
                }
            })
            .catch((err) => {
                toastErr();
            });
    };
    const handleOk = () => {
        if (editObject.id) {
            const id = editObject.id;
            formSpeciality
                .validateFields()
                .then((values) => {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    editSpecialtyConfig(id, newdata)
                        .then((res) => {
                            getAllSpecialitiesFunc(pagination.current);
                            setIsModalVisible(false);
                            setSelectedRowKeys([]);
                            setEditObject([]);
                            toastEdit("Mutahasislik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            formSpeciality
                .validateFields()
                .then((values) => {
                    let newdata = new FormData()
                    Object.keys(values).map((item) => {
                        if (typeof values[item] === "object") {
                            Object.keys(values[item]).forEach(value => {
                                newdata.append(`${item}[${value}]`, values[item][value])
                            })
                        } else {
                            newdata.append(item, values[item])
                        }
                    });
                    sendSpeciality(newdata)
                        .then((res) => {
                            getAllSpecialitiesFunc(pagination.current);
                            toastAdd("mutahasislik");
                            setIsModalVisible(false);
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }
    };
    const editSpeciality = () => {
        const id = selectedRowKeys[0];
        getSpecialityByIdConfig(id)
            .then((res) => {
                let data = res.data
                const standard_services = []
                res.data.standard_services.map(item => {
                    standard_services.push(item.id)
                })
                console.log(res.data)
                data["standard_services"] = standard_services
                setEditObject(res.data);
                showModal();
                formSpeciality.setFieldsValue(data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSpeciality = () => {
        selectedRowKeys.forEach((item) => {
            deleteSpecialityConfig(item)
                .then((res) => {
                    getAllSpecialitiesFunc(pagination.current);
                    toastDel("Mutahasislik");
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "Rosdan ham o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSpeciality();
            },
        });
    }

    const handleTableChange = (page) => {
        getAllSpecialitiesFunc(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };

    useEffect(() => {
        getAllSpecialitiesFunc();
        gerServices();
    }, [getAllSpecialitiesFunc]);
    const [size, setSize] = useState('middle');
    return (
        <div className="illness">
            <div>
                <div style={{marginBottom: 16}}>
                    <Button type="primary" onClick={showModal} disabled={hasSelected}>
                        Yangi Mutaxasislik yaratish
                    </Button>
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{marginLeft: 16, color: "green"}}
                        onClick={editSpeciality}
                    >
                        <EditOutlined/>
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{marginLeft: 16}}
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowKey={(o) => o.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={specialityData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            </div>

            <Modal
                title="Muataxasislik qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={handleOk}
                onCancel={handleCancel}
                className="illness-modal"
            >
                <Form {...layout} form={formSpeciality}>
                    {lang.map((item, index) => (
                        <>
                            <h3
                                style={{textTransform: "capitalize"}}
                                key={`${item.name}malumot`}
                            >
                                {item.name}da kiritiladigan ma'lumotlar
                            </h3>
                            <Form.Item
                                label={`Mutaxasislik nomini ${item.name} da kiriting:`}
                                name={["name", item.url]}
                                key={`${index}speciality`}
                                rules={[{required: true, message: "To'ldiring!"}]}
                            >
                                <Input/>
                            </Form.Item>
                        </>
                    ))}

                    <Form.Item
                        label={`Speciality servislarini tanlang:`}
                        name="standard_services"
                        key="standard_services"
                        rules={[{required: true, message: "To'ldiring!"}]}
                    >
                        <Select

                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }

                            mode="multiple" allowClear style={{width: "100%"}}>
                            {serviceData.map((item, index) => (
                                <Option value={item.id} key={`${index}service`}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default Speciality;
