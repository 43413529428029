import React, {useEffect, useState, useRef} from "react";
import ReactHtmlParser from "react-html-parser";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {getTemplateCategory} from "../../server/config/TemplateCategory";
import {
    getTemplateSubCategorybyCategory,
    getTemplateSubCategoryById,
} from "../../server/config/TemplateSubcategory";
import {
    Button,
    Space,
    Pagination,
    Layout,
    Form,
    Modal,
    Table,
    Input,
    Select,
    Card,
    Switch
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ArrowRightOutlined,
    SearchOutlined,
    ArrowLeftOutlined,
} from "@ant-design/icons";
import {
    deleteTemplateObject,
    editTemplateObject,
    getTemplateObjectById,
    getTemplateObjects,
    getTemplateObjectsBySubcategoryId,
    sendTemplateObject,
} from "../../server/config/TemplateObject";
import {
    deleteTemplate,
    editTemplate, getFullTemplateById,
    getTemplateById,
    getTemplates,
    getTemplatesBySubcategoryId,
    sendTemplate,
} from "../../server/config/TemplatesCrud";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";

const {Header, Content} = Layout;

const Template = () => {
    const {Option} = Select;
    const {TextArea} = Input;
    const [formTemplateObject] = Form.useForm();
    const [formTemplate] = Form.useForm();
    const layout = {labelCol: {span: 24}, wrapperCol: {span: 24}};
    const [isTemplate, setTemplate] = useState(false);
    const [aktivId, setAktivId] = useState("");
    const [editId, setEditId] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [readList, setReadList] = useState({});
    const [readTemplateList, setReadTemplateList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [loading2, setLoading2] = useState(false);
    const [pagination2, setPagination2] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const [templateObjectList, setObjectList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [categoryList, setCategory] = useState([]);
    const [subCategoryList, setSubCategory] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState("");
    const [categoryId2, setCategoryId2] = useState([]);
    const [categoryList2, setCategory2] = useState([]);
    const [subCategoryList2, setSubCategory2] = useState([]);
    const [subCategoryId2, setSubCategoryId2] = useState("");
    const [templateModal, setTemplateModal] = useState(false);
    const [isModal, setModal] = useState(false);
    const [readTemplateModal, setReadTemplateModal] = useState(false);
    const [readModal, setReadModal] = useState(false);
    const [ckData, setCkData] = useState("");
    const [templateListAdded, setTemplateListAdded] = useState([]);
    const [boolean, setBoolean] = useState([]);
    const [booleantoggle, setBooleantoggle] = useState(``);
    const useindex = useRef(0);

    const [switchStatus, setSwitchStatus] = useState(1)

    useEffect(() => {
        TemplateObjectGet();
        categoryGet();
        filterCategoryGet();
    }, []);

    const onSwitchChange = (checked) => {
        if (checked){
            setSwitchStatus(1)
        }
        else {
            setSwitchStatus(0)
        }
    }

    //Template Object Crud
    const TemplateObjectGet = (page) => {
        setLoading(true);
        getTemplateObjects(page ? page : 1).then((res) => {
            setLoading(false);
            const Data = res.data.data;
            console.log(res.data)
            let keyed = res.data.data.map((item) => {
                return {...item, key: item.id};
            });
            setObjectList(keyed);
            setPagination((prev) => ({
                ...prev,
                total: Data.totalElements,
            }));
        });
    };
    // const filterTemplateItems = () => {
    //     setAktivId(subCategoryId2);
    //     if (isTemplate) {
    //         getTemplatesBySubcategoryId(subCategoryId2, pagination2.current)
    //             .then((res) => {
    //                 setTemplateList(res.data.content);
    //             })
    //             .catch((err) => {
    //                 toastErr();
    //             });
    //     } else {
    //         getTemplateObjectsBySubcategoryId(subCategoryId2, pagination.current)
    //             .then((res) => {
    //                 setObjectList(res.data.content);
    //             })
    //             .catch((err) => {
    //                 toastErr();
    //             });
    //     }
    // };
    const TemplateObjectGetById = (id) => {
        let idEdit = selectedRowKeys[0]
        console.log(idEdit)
        getTemplateObjectById(editId ? editId : id)
            .then((res) => {
                setReadList(res.data);
                let data = {};
                data["name"] = res.data.name;
                data["description"] = res.data.description;
                data["template_category_id"] = res.data.category.parent_id;
                changeTemplateCategory(res.data.category.parent_id)
                data["template_subcategory_id"] = res.data.template_category_id;
                data["switch"] = res.data.status;
                setCkData(res.data.content);
                formTemplateObject.setFieldsValue(data);


                // getTemplateSubCategoryById(res.data.id)
                //     .then((res) => {
                //         setCategoryId(res.data[0].categoryId);
                //         getTemplateSubCategorybyCategory(res.data[0].categoryId, 1, 50)
                //             .then((res) => {
                //                 setSubCategory(res.data.content);
                //             })
                //             .catch((err) => {
                //                 toastErr();
                //             });
                //     })
                //     .catch((err) => {
                //         toastErr();
                //     });
                // setSubCategoryId(res.data.subCategoryId);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const saveTemplateObject = () => {
        formTemplateObject
            .validateFields()
            .then((values) => {
                if (editId) {
                    console.log(values)
                    const data = {
                        name: values.name,
                        description: values.description,
                        content: values.context,
                        template_category_id: values.template_subcategory_id,
                        status: values.switch == true ? 1 : 0,
                    };
                    console.log(data, editId)
                    editTemplateObject(data, editId)
                        .then((res) => {
                            TemplateObjectGet();
                            toastEdit("Template object");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                } else {
                    const data = {
                        name: values.name,
                        description: values.description,
                        content: values.context,
                        template_category_id: values.template_subcategory_id,
                        status: values.switch == true ? 1 : 0,
                    };
                    sendTemplateObject(data)
                        .then((res) => {
                            TemplateObjectGet();
                            toastAdd("template object");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                }
            })
            .catch((err) => {
                toastErr();
            });
        setModal(false);
    };
    const TemplateObjectDelete = () => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi ?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteTemplateObject(editId)
                    .then((res) => {
                        TemplateObjectGet();
                        toastDel("Template object");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };
    const onChange = (boolean) => {
        console.log(`switch to ${boolean}`);
        if (boolean) {
            setBoolean(1)
        } else {
            setBoolean(0)
        }
    };

    //Template Crud
    const TemplatesGet = (page) => {
        setLoading2(true);
        getTemplates(page ? page : 1)
            .then((res) => {
                setLoading2(false);
                const Data = res.data;
                let keyed = Data.data.map((item) => {
                    return {...item, key: item.id};
                });
                setTemplateList(keyed);
                setPagination2((prev) => ({
                    ...prev,
                    total: Data.total,
                }));
            })
            .catch((err) => {
                setLoading2(false);
                toastErr();
            });
    };
    const TemplateGetById = (id) => {
        console.log(id)
        setReadTemplateList([]);
        getFullTemplateById(editId ? editId : id)
            .then((res) => {
                setTemplateName(res.data.name);
                setReadList(res.data);
                let data = {};
                data["name"] = res.data.name;
                data["description"] = res.data.description;
                data["status"] = res.data.status
                data["template_category_id"] = res.data.category.parent_id;
                // setCategoryId(res.data.category.parent_id)
                data["template_subcategory_id"] = res.data.template_category_id;
                // setSubCategoryId(res.data.template_category_id)
                formTemplate.setFieldsValue(data);
                // setSubCategoryId(res.data.id);
                console.log(res.data.templateHelpers);
                let readMassive = [];
                let helperObject = [];
                templateObjectList.map((item) => {
                    return res.data.templates.forEach((res) => {
                        if (item.id === res.id) {
                            helperObject.push(item);
                        }
                    });
                });
                // console.log(helperObject);
                // res.data.templateHelpers.map((item) => {
                //     return getTemplateObjectById(item.id)
                //         .then((res) => {
                //             readMassive.push({context: res.data.description});
                //         })
                //         .catch((err) => {
                //             toastErr();
                //         });
                // });
                // console.log(readMassive);
                // setReadTemplateList(readMassive);

                setReadTemplateList(res.data.templates);
                if (editId) {
                    setTemplateListAdded(helperObject);
                }
                // getTemplateSubCategoryById(res.data.id)
                //     .then((res) => {
                //         setCategoryId(res.data[0].id);
                //         getTemplateSubCategorybyCategory(res.data[0].id, 1, 50)
                //             .then((res) => {
                //                 setSubCategory(res.data);
                //             })
                //             .catch((err) => {
                //                 toastErr();
                //             });
                //     })
                //     .catch((err) => {
                //         toastErr();
                //     });
                setSubCategoryId(res.data.id);
                // else {
                // 	setReadTemplateList(readObject)
                // }
            })
            .catch((err) => {
                toastErr();
            });
    };
    const saveTemplates = () => {
        formTemplate
            .validateFields()
            .then((values) => {
                if (editId) {
                    let TemplateHelpers = [];
                    templateListAdded.forEach((item, index) => {
                        TemplateHelpers.push({
                            sort: index+1,
                            template_id: item.id,
                            status: item.status
                        });
                    });
                    const data = {
                        name: values.name,
                        description: values.description,
                        template_category_id: categoryId,
                        templates: TemplateHelpers,
                        // templateId: editId,
                        status: values.status == true ? 1 : 0,
                    };

                    editTemplate(editId, data)
                        .then((res) => {
                            TemplatesGet();
                            toastEdit("Template");
                            setTemplateModal(false);
                        })
                        .catch((err) => {
                            toastErr();
                        });
                } else {
                    let TemplateHelpers = [];
                    templateListAdded.forEach((item, index) => {
                        TemplateHelpers.push({
                            sort: index+1,
                            template_id: item.id,
                            status: item.status
                        });
                    });
                    const data = {
                        name: values.name,
                        description: values.description,
                        template_category_id: categoryId,
                        templates: TemplateHelpers,
                        status: values.status == true ? 1 : 0,
                    };
                    console.log(data)
                    sendTemplate(data)
                        .then((res) => {
                            TemplatesGet();
                            toastAdd("Template");
                            setTemplateModal(false);
                        })
                        .catch((err) => {
                            toastErr();
                        });
                }
            })
            .catch((err) => {
                toastErr();
            });

    };
    const TemplateDelete = () => {
        Modal.confirm({
            content: "Rostan o'chirishni xohlaysizmi?",
            okText: "Ha",
            cancelText: "Yo'q",
            onOk: () => {
                deleteTemplate(editId)
                    .then((res) => {
                        TemplatesGet();
                        toastDel("Template");
                    })
                    .catch((err) => {
                        toastErr();
                    });
            },
        });
    };

    //Table functions Template Object
    const onSelectChange = (selectedRowKeys) => {
        setEditId(selectedRowKeys[0]);
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
            {
                key: "odd",
                text: "Select Odd Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return false;
                        }
                        return true;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
            {
                key: "even",
                text: "Select Even Row",
                onSelect: (changableRowKeys) => {
                    let newSelectedRowKeys = [];
                    newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                        if (index % 2 !== 0) {
                            return true;
                        }
                        return false;
                    });
                    setSelectedRowKeys(newSelectedRowKeys);
                },
            },
        ],
    };
    const columns = [
        {
            title: "Template object kontexti",
            render: (item) => (
                <Button
                    disabled={editId ? true : false}
                    onClick={() => {
                        TemplateObjectGetById(item.id);
                        setReadModal(true);
                    }}
                >
                    Ko'rish
                </Button>
            ),
            key: "context",
        },
        {
            title: "Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ma'lumot",
            dataIndex: "description",
            key: "description",
        },
    ];

    //Table functions Template
    const columns2 = [
        {
            title: "Template kontexti",
            render: (item) => (
                <Button
                    disabled={editId ? true : false}
                    onClick={() => {
                        TemplateGetById(item.id);
                        setReadTemplateModal(true);
                    }}
                >
                    Ko'rish
                </Button>
            ),
            key: "context",
        },
        {
            title: "Nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ma'lumot",
            dataIndex: "description",
            key: "description",
        },
    ];

    //MedQuestionCategory functions
    const categoryGet = () => {
        getTemplateCategory()
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const changeTemplateCategory = (value) => {
        console.log(value)
        setCategoryId(value);
        getTemplateSubCategorybyCategory(value, 1, 50)
            .then((res) => {
                setSubCategory(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const changeTemplateSubcategory = (value) => {
        setSubCategoryId(value);
    };

    //Filter functions
    const filterCategoryGet = () => {
        getTemplateCategory()
            .then((res) => {
                setCategory2(res.data.content);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const changeTemplateCategory2 = (value) => {
        setCategoryId2(value);
        getTemplateSubCategorybyCategory(value, 1, 50)
            .then((res) => {
                setSubCategory2(res.data.content);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const changeTemplateSubcategory2 = (value) => {
        setSubCategoryId2(value);
    };

    // Modal functions
    const showModal = () => {
        setModal(true);
        clearModal();
    };
    const showTemplateModal = ({type}) => {
        setTemplateModal(true);
        clearTemplateModal();
    };
    const clearModal = () => {
        formTemplateObject.resetFields();
        setSubCategoryId("");
        setCategoryId("");
        setCkData("");
    };
    const clearTemplateModal = () => {
        formTemplate.resetFields();
        setSubCategoryId("");
        setCategoryId("");
        setTemplateListAdded([]);
    };

    // ckeditor
    const ckChange = (event, editor, item) => {
        const data = editor.getData();
        setCkData(data);
        return data;
    };
    const resetSearch = () => {
        setEditId("");
        setAktivId("");
        setSelectedRowKeys([]);
        TemplateObjectGet();
        TemplatesGet();
        setSubCategoryId2("");
        setCategoryId2("");
    };
    const fullBtn = () => {
        resetSearch();
        setTemplate(true);
        setCategoryId("");
        setSubCategoryId("");
    };
    const partBtn = () => {
        setTemplate(false);
        resetSearch();
        setCategoryId("");
        setSubCategoryId("");
    };

    /////////Drag and Drop functions
    const dragStart = (ev, el) => {
        ev.target.classList.add("dragging");
        let data = JSON.stringify(el);
        ev.dataTransfer.setData("dropable", data);
        ev.dataTransfer.setData("isOrder", "false");
    };
    const onDrop = (ev) => {
        let el = JSON.parse(ev.dataTransfer.getData("dropable"));
        let isOrder = ev.dataTransfer.getData("isOrder");
        if (isOrder === "true") {
            let index = +ev.dataTransfer.getData("index");
            let newList = [...templateListAdded];
            newList.splice(index, 1);
            newList.splice(useindex.current, 0, el);
            setTemplateListAdded(newList);
        } else {
            let newList = [...templateListAdded];
            newList.splice(useindex.current, 0, el);
            setTemplateListAdded(newList);
        }
    };
    const delcard = (index) => {
        let newList = [...templateListAdded];
        newList.splice(index, 1);
        setTemplateListAdded(newList);
    };
    const addDrop = (ev, el) => {
        let btn = ev.target.closest("button");
        btn.classList.add("added");
        setTemplateListAdded((prev) => [...prev, el]);
    };
    const dsOrder = (ev, el, index) => {
        ev.target.classList.add("dragging");
        let data = JSON.stringify(el);
        ev.dataTransfer.setData("dropable", data);
        ev.dataTransfer.setData("isOrder", "true");
        ev.dataTransfer.setData("index", index);
    };
    const ondragend = (ev) => {
        ev.target.classList.remove("dragging");
    };
    const dragover = (ev) => {
        ev.preventDefault();
        let element = ev.target.closest("#orderCard");
        if (element) {
            let index = element.getAttribute("index");
            useindex.current = index;
        }
    };

    //Pagination functions
    const handleTableChange = (page) => {
        TemplateObjectGet(page.current);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };
    const handleTableChange2 = (page) => {
        TemplatesGet(page.current);
        setPagination2((prev) => ({
            ...prev,
            current: page.current,
        }));
    };
    const paginationChange = (page) => {
        TemplateObjectGet(page);
    };

    return (
        <Layout className="temp_layut">
            <Header className="temp_head">
                <Space>
                    <Button
                        onClick={partBtn}
                        type={isTemplate ? "default" : "primary"}
                        className="temp_btn"
                    >
                        Template Objects
                    </Button>
                    <Button
                        onClick={fullBtn}
                        type={isTemplate ? "primary" : "default"}
                        className="temp_btn"
                    >
                        Templates
                    </Button>
                </Space>
            </Header>

            <Layout style={{backgroundColor: "white"}}>
                <Content>
                    {/*<div className="temp_cont_head">*/}
                    {/*    <Select*/}
                    {/*        placeholder="Template kategoriyasini tanlang"*/}
                    {/*        onChange={changeTemplateCategory2}*/}
                    {/*        value={categoryId2}*/}
                    {/*    >*/}
                    {/*        {categoryList2*/}
                    {/*            ? categoryList2.map((item, index) => {*/}
                    {/*                return (*/}
                    {/*                    <Option value={item.categoryId} key={index}>*/}
                    {/*                        {item.name}*/}
                    {/*                    </Option>*/}
                    {/*                );*/}
                    {/*            })*/}
                    {/*            : ""}*/}
                    {/*    </Select>*/}
                    {/*    <ArrowRightOutlined/>*/}
                    {/*    <Select*/}
                    {/*        placeholder="Template subkategoriyasini tanlang"*/}
                    {/*        onChange={changeTemplateSubcategory2}*/}
                    {/*        value={subCategoryId2}*/}
                    {/*    >*/}
                    {/*        {subCategoryList2*/}
                    {/*            ? subCategoryList2.map((item, index) => {*/}
                    {/*                return (*/}
                    {/*                    <Option value={item.subCategoryId} key={index}>*/}
                    {/*                        {item.name}*/}
                    {/*                    </Option>*/}
                    {/*                );*/}
                    {/*            })*/}
                    {/*            : ""}*/}
                    {/*    </Select>*/}
                    {/*    <ArrowRightOutlined/>*/}
                    {/*    <Button*/}
                    {/*        onClick={filterTemplateItems}*/}
                    {/*        icon={<SearchOutlined/>}*/}
                    {/*        type="primary"*/}
                    {/*        shape="round"*/}
                    {/*        className="temp_cat_btn"*/}
                    {/*    >*/}
                    {/*        Search...*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                    {isTemplate ? (
                        <Table
                            rowSelection={{type: "radio", ...rowSelection}}
                            columns={columns2}
                            rowKey={(o) => o.id}
                            dataSource={templateList}
                            bordered
                            pagination={pagination2}
                            loading={loading2}
                            onChange={handleTableChange2}
                            title={() => (
                                <div>
                                    <Button
                                        disabled={editId ? true : false}
                                        onClick={showTemplateModal}
                                    >
                                        Yangi Template yaratish
                                    </Button>
                                    {/*<Button*/}
                                    {/*    className={selectedRowKeys.length > 0 ? "" : "display-none"}*/}
                                    {/*    style={{marginLeft: 16, color: "red"}}*/}
                                    {/*    onClick={TemplateDelete}*/}
                                    {/*>*/}
                                    {/*    <DeleteOutlined/>*/}
                                    {/*</Button>*/}
                                    <Button
                                        className={
                                            selectedRowKeys.length === 1 ? "" : "display-none"
                                        }
                                        style={{marginLeft: 16, color: "green"}}
                                        onClick={() => {
                                            TemplateGetById();
                                            setTemplateModal(true);
                                        }}
                                    >
                                        <EditOutlined/>
                                    </Button>
                                    <Button
                                        style={{marginLeft: "20px"}}
                                        onClick={resetSearch}
                                        disabled={editId || aktivId ? false : true}
                                        type="primary"
                                    >
                                        {" "}
                                        Yangilash{" "}
                                    </Button>
                                </div>
                            )}
                        />
                    ) : (
                        <Table
                            rowSelection={rowSelection}
                            columns={columns}
                            rowKey={(o) => o.id}
                            dataSource={templateObjectList}
                            bordered
                            pagination={pagination}
                            loading={loading}
                            onChange={handleTableChange}
                            title={() => (
                                <div>
                                    <Button disabled={editId ? true : false} onClick={showModal}>
                                        Yangi Template object yaratish
                                    </Button>
                                    {/*<Button*/}
                                    {/*    className={selectedRowKeys.length > 0 ? "" : "display-none"}*/}
                                    {/*    style={{marginLeft: 16, color: "red"}}*/}
                                    {/*    onClick={TemplateObjectDelete}*/}
                                    {/*>*/}
                                    {/*    <DeleteOutlined/>*/}
                                    {/*</Button>*/}
                                    <Button
                                        className={
                                            selectedRowKeys.length === 1 ? "" : "display-none"
                                        }
                                        style={{marginLeft: 16, color: "green"}}
                                        onClick={() => {
                                            TemplateObjectGetById();
                                            setModal(true);
                                        }}
                                    >
                                        <EditOutlined/>
                                    </Button>
                                    <Button
                                        style={{marginLeft: "20px"}}
                                        onClick={resetSearch}
                                        disabled={editId || aktivId ? false : true}
                                        type="primary"
                                    >
                                        {" "}
                                        Yangilash{" "}
                                    </Button>
                                </div>
                            )}
                        />
                    )}
                </Content>
            </Layout>

            {/* Template Object qo'shish */}
            <Modal
                title={
                    editId
                        ? "Medcart template object ma'lumotlarini tahrirlang"
                        : "Medcart template object ma'lumotlarini kiriting"
                }
                visible={isModal}
                centered
                width={1000}
                onOk={saveTemplateObject}
                onCancel={() => {
                    setModal(false);
                    clearModal();
                }}
                className="shablon-modal"
            >
                <Form {...layout} form={formTemplateObject}>
                    <div className="clinic_modal__location">
                        <div className="clinic_modal__region">
                            <Form.Item
                                label="Template object kategoriysini va subkategoriyasini tanlang:"
                                key="Template object kategoriysini va subkategoriyasini tanlang:"
                                name='template_category_id'
                            >
                                <Select
                                    placeholder="Template object kategoriyasini tanlang"
                                    onChange={changeTemplateCategory}
                                    name='template_category_id'
                                >
                                    {categoryList
                                        ? categoryList.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={index}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="clinic_modal__district">
                            <Form.Item label=" ." key="Template object subkategoriyasi"
                                       name='template_subcategory_id'>
                                <Select
                                    placeholder="Template object kategoriyasini tanlang"
                                    onChange={changeTemplateSubcategory}
                                    value={subCategoryId}
                                >
                                    {subCategoryList
                                        ? subCategoryList.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={index}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>

                        </div>
                    </div>

                    <Form.Item
                        name="name"
                        key="name"
                        label="Template object nomini kiriting"
                        rules={[
                            {
                                required: true,
                                message: "Please input the title of collection!",
                            },
                        ]}
                    >
                        <Input placeholder="Template nomi" size="large"/>
                    </Form.Item>
                    <Form.Item
                        name="description"
                        key="description"
                        label="Template object haqida malumotni kiriting:"
                        rules={[
                            {
                                required: true,
                                message: "Please input the title of collection!",
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            size="large"
                            placeholder="Template object ma'lumotlari"
                        />
                    </Form.Item>

                    <Form.Item
                        name="context"
                        key="context"
                        label="Template object kontextini kiriting:"
                        getValueFromEvent={ckChange}
                    >
                        <CKEditor
                            data={ckData}
                            editor={ClassicEditor}
                            onChange={(e, editor) => ckChange(e, editor)}
                        />
                    </Form.Item>
                    <Form.Item label="Switch" valuePropName="checked" key='switch' name='switch'>
                        <Switch/>
                    </Form.Item>

                </Form>
            </Modal>

            {/* Template Object haqidagi to'liq ma'lumotlar */}
            <Modal
                width={1000}
                title={`${readList.name} template objecti contenti`}
                centered
                visible={readModal}
                onOk={() => setReadModal(false)}
                onCancel={() => {
                    setReadModal(false);
                }}
            >
                <div style={{minHeight: "40vh"}}>
                    <div>{ReactHtmlParser(readList.content)}</div>
                </div>
            </Modal>

            {/* Template qo'shish */}
            <Modal
                width="80%"
                title={editId ? "Templateni tahrirlash" : "Yangi template yaratish"}
                visible={templateModal}
                onOk={saveTemplates}
                onCancel={() => setTemplateModal(false)}
                okText="Save"
                centered={true}
            >
                <Form {...layout} form={formTemplate}>
                    <div className="clinic_modal__location">
                        <div className="clinic_modal__region">
                            <Form.Item
                                name='template_category_id'
                                label="Template kategoriysini va subkategoriyasini tanlang:"
                                key="Template kategoriysini va subkategoriyasini tanlang:"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the title of collection!",
                                    },
                                ]}
                            >
                                <Select
                                    name='template_category_id'
                                    placeholder="Template kategoriyasini tanlang"
                                    onChange={changeTemplateCategory}
                                    // value={categoryId}
                                >
                                    {categoryList
                                        ? categoryList.map((item, index) => {
                                            return (
                                                <Option value={item.id} key={`templateCat${item.id}`}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="clinic_modal__district">
                            <Form.Item
                                label=" ."
                                key=" modal_district"
                                name='template_subcategory_id'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the title of collection!",
                                    },
                                ]}
                            >
                                <Select
                                    name='template_subcategory_id'
                                    placeholder="Template subkategoriyasini tanlang"
                                    onChange={changeTemplateSubcategory}
                                    value={subCategoryId}
                                >
                                    {subCategoryList
                                        ? subCategoryList.map((item, index) => {
                                            return (
                                                <Option value={item.subCategoryId} key={index}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                        : ""}
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <Form.Item
                        name="name"
                        key="name"
                        label="Template nomini kiriting"
                        rules={[
                            {
                                required: true,
                                message: "Please input the title of collection!",
                            },
                        ]}
                    >
                        <Input placeholder="Template nomi" size="large"/>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        key="description"
                        label="Template haqida malumotni kiriting:"
                        rules={[
                            {
                                required: true,
                                message: "Please input the title of collection!",
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            size="large"
                            placeholder="Template ma'lumotlari"
                        />
                    </Form.Item>
                    <Form.Item label="Status" valuePropName="checked" key='switch' name='status'>
                        <Switch/>
                    </Form.Item>
                    <Form.Item
                        name="templates"
                        key="context"
                        label="Template kontextini tempalte objectlardan yig'ing"
                        getValueFromEvent={ckChange}
                    >
                        <Content className="temp_modal_cont_box">
                            <div
                                className="temp_modal_cont"
                                onDrop={(e) => onDrop(e)}
                                onDragOver={dragover}
                            >
                                {templateListAdded.length > 0 ? (
                                    templateListAdded.map((el, index) => (
                                        <Card
                                            index={index}
                                            id="orderCard"
                                            onDragEnd={ondragend}
                                            onDragStart={(ev) => dsOrder(ev, el, index)}
                                            draggable={true}
                                            key={index}
                                            title={
                                                <div className="temp_mod_card_head">
                                                    <span className="temp_mod_card_tit">{el.title}</span>
                                                    <Space>
                                                        <Button
                                                            onClick={() => delcard(index)}
                                                            icon={<DeleteOutlined/>}
                                                            shape="circle"
                                                            danger
                                                        />
                                                    </Space>
                                                </div>
                                            }
                                            className="temp_card"
                                            hoverable
                                        >
                                            {el.description}
                                        </Card>
                                    ))
                                ) : (
                                    <div className="template_card__hover">
                                        Template contextini yaratish uchun template objectlarni
                                        chapga suring yoki
                                        <Button
                                            icon={<ArrowLeftOutlined/>}
                                            shape="circle"
                                            danger
                                        />
                                        tugmasini ustiga bosing.
                                    </div>
                                )}
                            </div>
                            <div className="temp_modal_side_box">
                                <div className="temp_mod_side_top">
                                    {templateObjectList
                                        ? templateObjectList.map((el, index) => (
                                            <Card
                                                onDragEnd={ondragend}
                                                onDragStart={(e) => dragStart(e, el)}
                                                draggable={true}
                                                key={index}
                                                title={
                                                    <div className="temp_side_card_head">
                              <span className="temp_side_card_tit">
                                {el.title}
                              </span>
                                                        <Space>
                                                            <Button
                                                                onClick={() => {
                                                                    TemplateObjectGetById(el.id);
                                                                    setModal(true);
                                                                }}
                                                                icon={<EditOutlined/>}
                                                                shape="circle"
                                                            />
                                                            <Button
                                                                className="exportBtn"
                                                                shape="circle"
                                                                onClick={(ev) => addDrop(ev, el)}
                                                                icon={<ArrowLeftOutlined/>}
                                                                danger
                                                            ></Button>
                                                        </Space>
                                                    </div>
                                                }
                                                className="temp_side_card "
                                                hoverable
                                            >
                                                {el.description}
                                            </Card>
                                        ))
                                        : ""}
                                </div>
                                <div className="temp_mod_foot">
                                    <Pagination
                                        pageSize={10}
                                        size="small"
                                        total={pagination.total}
                                        onChange={paginationChange}
                                    />
                                </div>
                            </div>
                        </Content>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Template haqidagi to'liq ma'lumotlar */}
            <Modal
                width={1000}
                title={`${templateName} template kontexti`}
                centered
                visible={readTemplateModal}
                onOk={() => setReadTemplateModal(false)}
                onCancel={() => {
                    setReadTemplateModal(false);
                    setReadTemplateList([]);
                }}
            >
                <div
                    style={{
                        minHeight: "40vh",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {readTemplateList
                        ? readTemplateList.map((item, index) => {
                            return <div key={item.id}>{ReactHtmlParser(item.content)}</div>;
                        })
                        : ""}
                </div>
            </Modal>
        </Layout>
    );
};

export default Template;
