import { HttpRequestHub } from "../HttpRequestHub";

export const getAllDoctorLanguages = () => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/languages/get-list`,
    };
    return HttpRequestHub(config);
};

export const sendDoctorLang = (data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/languages/store",
        data,
    };
    return HttpRequestHub(config);
};

export const editDoctorLang = (id, data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/languages/update/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const getDoctorLangById = (id) => {
    const config = {
        method: "GET",
        url: "doctor-information-list-crud/languages/show/" + id,
    };
    return HttpRequestHub(config);
};






