import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  QuestionCircleOutlined,
  DashboardOutlined,
  QuestionOutlined,
  CopyOutlined,
  FilterOutlined,
  CheckOutlined,
  HomeOutlined,
  AreaChartOutlined,
  FileImageOutlined,
  AppstoreOutlined,
  EyeInvisibleOutlined,
  KeyOutlined,
  PartitionOutlined,
  InsuranceOutlined,
  NodeIndexOutlined,
  UngroupOutlined,
  ContactsOutlined,
  CopyrightOutlined,
  UserSwitchOutlined,
  PayCircleOutlined,
  FileSearchOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { getQuestion } from "../server/config/Question";
import { deleteCookie } from "../functions/useCookies";
import { TOKEN } from "../constants";
import DoctorSpecializations from "./doctorSpecializations/DoctorSpecializations";

const LayOut = (props) => {
  useEffect(() => {
    getQuestions();
  }, []);
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false); 
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const [questionCount, setQuestion] = useState(0);
  const getQuestions = () => {
    getQuestion()
      .then((res) => {
        setQuestion(res.data.content.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout className="main-layout" style={{ color: "black" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo"></div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="/1" icon={<DashboardOutlined />}>
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="11" icon={<HomeOutlined />}>
            <Link to="/clinic">Clinics</Link>
          </Menu.Item>
          <Menu.Item key="19" icon={<PayCircleOutlined />}>
            <Link to="/partner">Prices</Link>
          </Menu.Item>
          {/*<Menu.Item key="18" icon={<UserSwitchOutlined />}>*/}
          {/*  <Link to="/partner">Partner</Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="8" icon={<UngroupOutlined />}>
            <Link to="/template">TemplateCategory</Link>
          </Menu.Item>
          <Menu.Item key="9" icon={<FileImageOutlined />}>
            <Link to="/shablon">Templates</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<AppstoreOutlined />}>
            <Link to="/category">Categories</Link>
          </Menu.Item>

          <Menu.Item key="7" icon={<NodeIndexOutlined />}>
            <Link to="/speciality">Speciality</Link>
          </Menu.Item>
          <Menu.Item key="22" icon={<NodeIndexOutlined />}>
            <Link to="/doctor-specializations">Doctor Specializations</Link>
          </Menu.Item>
          <Menu.Item key="24" icon={<NodeIndexOutlined />}>
            <Link to="/doctor-languages">Doctor Languages</Link>
          </Menu.Item>
          <Menu.Item key="25" icon={<NodeIndexOutlined />}>
            <Link to="/doctor-specialization-direction">Doctor Specialization-directions</Link>
          </Menu.Item>
          <Menu.Item key="26" icon={<NodeIndexOutlined />}>
            <Link to="/med-question-category">Med Question Category</Link>
          </Menu.Item>
          <Menu.Item key="23" icon={<NodeIndexOutlined />}>
            <Link to="/doctor-academic-t">Doctor Academic Title</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<InsuranceOutlined />}>
            <Link to="/sicknesscategory">SicknessCategory</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<EyeInvisibleOutlined />}>
            <Link to="/illness">Sickness</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<PartitionOutlined />}>
            <Link to="/symptomcategory">SymptomCategory</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<KeyOutlined />}>
            <Link to="/symptom">Symptom</Link>
          </Menu.Item>
          {/*<Menu.Item key="20" icon={<FileSearchOutlined />}>*/}
          {/*  <Link to="/dictionary">Dictionary</Link>*/}
          {/*</Menu.Item>*/}

          <Menu.Item key="10" icon={<AreaChartOutlined />}>
            <Link to="/region">Regions</Link>
          </Menu.Item>

          <Menu.Item key="12" icon={<CheckOutlined />}>
            <Link to="/news">News</Link>
          </Menu.Item>
          <Menu.Item key="13" icon={<QuestionOutlined />}>
            <Link to="/question">Question</Link>
          </Menu.Item>
          <Menu.Item key="14" icon={<CopyOutlined />}>
            <Link to="/about">Footer/About</Link>
          </Menu.Item>
          <Menu.Item key="15" icon={<ContactsOutlined />}>
            <Link to="/contact">Contact</Link>
          </Menu.Item>
          {/*<Menu.Item key="16" icon={<FilterOutlined />}>*/}
          {/*  <Link to="/filter">Filter elements</Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="17" icon={<CopyrightOutlined />}>
            <Link to="/storage">Storage Profile</Link>
          </Menu.Item>
          <Menu.Item
            key="21"
            icon={<LogoutOutlined />}
            onClick={() => {
              deleteCookie(TOKEN);
              window.location.href = "/login";
            }}
          >
            <Link to="/login">LogOut</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "main-layout-trigger",
              onClick: toggle,
            }
          )}
          <h1 style={{ display: "inline-block" }}>Moderator Admin Panel</h1>
          <Link to="/question">
            <div className="question-icon-div">
              <QuestionCircleOutlined className="question-icon" />
              <div className="question-count">{questionCount}</div>
            </div>
          </Link>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflow: "auto",
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayOut;
