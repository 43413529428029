import {HttpRequestHub} from "../HttpRequestHub";
import {useState} from "react";

export const getRegions = (page, size = "10") => {
    const config = {
        method: "GET",
        url: "basic-list/regions",
    };
    return HttpRequestHub(config);
};

export const sendRegion = (data, file) => {
    let formData = new FormData();
    formData.append('image', file)

    Object.keys(data).map((item) => {
        if (typeof data[item] === "object") {
            Object.keys(data[item]).forEach(value => {
                formData.append(`${item}[${value}]`, data[item][value])
            })
        } else {
            formData.append(item, data[item])
        }
    });
    const config = {
        method: "POST",
        url: "regions/store",
        data: formData
    };
    return HttpRequestHub(config);
};

export const getRegionbyid = (id) => {
    const config = {
        method: "GET",
        url: `regions/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editRegion = (data) => {
    const config = {
        method: "POST",
        url: "regions/edit",
        data,
    };
    return HttpRequestHub(config);
};


export const deleteRegion = (id) => {
    let data = {
        region_id: id
    }
    const config = {
        method: "POST",
        url: 'regions/delete',
        data: data
    };
    return HttpRequestHub(config);
};