import { HttpRequestHub } from "../HttpRequestHub";

export const getDistrictsbyPagination = (id) => {
    const config = {
        method: "GET",
        url: "basic-list/districts/?page=0&size=50",
    };
    return HttpRequestHub(config);
};

export const getDistrictsbyRegion = (id) => {
    const config = {
        method: "GET",
        url: "basic-list/districts?region_id=" + id,
    };
    return HttpRequestHub(config);
};

export const sendDistrict = (data) => {
    const config = {
        method: "POST",
        url: "districts/store",
        data,
    };
    return HttpRequestHub(config);
};

export const getDistrictbyid = (id) => {
    const config = {
        method: "GET",
        url: `districts/show/${id}`
    };
    return HttpRequestHub(config);
};

export const editDistrict = (data,id) => {
    const config = {
        method: "POST",
        url: `districts/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};


export const deleteDistrict = (id) => {
    let data = {
        district_id: id
    }
    const config = {
        method: "POST",
        url: 'districts/delete',
        data: data
    };
    return HttpRequestHub(config);
};