import React, {useCallback, useEffect, useState} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import "./Illness.css";
import {Table, Button, Modal, Form, Input, Select} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {getLanguages} from "../../server/config/GetLenguage";
import {getAllServer, getAllSpecialities} from "../../server/config/SpecialityConfig";
import {getAllSicknessCategories} from "../../server/config/SicknessCategoryConfig";
import {
    deleteSicknessConfig,
    editSicknessConfig,
    getAllSicknessConfig,
    getSicknessByIdConfig,
    sendSicknessConfig,
} from "../../server/config/SicknessConfig";
import {
    toastAdd,
    toastDel,
    toastEdit,
    toastErr,
} from "../../Notifications/toast";
import {getAllCategories} from "../../server/config/Category";
import {getAllSubcategories} from "../../server/config/SubcategoryCrud";
import {getAllServices} from "../../server/config/ServiceCrud";

const {Option} = Select;
const Illness = () => {
    const [formSickness] = Form.useForm();
    const [specialityData, setSpecialityData] = useState([]);
    const [sicknessCategory, setSicknessCategoy] = useState([]);
    const [sicknessData, setSicknessData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [lang, setLang] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editObject, setEditObject] = useState([]);
    const [ckData, setCkData] = useState({uz: "<p></p>", ru: "<p></p>"});
    const [loading, setLoading] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [illnessInfoContent, setIllnessInfoContent] = useState("");
    const [pagination, setPagination] = useState({
        current: 1,
        total: 10,
        hideOnSinglePage: true,
    });
    const layout = {
        labelCol: {span: 24},
        wrapperCol: {span: 24},
    };

    useEffect(() => {
        languageGet();
        getSpecialty();
        getSicknessCategories();
        getAllSickness();
        getServer()
    }, []);

    const languageGet = () => {
        getLanguages()
            .then((res) => {
                setLang(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };

    //   table illlness
    const hasSelected = selectedRowKeys.length > 0;
    const start = () => {
        setSelectedRowKeys([]);
    };
    const onSelectChange = (value) => {
        setSelectedRowKeys(value);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const columns = [
        {
            title: "Kasalik nomi",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Kasallik haqida malumot",
            render: (illnessInfo) => (
                <Button onClick={() => showInfoModal(illnessInfo)}>Batafsil</Button>
            ),
            key: "sicknessId",
        },
    ];
    const showModal = () => {
        setIsModalVisible(true);
        formSickness.resetFields();
        setEditObject([]);
    };
    const {TextArea} = Input;
    //Sickness Crud
    const getSpecialty = () => {
        getAllSpecialities()
            .then((res) => {
                setSpecialityData(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getSicknessCategories = () => {
        getAllSicknessCategories()
            .then((res) => {
                setSicknessCategoy(res.data);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const getAllSickness = (page) => {
        setLoading(true);
        getAllSicknessConfig(page)
            .then((res) => {
                setLoading(false);
                const Data = res.data;
                const data = Data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    };
                });
                setSicknessData(data);
                setPagination((prev) => ({
                    ...prev,
                    total: Data.totalElements,
                }));
            })
            .catch((err) => {
                setLoading(false);
                toastErr();
            });
    };
    const saveSickness = () => {
        console.log(editObject)
        if (editObject.id) {
            formSickness
                .validateFields()
                .then((values) => {
                    let {sicknessCategoryId, specialityIdList, ...rest} = values;
                    let data2 = {
                        ...values,
                        sickness_category_id: sicknessCategoryId,
                        standard_services: specialityIdList,
                    }
                    console.log(data2, editObject.id)
                    editSicknessConfig(data2, editObject.id)
                        .then((res) => {
                            getAllSickness(pagination.current);
                            toastEdit("Kasallik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                    setIsModalVisible(false);
                })
                .catch((err) => {
                    toastErr();
                });
        } else {
            formSickness
                .validateFields()
                .then((values) => {
                    let {sicknessCategoryId, specialityIdList, ...rest} = values;
                    let data2 = {
                        ...values,
                        sickness_category_id: sicknessCategoryId,
                        standard_services: specialityIdList,
                    }
                    sendSicknessConfig(data2)
                        .then((res) => {
                            getAllSickness(pagination.current);
                            toastAdd("Kasallik");
                        })
                        .catch((err) => {
                            toastErr();
                        });
                })
                .catch((err) => {
                    toastErr();
                });
        }

        setSelectedRowKeys([]);
        setEditObject([]);
        setIsModalVisible(false);
    };
    const editSickness = () => {
        showModal();
        const id = selectedRowKeys[0];
        getSicknessByIdConfig(id)
            .then((res) => {
                setEditObject(res.data);
                console.log(res.data)
                let servic_ID = []
                res.data.services.map((item) => {
                    servic_ID.push(item.pivot.service_category_id)
                })
                const data2 = {
                    ...res.data,
                    sicknessCategoryId: res.data.sickness_category_id,
                    specialityIdList: servic_ID
                    // sickness_category_id:res.data.sickness_category_id,

                };

                // res.data.forEach((item) => {
                //   data2[item.language] = {
                //     name: item.name,
                //     description: item.description,
                //   };
                // });
                // data2["sicknessCategoryId"] = res.data[0].id;
                // data2["specialityIdList"] = res.data[0].id;

                console.log(data2)
                formSickness.setFieldsValue(data2);

                let ckObject = {};
                res.data.forEach((item) => {
                    ckObject[item.language] = item.description;
                });
                setCkData(ckObject);
            })
            .catch((err) => {
                toastErr();
            });
    };
    const delSickness = () => {
        selectedRowKeys.forEach((item) => {
            deleteSicknessConfig(item)
                .then((res) => {
                    toastDel("Kasallik");
                    getAllSickness(
                        sicknessData.length > 1
                            ? pagination.current
                            : pagination.current - 1
                    );
                })
                .catch((err) => {
                    toastErr();
                });
        });
        setSelectedRowKeys([]);
    };

    // confirmation
    function confirm() {
        Modal.confirm({
            title: "Rosdan ham o'chirishni xohlaysizmi?",
            icon: <ExclamationCircleOutlined/>,
            okText: "Ha",
            cancelText: "Yo'q",
            onOk() {
                delSickness();
            },
        });
    }

    // kasallik haqida malumot
    const showInfoModal = (illnessInfo) => {
        setInfoModal(true);
        setIllnessInfoContent(illnessInfo);
    };
    const hideInfoModal = () => {
        setInfoModal(false);
    };
    const handleTableChange = (page) => {
        getAllSickness(page.current);
        setSelectedRowKeys([]);
        setPagination((prev) => ({
            ...prev,
            current: page.current,
        }));
    };


    //Standart service===========================
    const [serviceList,setServer]=useState([])
   const getServer = () => {
       getAllServer()
           .then((res) => {
               setServer(res.data);
           })
           .catch((err) => {
               toastErr();
           });
   }
 


    return (
        <div className="illness">
            <div>
                <div style={{marginBottom: 16}}>
                    <Button
                        type="primary"
                        onClick={showModal}
                        key="addBtn"
                        disabled={selectedRowKeys.length > 0 ? true : false}
                    >
                        Kasallik Qo'shish
                    </Button>
                    <Button
                        className={selectedRowKeys.length > 0 ? "" : "display-none"}
                        style={{marginLeft: 16, color: "red"}}
                        onClick={confirm}
                        key="deleteBtn"
                    >
                        <DeleteOutlined/>
                    </Button>
                    <Button
                        className={selectedRowKeys.length === 1 ? "" : "display-none"}
                        style={{marginLeft: 16, color: "green"}}
                        onClick={editSickness}
                        key="editBtn"
                    >
                        <EditOutlined/>
                    </Button>
                    <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        style={{marginLeft: 16}}
                        key="resetBtn"
                    >
                        Yangilash
                    </Button>
                </div>
                <Table
                    rowKey={(o) => o.id}
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={sicknessData}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                    key="tableKey"
                />
            </div>

            <Modal
                title="Kasallik qoshish"
                visible={isModalVisible}
                centered
                width="70%"
                onOk={saveSickness}
                onCancel={() => setIsModalVisible(false)}
                className="illness-modal"
                key="modalAdd"
            >
                <Form {...layout} form={formSickness}>
                    {lang.map((item, index) => (
                        <>
                            <h3 style={{textTransform: "capitalize"}} key={`${item.name}infrom`}>
                                {item.name}da to'ldiriladigan ma'lumotlar:
                            </h3>
                            <Form.Item
                                label={`Kasallik nomini ${item.name}da kiriting`}
                                name={["name", item.url]}
                                key={`${index}name`}
                                rules={[
                                    {required: true, message: "Please input illness name!"},
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label={`Kasallik haqida malumotni ${item.name}da kiriting:`}
                                name={["description", item.url]}
                                key={`${index}description`}
                                // getValueFromEvent={(_, editor) => editor.getData()}
                                // initialValue={ckData[item.name]}
                            >
                                <TextArea rows={4} placeholder={"description..."}/>
                            </Form.Item>
                        </>
                    ))}

                    
                    <Form.Item
                        label="Kasallik tegishli bo'lgan standart servisni tanlang"
                        name="specialityIdList"
                        key="specialityIdList"
                        rules={[{required: true, message: "To'ldiring!"}]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: "100%"}}
                            key="speKey"
                        >
                            {serviceList.map((item, index) => (
                                <Select.Option value={item.id} key={index} optionFilterProp="children"
                                               filterOption={(input, option) => option.children.includes(input)}
                                               filterSort={(optionA, optionB) =>
                                                   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                               }>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={`Kasallik tegishli bo'lgan mutahasislik kategoriyasini tanlang`}
                        name="sicknessCategoryId"
                        key="sicknessCategoryId"
                        rules={[{required: true, message: "To'ldiring!"}]}
                    >
                        <Select key="sickKey" optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }>
                            {sicknessCategory.map((item, index) => (
                                <Select.Option value={item.id} key={index}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={`${illnessInfoContent.name} kassaligi haqida ma'lumot`}
                visible={infoModal}
                centered
                width="70%"
                onOk={hideInfoModal}
                onCancel={hideInfoModal}
                className="illness-info-modal"
                key="modalInform"
            >
                <div className="illness-info" key="infromModal">
                    {ReactHtmlParser(illnessInfoContent.description)}
                </div>
            </Modal>
        </div>
    );
};

export default Illness;
