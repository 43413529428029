import { HttpRequestHub } from "../HttpRequestHub";

export const getAllDoctorSpec = () => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/specialization-directions/get-list`,
    };
    return HttpRequestHub(config);
};

export const sendDoctorSpec = (data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/specialization-directions/store",
        data,
    };
    return HttpRequestHub(config);
};

export const editDoctorSpec = (id, data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/specialization-directions/update/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const getDoctorSpecById = (id) => {
    const config = {
        method: "GET",
        url: "doctor-information-list-crud/specialization-directions/show/" + id,
    };
    return HttpRequestHub(config);
};

export const deleteDoctorSpec = (id) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/specialization-directions/delete/" + id,
    };
    return HttpRequestHub(config);
};






