import { HttpRequestHub } from "../HttpRequestHub";

export const getTemplateObjects = (page) => {
  const config = {
    method: "GET",
    url: `templates/get-list?page=${page - 1}`,
  };
  return HttpRequestHub(config);
};

export const getTemplateObjectsBySubcategoryId = (id, page) => {
  const config = {
    method: "GET",
    url: `template-object/med-card-sub-category/${id}?page=${page - 1}&size=10`,
  };
  return HttpRequestHub(config);
};

export const sendTemplateObject = (data) => {
  const config = {
    method: "POST",
    url: "templates/store",
    data,
  };
  return HttpRequestHub(config);
};

export const getTemplateObjectById = (id) => {
  const config = {
    method: "GET",
    url: "templates/show/" + id,
  };
  return HttpRequestHub(config);
};

export const editTemplateObject = (data,id) => {
  const config = {
    method: "POST",
    url: `templates/update/${id}`,
    data,
  };
  return HttpRequestHub(config);
};

export const deleteTemplateObject = (id) => {
  const config = {
    method: "DELETE",
    url: "template-object/delete/" + id,
  };
  return HttpRequestHub(config);
};
