import {HttpRequestHub} from "../HttpRequestHub";

export const getAllAcademictitle = (page = 1, size = 10) => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/academic-titles/get-list`,
    };
    return HttpRequestHub(config);
};

export const getAcademicTitleByIdConfig = (id) => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/academic-titles/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editAcademicTitle = (data,id) => {
    const config = {
        method: "POST",
        url: `doctor-information-list-crud/academic-titles/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};

export const sendDoctorAcademicTitle = (data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/academic-titles/store",
        data,
    };
    return HttpRequestHub(config);
};