import { HttpRequestHub } from "../HttpRequestHub";

export const getSocialNetwork = () => {
    const config = {
        method: "GET",
        url: "get-social-contact-list",
    };
    return HttpRequestHub(config);
};

export const getSocialNetworkById = (id) => {
    const config = {
        method: "GET",
        url: "get-social-contact/" + id,
    };
    return HttpRequestHub(config);
};

export const sendSocialNetwork = (data) => {
    const config = {
        method: "POST",
        url: "create-social-contact",
        data,
    };
    return HttpRequestHub(config);
}; 

export const editSocialNetwork = (id, data) => {
    const config = {
        method: "POST",
        url: "update-social-contact/" + id,
        data,
    };
    return HttpRequestHub(config);
}; 

export const deleteSocialNetwork = (id) => {
    const config = {
        method: "POST",
        url: "destroy-social-contact/" + id,
    };
    return HttpRequestHub(config);
};

