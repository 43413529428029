import React, { useEffect, useState } from "react";
import "../Regions/Region.css";
import { PlusOutlined, DeleteFilled, EditOutlined } from "@ant-design/icons";
import {Button, Table, Modal, Input, Form, Switch} from "antd";
import { getLanguages } from "../../server/config/GetLenguage";
import {
  deleteTemplateCategory,
  editTemplateCategory,
  getTemplateCategory,
  getTemplateCategoryById,
  sendTemplateCategory,
} from "../../server/config/TemplateCategory";
import {
  deleteTemplateSubCategory,
  editTemplateSubCategory,
  getTemplateSubCategorybyCategory,
  getTemplateSubCategoryById,
  sendTemplateSubCategory,
} from "../../server/config/TemplateSubcategory";
import {
  toastAdd,
  toastDel,
  toastEdit,
  toastErr,
} from "../../Notifications/toast";

function TemplateCategory() {
  const [formCategory] = Form.useForm();
  const [formSubCategory] = Form.useForm();
  const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const [languages, setLanguages] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [aktivId, setAktivId] = useState("");
  const [regionModal, setCategoryModal] = useState(false);
  const [districtModal, setSubCategoryModal] = useState(false);
  const [subCategoryList, setSubCategory] = useState([]);
  const [categoryList, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
    hideOnSinglePage: true,
  });

    const [switchStatus, setSwitchStatus] = useState(1)



  useEffect(() => {
    categoriesGet();
    getLanguages().then((res) => {
      setLanguages(res.data);
    });
  }, []);


    const onSwitchChange = (checked) => {
        if (checked){
            setSwitchStatus(1)
        }
        else {
            setSwitchStatus(0)
        }
    }
  // MedQuestionCategory Crud
  const categoriesGet = () => {
    getTemplateCategory()
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const categoryGetById = (id) => {
    getTemplateCategoryById(id).then((res) => {
      const data = res.data;
        console.log(res.data)
        setSwitchStatus(res.data.status)
      formCategory.setFieldsValue(data);
    });
  };
  const categorySave = () => {
    formCategory
      .validateFields()
      .then((values) => {
        if (categoryId) {
            let newdata = new FormData()
            Object.keys(values).map((item) => {
                if (typeof values[item] === "object") {
                    Object.keys(values[item]).forEach(value => {
                        newdata.append(`${item}[${value}]`, values[item][value])
                    })
                } else {
                    newdata.append(item, values[item])
                }
            });
            newdata.delete('status')
            newdata.append('status', switchStatus)
          editTemplateCategory(categoryId, newdata)
            .then(() => {
              categoriesGet();
              toastEdit("Template kategoriyasi");
              setCategoryModal(false);
                formCategory.resetFields()
            })
            .catch((err) => {
              toastErr();
            });
        } else {
            let newdata = new FormData()
            Object.keys(values).map((item) => {
                if (typeof values[item] === "object") {
                    Object.keys(values[item]).forEach(value => {
                        newdata.append(`${item}[${value}]`, values[item][value])
                    })
                } else {
                    newdata.append(item, values[item])
                }
            });
            newdata.delete('status')
            newdata.append('status', switchStatus)
          sendTemplateCategory(newdata)
            .then(() => {
              categoriesGet();
              toastAdd("template kategoriyasi");
              setCategoryModal(false);
                formCategory.resetFields()
            })
            .catch((err) => {
              toastErr();
            });
        }
      })
      .catch((err) => {
        toastErr();
      });
  };
  const categoryDelete = (id) => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        setAktivId("");
        deleteTemplateCategory(id)
          .then((res) => {
            setCategoryId("");
            categoriesGet();
            toastDel("Template kategoriyasi");
          })
          .catch((err) => {
            toastErr();
          });
      },
    });
  };

  // SubCategory Crud
  const subCategoryGet = (id, page) => {
    setLoading(true);
    getTemplateSubCategorybyCategory(id, page ? page : 1)
      .then((res) => {
        setLoading(false);
        const Data = res.data;
        setSubCategory(Data);
        setPagination((prev) => ({
          ...prev,
          total: Data.totalElements,
        }));
      })
      .catch((err) => {
        setLoading(false);
        toastErr();
      });
  };
  const subCategoryGetById = (id) => {
      getTemplateCategoryById(id).then((res) => {
          const data = res.data;
          setSwitchStatus(res.data.status)
          formSubCategory.setFieldsValue(data);
      });
  };
  const subCategorySave = () => {
    formSubCategory
      .validateFields()
      .then((values) => {
        if (subCategoryId) {
            let newdata = new FormData()
            Object.keys(values).map((item) => {
                if (typeof values[item] === "object") {
                    Object.keys(values[item]).forEach(value => {
                        newdata.append(`${item}[${value}]`, values[item][value])
                    })
                } else {
                    newdata.append(item, values[item])
                }
            });
            newdata.delete('status')
            newdata.append('status', switchStatus)
            newdata.append('parent_id', aktivId)
          editTemplateSubCategory(subCategoryId, newdata)
            .then(() => {
              subCategoryGet(aktivId, pagination.current);
              toastEdit("Template subkategoriyasi");
            })
            .catch((err) => {
              toastErr();
            });
        } else {
            let newdata = new FormData()
            Object.keys(values).map((item) => {
                if (typeof values[item] === "object") {
                    Object.keys(values[item]).forEach(value => {
                        newdata.append(`${item}[${value}]`, values[item][value])
                    })
                } else {
                    newdata.append(item, values[item])
                }
            });
            newdata.delete('status')
            newdata.append('status', switchStatus)
            newdata.append('parent_id', aktivId)
          sendTemplateSubCategory(newdata)
            .then(() => {
              subCategoryGet(aktivId, pagination.current);
              toastAdd("template subkategoriyasi");
            })
            .catch((err) => {
              toastErr();
            });
        }
        setSubCategoryModal(false);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const subCategoryDelete = (id) => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteTemplateSubCategory(id)
          .then((res) => {
            toastDel("Template subkategoriyasi");
            subCategoryGet(
              aktivId,
              subCategoryList.length > 1
                ? pagination.current
                : pagination.current - 1
            );
          })
          .catch((err) => {
            toastErr();
          });
      },
    });
  };

  // Table functions
  const columnsRegion = [
    {
      title: <h1>Template Categoriyalari</h1>,
      render: (item) => (
        <Button
          onClick={() => {
            subCategoryGet(item.id);
            setAktivId(item.id);
          }}
          type="text"
        >
          {item.name}
        </Button>
      ),
      key: "name",
    },
    {
      title: (
        <Button
          className="category-main-button"
          onClick={() => {
            setCategoryModal(true);
            setCategoryId("");
            clearRegionModal();
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PlusOutlined style={{ fontSize: "25px" }} />
        </Button>
      ),
      render: (item) => (
        <React.Fragment>
          {/*<DeleteFilled*/}
          {/*  className="cate-button-icon cate-icon-delete"*/}
          {/*  onClick={() => categoryDelete(item.id)}*/}
          {/*/>*/}
          <EditOutlined
            className="cate-button-icon cate-icon-edit"
            onClick={() => {
              setCategoryId(item.id);
              setCategoryModal(true);
              categoryGetById(item.id);
            }}
          />
        </React.Fragment>
      ),
      key: "item",
    },
  ];
  const columnsDistrict = [
    {
      title: <h1>Template SubCategoriyalari</h1>,
      dataIndex: "name",
      key: "namase",
    },
    {
      title: (
        <Button
          className="category-main-button"
          onClick={() => {
            setSubCategoryModal(true);
            setSubCategoryId("");
            clearDistrictModal();
          }}
          disabled={aktivId ? false : true}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PlusOutlined style={{ fontSize: "25px" }} />
        </Button>
      ),
      render: (item) => (
        <React.Fragment>
          {/*<DeleteFilled*/}
          {/*  className="cate-button-icon cate-icon-delete"*/}
          {/*  onClick={() => subCategoryDelete(item.id)}*/}
          {/*/>*/}
          <EditOutlined
            className="cate-button-icon cate-icon-edit"
            onClick={() => {
              setSubCategoryId(item.id);
              setSubCategoryModal(true);
              subCategoryGetById(item.id);
            }}
          />
        </React.Fragment>
      ),
      key: "item",
    },
  ];

  //Clear Modal
  const clearRegionModal = () => {
    const data = {};
    languages.forEach((item) => {
      data[item] = { name: null };
    });
    formCategory.setFieldsValue(data);
  };
  const clearDistrictModal = () => {
    const data = {};
    languages.forEach((item) => {
      data[item] = { name: null };
    });
    formSubCategory.setFieldsValue(data);
  };

  const handleTableChange = (page) => {
    subCategoryGet(aktivId, page.current);
    setPagination((prev) => ({
      ...prev,
      current: page.current,
    }));
  };

  return (
    <>
      <div className="main-categories-content region_section">
        <div className="region_left" style={{ width: "50%" }}>
          <Table
            rowKey={(o) => o.categoryId}
            columns={columnsRegion}
            dataSource={categoryList}
            style={{ width: "100%" }}
            pagination={false}
          />
        </div>
        <div className="region_right" style={{ width: "50%" }}>
          <Table
            rowKey={(o) => o.subCategoryId}
            columns={columnsDistrict}
            dataSource={subCategoryList}
            style={{ width: "100%" }}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>

      {/* Region Modal */}
      <Modal
        width={1000}
        title={
          categoryId
            ? "Template Categoriyasi Ma'lumotlarini tahrirlash"
            : "Yangi Template Categoriyasi Qo'shish"
        }
        centered
        visible={regionModal}
        okText={categoryId ? "O'zgarishlarni Saqlash" : "Saqlash"}
        cancelText="Bekor qilish"
        onOk={categorySave}
        onCancel={() => {
            formCategory.resetFields()
          setCategoryModal(false);
        }}
      >
        <Form {...layout} form={formCategory}>
          {languages
            ? languages.map((item, index) => {
                return (
                  <Form.Item
                    name={["name", item.url]}
                    key={index.url + "name"}
                    label={`Template Categoriyasi Nomini Kiriting (${item.name})`}
                    rules={[{ required: true, message: "To'ldirilmagan" }]}
                  >
                    <Input
                      size="large"
                      placeholder={`Template Categoriyasi ${item.name} nomi...`}
                    />
                  </Form.Item>
                );
              })
            : null}
            <Form.Item
                label={`Sickness category status`}
                name={'status'}
            >
                <Switch
                    onChange={onSwitchChange}
                    checked={switchStatus}
                />
            </Form.Item>
        </Form>
      </Modal>

      {/* District Modal */}
      <Modal
        width={1000}
        title={
          subCategoryId
            ? "Template Subcategoriyasi Ma'lumotlarini tahrirlash"
            : "Yangi Template Subcategoriyasi Qo'shish"
        }
        centered
        visible={districtModal}
        okText={subCategoryId ? "O'zgarishlarni Saqlash" : "Saqlash"}
        cancelText="Bekor qilish"
        onOk={subCategorySave}
        onCancel={() => {
          setSubCategoryModal(false);
        }}
      >
        <Form {...layout} form={formSubCategory}>
          {languages
            ? languages.map((item, index) => {
                return (
                  <Form.Item
                      name={["name", item.url]}
                      key={index.url + "nameSubCategory"}
                    label={`Template Subcategoriyasi Nomini Kiriting (${item.name})`}
                    rules={[{ required: true, message: "To'ldirilmagan" }]}
                  >
                    <Input
                      size="large"
                      placeholder={`Template Subcategoriyasi ${item.name} nomi...`}
                    />
                  </Form.Item>
                );
              })
            : null}
            <Form.Item
                label={`Sickness category status`}
                name={'status'}
            >
                <Switch
                    onChange={onSwitchChange}
                    checked={switchStatus}
                />
            </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default TemplateCategory;
