import {HttpRequestHub} from "../HttpRequestHub";


export const getAllSpecialization = (page = 1, size = 10) => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/specializations/get-list`,
    };
    return HttpRequestHub(config);
};


export const getSpecializationByIdConfig = (id) => {
    const config = {
        method: "GET",
        url: `doctor-information-list-crud/specializations/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editSpecializationsConfig = (data,id) => {
    const config = {
        method: "POST",
        url: `doctor-information-list-crud/specializations/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};

export const sendSpecializations = (data) => {
    const config = {
        method: "POST",
        url: "doctor-information-list-crud/specializations/store",
        data,
    };
    return HttpRequestHub(config);
};