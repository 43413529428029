import { HttpRequestHub } from "../HttpRequestHub";

export const getTemplateCategory = () => {
    const config = {
        method: "GET",
        url: "template-category/get-list",
    };
    return HttpRequestHub(config);
};

export const sendTemplateCategory = (data) => {
    const config = {
        method: "POST",
        url: "template-category/store",
        data,
    };
    return HttpRequestHub(config);
};

export const getTemplateCategoryById = (id, data) => {
    const config = {
        method: "GET",
        url: "template-category/show/" + id,
        data,
    };
    return HttpRequestHub(config);
};

export const editTemplateCategory = (id, data) => {
    const config = {
        method: "POST",
        url: "template-category/update/" + id,
        data,
    };
    return HttpRequestHub(config);
};


export const deleteTemplateCategory = (id) => {
    const config = {
        method: "DELETE",
        url: "med-card-category/delete/" + id,
    };
    return HttpRequestHub(config);
};