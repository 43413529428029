import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, SITE_LANG, TOKEN } from "../constants";
import { deleteCookie, getCookie } from "../functions/useCookies";

export const token = getCookie(TOKEN);

export let host = API_URL;

export let headers = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: token ? `Bearer ${token}` : "",
};

export let axiosInstance = axios.create({
  baseURL: `${host}/`,
  headers,
  timeout: 100000,
});


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error.response.data)
      if (error.response && error.response.data) {
        error.response.data.errors &&
        toast.error(
            Object.values(error.response.data.errors)
                .map((item) => item[0])
                .join(", ")
        );
      }
      throw error;
    }
);