import React, { useEffect, useState } from "react";
import "../illness/Illness.css";
// import ReactHtmlParser from 'react-html-parser';
import {Table, Button, Modal, Form, Input, Select, Switch} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  deleteSymptomCategory,
  editSymptomCategoryConfig,
  getAllSymptomCategories,
  getSymptomCategoryByIdConfig,
  sendSymptomCategory,
} from "../../server/config/SymptomCategory";
import { getLanguages } from "../../server/config/GetLenguage";
import { getSymptomsBySymptomCategory } from "../../server/config/SymptomConfig";
import {
  toastAdd,
  toastDel,
  toastEdit,
  toastErr,
} from "../../Notifications/toast";
import {editSicknessCategoryConfig, sendSicknessCategory} from "../../server/config/SicknessCategoryConfig";
const SymptomCategory = () => {
  const { Option } = Select;
  const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const [symptomCategoryList, setSymptomCategoryList] = useState([]);
  const [formSymptomCategory] = Form.useForm();
  const [editObject, setEditObject] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [symptomsList, setSymptomsList] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
    hideOnSinglePage: true,
  });
  const [switchStatus, setSwitchStatus] = useState(1)

  //   table symtopmcategory
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Symtom kategoriya nomi",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Categoriyaga biriktirilgan symptomlar",
      render: (category) => (
          <Select
              defaultValue="Simtomlarni ko'rish"
              style={{ width: 200 }}
              // onClick={() => getSicknesses(category.id)}
          >
            {category.symptoms.length > 0
                ? category.symptoms.map((item, index) => {
                  return (
                      <Option
                          key={`${index}option`}
                          value="disabled"
                          style={{ pointerEvents: "none" }}
                      >
                        {item.name}
                      </Option>
                  );
                })
                : ""}
          </Select>
      ),
      key: "item",
    },
  ];

  const onSwitchChange = (checked) => {
    if (checked){
      setSwitchStatus(1)
    }
    else {
      setSwitchStatus(0)
    }
  }

  const start = () => {
    setSelectedRowKeys([]);
  };
  const onSelectChange = (value) => {
    setSelectedRowKeys(value);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //   modal add symtop category
  const showModal = () => {
    setIsModalVisible(true);
    formSymptomCategory.resetFields()
    setSwitchStatus(1)
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditObject([]);
  };

  //Sumptom MedQuestionCategory Crud
  const getLanguagesFunc = () => {
    getLanguages()
      .then((res) => {
        setLanguages(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const getSymptoms = (id) => {
    getSymptomsBySymptomCategory(id)
      .then((res) => {
        setSymptomsList(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const getAllSymptomCategoriesFunction = (page) => {
    setLoading(true);
    getAllSymptomCategories(page)
      .then((res) => {
        console.log(res.data)
        setLoading(false);
        const Data = res.data;
        const data = Data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setSymptomCategoryList(data);
        setPagination((prev) => ({
          ...prev,
          total: Data.totalElements,
        }));
      })
      .catch((err) => {
        toastErr();
        setLoading(false);
      });
  };
  const saveSymptomCategory = () => {
    if (editObject.id) {
      const id = editObject.id;
      formSymptomCategory.validateFields().then((values) => {
        let newData = new FormData()
        Object.keys(values).map((item) => {
          if (typeof values[item] === "object") {
            Object.keys(values[item]).forEach(value => {
              newData.append(`${item}[${value}]`, values[item][value])
            })
          } else {
            newData.append(item, values[item])
          }
        });
        newData.delete('status')
        newData.append('status', switchStatus)
        editSymptomCategoryConfig(id, newData)
            .then((res) => {
              getAllSymptomCategoriesFunction(pagination.current);
              setSelectedRowKeys([]);
              toastEdit("Kassallik kategoriyasi");
              handleCancel();
            })
            .catch((err) => {
              toastErr();
            });
      });
    } else {
      formSymptomCategory.validateFields().then((values) => {
        let newData = new FormData()
        Object.keys(values).map((item) => {
          if (typeof values[item] === "object") {
            Object.keys(values[item]).forEach(value => {
              newData.append(`${item}[${value}]`, values[item][value])
            })
          } else {
            newData.append(item, values[item])
          }
        });
        newData.delete('status')
        newData.append('status', switchStatus)
        sendSymptomCategory(newData)
            .then((res) => {
              getAllSymptomCategoriesFunction(pagination.current);
              toastAdd("kassallik kategoriyasi");
              setSwitchStatus(1)
              handleCancel();
            })
            .catch((err) => {
              toastErr();

            });
      });
    }
  };
  const delSymtopCategory = () => {
    selectedRowKeys.forEach((item) => {
      deleteSymptomCategory(item)
        .then((res) => {
          toastDel("Simptom kategoriyasi");
          getAllSymptomCategoriesFunction(
            symptomCategoryList.length > 1
              ? pagination.current
              : pagination.current - 1
          );
        })
        .catch((err) => {
          toastErr();
        });
    });
    setSelectedRowKeys([]);
  };
  const editSymptomCategory = () => {
    const id = selectedRowKeys[0];
    getSymptomCategoryByIdConfig(id)
        .then((res) => {
          setEditObject(res.data);
          showModal();
          formSymptomCategory.setFieldsValue(res.data);
          setSwitchStatus(res.data.status)
        })
        .catch((err) => {
          toastErr();
        });
  };
  function confirm() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "Rosdan ham o'chirishni xohlaysizmi?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk() {
        delSymtopCategory();
      },
    });
  }

  const handleTableChange = (page) => {
    getAllSymptomCategoriesFunction(page.current);
    setSelectedRowKeys([]);
    setPagination((prev) => ({
      ...prev,
      current: page.current,
    }));
  };

  useEffect(() => {
    getAllSymptomCategoriesFunction();
    getLanguagesFunc();
  }, []);

  return (
    <div className="illness">
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button disabled={hasSelected} type="primary" onClick={showModal}>
            Symptom Categoriya Qo'shish
          </Button>
          {/*<Button*/}
          {/*  className={selectedRowKeys.length > 0 ? "" : "display-none"}*/}
          {/*  style={{ marginLeft: 16, color: "red" }}*/}
          {/*  onClick={confirm}*/}
          {/*>*/}
          {/*  <DeleteOutlined />*/}
          {/*</Button>*/}
          <Button
            className={selectedRowKeys.length === 1 ? "" : "display-none"}
            style={{ marginLeft: 16, color: "green" }}
            onClick={editSymptomCategory}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            onClick={start}
            disabled={!hasSelected}
            style={{ marginLeft: 16 }}
          >
            Yangilash
          </Button>
        </div>
        <Table
          rowKey={(p) => p.id}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={symptomCategoryList}
          loading={loading}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </div>

      <Modal
        title="Symtom kategoriya qoshish"
        visible={isModalVisible}
        centered
        width="70%"
        okText="Saqlash"
        cancelText="Bekor qilish"
        onOk={saveSymptomCategory}
        onCancel={handleCancel}
        className="illness-modal z-modal"
      >
        <Form {...layout} form={formSymptomCategory}>
          {languages.map((item, index) => (
              <Form.Item
                  label={`Sickness kategoriyani ${item.name} da kiriting`}
                  name={["name", item.url]}
                  rules={[{ required: true, message: "Toldirilmagan" }]}
                  key={`${index}sicknesscategory`}
              >
                <Input />
              </Form.Item>
          ))}
          <Form.Item
              label={`Sickness category status`}
              name={'status'}
          >
            <Switch
                onChange={onSwitchChange}
                checked={switchStatus}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SymptomCategory;
