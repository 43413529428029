import { post, get } from "axios";
import { TOKEN } from "../../constants";
import { getCookie } from "../../functions/useCookies";

import { host } from "../host";
import { HttpRequestHub } from "../HttpRequestHub";

const token = getCookie(TOKEN);
export const fileUpload = (file, url) => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: token ? `Bearer ${token}` : "",
      "content-type": "multipart/form-data",
    },
  };
  return post(`${host}${url}`, formData, config);
};

export const deleteFile = (id,data) => {
  // const config = {
  //   method: "POST",
  //   url: `update-article/${id}`,
  //   data
  // };
  // return HttpRequestHub(config);
};

export const getFile = (id) => {
  const config = {
    method: "GET",
    url: `files/public/${id}`,
  };
  return HttpRequestHub(config);
};

export const getFileURl = (url) => {
  return get(url);
};



export const orderFileUpload = (data = {}, onProgress) => {
  const config = {
    method: "POST",
    url: `files/public/`,
    data,
    onUploadProgress: ({ total, loaded }) => {
      onProgress({ percent: Math.round((loaded / total) * 100) }, data);
    },
  };
  return HttpRequestHub(config);
};

export const getUploadFile = (id, name) => {

  const config = {
    url: `files/public/${id}`,
    method: "GET",
    responseType: "blob",
  };
  HttpRequestHub(config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      console.log("url", url);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {
      console.log("can't upload file");
    });
};

export const deleteUploadFile = (id) => {
  const config = {
    url: `files/public/${id}`,
    method: "delete",
  };
  return HttpRequestHub(config);
};