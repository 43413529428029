import { HttpRequestHub } from "../HttpRequestHub";

export const getContact = () => {
    const config = {
        method: "GET",
        url: "get-contact-info",
    };
    return HttpRequestHub(config);
};

export const sendContact = (data) => {
    console.log(data)
    const config = {
        method: "POST",
        url: "update-contact-info",
        data,
    };
    return HttpRequestHub(config);
};

export const editContact = (data) => {
    const config = {
        method: "POST",
        url: "contact-info/edit",
        data,
    };
    return HttpRequestHub(config);
};

export const deleteContact = () => {
    const config = {
        method: "DELETE",
        url: "contact-info/delete/",
    };
    return HttpRequestHub(config);
};

export const getContactRequest = () => {
    const config = {
        method: "GET",
        url: "get-feedback",
    };
    return HttpRequestHub(config);
};
export const getContactMap = () => {
    const config = {
        method: "GET",
        url: "get-location",
    };
    return HttpRequestHub(config);
};
export const EditContactMap = (data) => {
    console.log(data)
    const config = {
        method: "POST",
        url: "update-location",
        data
    };
    return HttpRequestHub(config);
};

export const deleteContactRequest = (id) => {
    const config = {
        method: "DELETE",
        url: "contact/delete/" + id,
    };
    return HttpRequestHub(config);
};