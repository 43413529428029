import React, { useCallback, useEffect, useState } from "react";
import "./Contact.css";
import {Tabs, List, Button, Modal, Input, Form, Select} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { getLanguages } from "../../server/config/GetLenguage";
import {
  deleteAbout,
  editAbout,
  getAbout,
  sendAbout,
} from "../../server/config/AboutCrud";
import {
  deleteFooter,
  editFooter,
  getFooter,
  sendFooter,
} from "../../server/config/FooterCrud";
import {
  deleteSocialNetwork,
  editSocialNetwork,
  getSocialNetwork,
  sendSocialNetwork,
} from "../../server/config/SocialNetworkCrud";
import {
  toastAdd,
  toastDel,
  toastEdit,
  toastErr,
} from "../../Notifications/toast";

const { Option } = Select;

const Contact = () => {
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
  const [languages, setLanguages] = useState([]);
  const [formAbout] = Form.useForm();
  const [ckData, setCkData] = useState({ uz: "", ru: "" });
  const [aboutId, setAboutId] = useState("");
  const [formFooter] = Form.useForm();
  const [footerNumber, setFooternumber] = useState("");
  const [footerEmail, setFooteremail] = useState("");
  const [footerTime, setFootertime] = useState("");
  const [footerId, setFooterId] = useState("");
  const [socialList, setSocialList] = useState([]);
  const [socialModal, setSocialModal] = useState(false);
  const [socialId, setSocialId] = useState("");
  const [footerSvg, setFootersvg] = useState("");
  const [footerLine, setFooterline] = useState("");
  const [socialStatus, setSocialStatus] = useState("");

  //About us
  const ckChange = (event, editor, item) => {
    const data = editor.getData();
    let ckObject = { ...ckData };
    ckObject[item] = data;
    setCkData(ckObject);
    return data;
  };

  //Crud about
  const getAboutAll = () => {
    getAbout()
      .then((res) => {
        console.log(res.data)
        // res.data[0].aboutId ? setAboutId(res.data[0].aboutId) : setAboutId("");
        let ckObject = {};
        // res.data.forEach((item) => {
        //   ckObject[item.language] = item.about;
        // });
        Object.keys(res.data.about_text).forEach((item) => {
          ckObject[item] = res.data.about_text[item];
        });
        console.log(ckObject)
        setCkData(ckObject);
      })
      .catch((err) => {
        toastErr();
      });
  };
  const saveAboutAll = () => {
    formAbout
      .validateFields()
      .then((values) => {
        editAbout(values).then(() => {
          getAboutAll();
          toastEdit("Shifoxona ma'lumotlari");
        });
        // if (aboutId) {
        //   const data = Object.keys(values).map((item) => {
        //     return {
        //       language: item,
        //       about: values[item].about,
        //       aboutId: aboutId,
        //     };
        //   });
        //   editAbout(data).then(() => {
        //     getAboutAll();
        //     toastEdit("Shifoxona ma'lumotlari");
        //   });
        // } else {
        //   const data = Object.keys(values).map((item) => {
        //     return {
        //       language: item,
        //       about: values[item].about,
        //     };
        //   });
        //   sendAbout(data).then(() => {
        //     getAboutAll();
        //     toastAdd("Shifoxona ma'lumotlari");
        //   });
        // }
      })
      .catch((err) => {
        toastErr();
      });
    setAboutId("");
  };
  const delAboutAll = () => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteAbout()
          .then((res) => {
            getAboutAll();
            toastDel("Shifoxona ma'lumotlari");
          })
          .catch((err) => {
            toastErr();
          });
      },
    });
  };

  //Footer function
  const numberChange = (e) => {
    setFooternumber(e.target.value);
  };
  const emailChange = (e) => {
    setFooteremail(e.target.value);
  };
  const timeChange = (e) => {
    setFootertime(e.target.value);
  };

  //Crud Footer
  const getFooterAll = useCallback(() => {
    getFooter().then((res) => {
      console.log(res.data)
      setFooterId(res.data.id);
      if (res.data.id) {
        setFooteremail(res.data.email);
        setFooternumber(res.data.phone);
        setFootertime(res.data.working_time);
        const address = res.data.address;
        const new_data = {};
        new_data["address"] = address
        formFooter.setFieldsValue(new_data);
      }
    });
  }, [formFooter]);
  const saveFooterAll = () => {
    formFooter
      .validateFields()
      .then((values) => {
        let newdata = new FormData()
        Object.keys(values).map((item) => {
          if (typeof values[item] === "object") {
            Object.keys(values[item]).forEach(value => {
              newdata.append(`${item}[${value}]`, values[item][value])
            })
          } else {
            newdata.append(item, values[item])
          }
        });
        newdata.append("phone", footerNumber)
        newdata.append("email", footerEmail)
        newdata.append("working_time", footerTime)
        editFooter(newdata)
            .then(() => {
              getFooterAll();
              toastEdit("Footer ma'lumotlari");
            })
            .catch((err) => {
              toastErr();
            });
        // if (footerId) {
          // const data = Object.keys(values).map((item) => {
          //   return {
          //     footerId: footerId,
          //     language: item,
          //     address: values[item].manzil,
          //     email: footerEmail,
          //     phone: footerNumber,
          //     workTime: footerTime,
          //   };
          // });
        // } else {
        //   const data = Object.keys(values).map((item) => {
        //     return {
        //       language: item,
        //       address: values[item].manzil,
        //       email: footerEmail,
        //       phone: footerNumber,
        //       workTime: footerTime,
        //     };
        //   });
        //   sendFooter(data).then(() => {
        //     getFooterAll();
        //     toastAdd("Footer ma'lumotlari");
        //   });
        // }
      })
      .catch((err) => {
        toastErr();
      });
  };
  const delFooterAll = () => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteFooter().then((res) => {
          getFooterAll();
          toastDel("Footer ma'lumotlari");
        });
      },
    });
  };

  //Social Network CRUD
  const clearSocialStates = () =>{
    setSocialStatus('')
    setFooterline('')
    setFootersvg('')
  }

  const svgChange = (e) => {
    setFootersvg(e.target.value);
  };
  const lineChange = (e) => {
    setFooterline(e.target.value);
  };
  const statusChange = (value) => {
    setSocialStatus(value);
  };
  const getSocial = () => {
    getSocialNetwork()
      .then((res) => {
        setSocialList(res.data);
      })
      .catch((err) => {
        toastErr();
      });
  };

  const saveSocial = () => {
    if (socialId) {
      console.log(socialId)
      const data = {
        icon: footerSvg,
        url: footerLine,
        id: socialId,
        status: socialStatus,
      };
      editSocialNetwork(socialId, data)
        .then((res) => {
          getSocial();
          clearSocialStates()
          toastEdit("Ijtimoiy tarmoq ma'lumotlari");
        })
        .catch((err) => {
          toastErr();
        });
    } else {
      const data = {
        icon: footerSvg,
        url: footerLine,
        status: socialStatus,
      };
      sendSocialNetwork(data)
        .then((res) => {
          getSocial();
          clearSocialStates()
          toastAdd("Ijtimoiy tarmoq ma'lumotlari");
        })
        .catch((err) => {
          toastErr();
        });
    }
  };

  const editSocial = (id) => {
    setSocialId(id);
    socialList.forEach((item) => {
      if (item.id === id) {
        setFooterline(item.url);
        setFootersvg(item.icon);
        setSocialStatus(item.status)
      }
    });
  };

  const deleteSocial = (id) => {
    Modal.confirm({
      content: "Rostan o'chirishni xohlaysizmi ?",
      okText: "Ha",
      cancelText: "Yo'q",
      onOk: () => {
        deleteSocialNetwork(id)
          .then((res) => {
            getSocial();
            toastDel("Ijtimoiy tarmoq ma'lumotlari");
          })
          .catch((err) => {
            toastErr();
          });
      },
    });
  };

  const clearModal = () => {
    setFooterline("");
    setFootersvg("");
    setSocialId("");
  };

  useEffect(() => {
    getAboutAll();
    getFooterAll();
    getSocial();
    getLanguages().then((res) => {
      setLanguages(res.data);
    });
  }, [getFooterAll]);

  return (
    <div className="contact-section">
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Footer Info" key="1">
          <Form {...layout} form={formFooter}>
            {languages
              ? languages.map((item) => {
                  return (
                    <Form.Item
                      name={["address", item.url]}
                      key={["address", item.url]}
                      label={`Manzilnini Kiriting (${item.name})`}
                    >
                      <Input size="large" placeholder={`Manzil.../${item.name}`} />
                    </Form.Item>
                  );
                })
              : ""}
          </Form>

          <div>
            <label
              style={{
                color: "rgba(0, 0, 0, 0.85)",
                fontSize: "14px",
                height: "32px",
              }}
              htmlFor="info_number"
            >
              Telefon raqamni kiriting
            </label>
            <Input
              type="number"
              value={footerNumber}
              onChange={numberChange}
              required
              size="large"
              id="info_number"
              placeholder="Telefon raqami..."
            />
            <br />
          </div>

          <div>
            <div className="info-div-space"></div>
            <label
              style={{
                color: "rgba(0, 0, 0, 0.85)",
                fontSize: "14px",
                height: "32px",
              }}
              htmlFor="info_email"
            >
              Email adressini kiriting
            </label>
            <Input
              type="email"
              value={footerEmail}
              onChange={emailChange}
              required
              size="large"
              id="info_email"
              placeholder="Email..."
            />
            <br />
          </div>
          <div className="info-div-space"></div>

          <label
            style={{
              color: "rgba(0, 0, 0, 0.85)",
              fontSize: "14px",
              height: "32px",
            }}
            htmlFor="info_time"
          >
            Ish vaqtini kiriting
          </label>
          <Input
            type="text"
            value={footerTime}
            onChange={timeChange}
            required
            size="large"
            id="info_time"
            placeholder="Ish vaqti..."
          />
          <br />
          <div className="info-div-space"></div>

          <Button
            type="primary"
            style={{ float: "right", marginRight: "30px" }}
            onClick={saveFooterAll}
          >
            Save changes
          </Button>

          <Button
            type="danger"
            style={{ float: "right", marginRight: "30px" }}
            onClick={delFooterAll}
          >
            Delete
          </Button>
        </TabPane>

        <TabPane tab="Social Network" key="2">
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              onClick={() => {
                setSocialModal(true);
                clearModal();
              }}
              type="danger"
              style={{ margin: "20px auto" }}
            >
              Create Social Network
            </Button>
          </div>

          <div>
            <List
              itemLayout="horizontal"
              dataSource={socialList}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    title={
                      <div>
                        <p
                          style={{
                            display: "inline-block",
                            marginBottom: "auto",
                            marginRight: "20px",
                            width:'30px',
                            height: '30px',
                            color: "blue",
                          }}
                        >
                          {ReactHtmlParser(item.icon)}
                        </p>
                        <p
                          style={{
                            display: "inline-block",
                            marginBottom: "0",
                            marginRight: "20px",
                            color: "blue",
                          }}
                        >
                          {item.url}
                        </p>
                        <p style={{ float: "right", marginRight: "50px" }}>
                          <DeleteFilled
                            onClick={() => deleteSocial(item.id)}
                            style={{
                              fontSize: "20px",
                              color: "#309BC8",
                              cursor: "pointer",
                            }}
                          />
                        </p>
                        <p style={{ float: "right", marginRight: "20px" }}>
                          <EditOutlined
                            onClick={() => {
                              setSocialModal(true);
                              editSocial(item.id);
                            }}
                            style={{
                              fontSize: "20px",
                              color: "#F4CA16",
                              cursor: "pointer",
                            }}
                          />
                        </p>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </TabPane>

        <TabPane tab="About Info" key="3">
          <Form {...layout} form={formAbout}>
            {languages
              ? languages.map((item) => {
                  return (
                    <Form.Item
                      label={`Klinika haqida malumotni ${item.name}da kiriting:`}
                      name={["about_text", item.url]}
                      key={["about_text", item.url]}
                      getValueFromEvent={ckChange}
                    >
                      <CKEditor
                        data={ckData[item.url]}
                        editor={ClassicEditor}
                        onChange={(e, editor) => ckChange(e, editor)}
                      />
                    </Form.Item>
                  );
                })
              : ""}
          </Form>
          <Button
            type="primary"
            style={{ float: "right", marginRight: "30px" }}
            onClick={saveAboutAll}
          >
            Save changes
          </Button>

          <Button
            type="danger"
            style={{ float: "right", marginRight: "30px" }}
            onClick={delAboutAll}
          >
            Delete
          </Button>
        </TabPane>
      </Tabs>

      <Modal
        title={
          socialId
            ? "Ijtimoiy tarmoqni tahrirlash"
            : "Ijtimoiy Tarmoqni Yaratish"
        }
        visible={socialModal}
        onOk={() => {
          setSocialModal(false);
          saveSocial();
        }}
        onCancel={() => {
          setSocialModal(false);
          clearModal();
        }}
        okText="Save"
        cancelText="Cancel"
      >
        <div style={{ display: "block" }}>
          <div>
            <label
              style={{ fontWeight: "500", fontSize: "18px" }}
              htmlFor="info_svg"
            >
              {socialId
                ? "Tarmoq svgsini tahrirlang"
                : "Tarmoq svgsini kiriting"}
            </label>
            <TextArea
              rows={4}
              style={{ width: "100%" }}
              type="text"
              value={footerSvg}
              onChange={svgChange}
              required
              size="large"
              id="info_svg"
              placeholder="Svg..."
            />
            <br />
          </div>
          <div className="info-div-space"></div>
          <div>
            <label
              style={{ fontWeight: "500", fontSize: "18px" }}
              htmlFor="info_line"
            >
              {socialId
                ? "Tarmoq yo'lini tahrirlang"
                : "Tarmoq yo'lini kiriting"}
            </label>
            <Input
              style={{ width: "100%" }}
              type="text"
              value={footerLine}
              onChange={lineChange}
              required
              size="large"
              id="info_line"
              placeholder="Tarmoq yo'li..."
            />
            <br />
          </div>
          <div>
            <label
                style={{ fontWeight: "500", fontSize: "18px" }}
                htmlFor="info_line"
            >
              {socialId
                  ? "Tarmoq statusini tahrirlang"
                  : "Tarmoq statusini kiriting"}
            </label>
            <Select
                style={{ width: "100%" }}
                type="text"
                value={socialStatus}
                onChange={statusChange}
                required
                size="large"
                id="info_line"
                placeholder="Tarmoq yo'li..."
            >
              <Option value="1">1</Option>
              <Option value="0">0</Option>
            </Select>
            <br />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Contact;
