import {HttpRequestHub} from "../HttpRequestHub";


export const getAllWeekdays = (page = 1, size = 10) => {
    const config = {
        method: "GET",
        url: `weekdays/get-list`,
    };
    return HttpRequestHub(config);
};


export const getWeekdayById = (id) => {
    const config = {
        method: "GET",
        url: `weekdays/show/${id}`,
    };
    return HttpRequestHub(config);
};

export const editWeekday = (id, data) => {
    const config = {
        method: "POST",
        url: `weekdays/update/${id}`,
        data,
    };
    return HttpRequestHub(config);
};

export const deleteWeekday = (id) => {
    const config = {
        method: "POST",
        url: `weekdays/update/${id}`,
    };
    return HttpRequestHub(config);
};

export const sendWeekday = (data) => {
    const config = {
        method: "POST",
        url: "weekdays/store",
        data,
    };
    return HttpRequestHub(config);
};